import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-registration-complete',
  templateUrl: './registration-complete.component.html',
  styleUrls: ['./registration-complete.component.scss']
})
export class RegistrationCompleteComponent implements OnInit {

  @Input() isPartner: boolean;
  @Input() isResearcherPayPerOrder: boolean;
  constructor() {}

  ngOnInit() {}

}
