import { Injectable } from '@angular/core';

import { Actions, ofType, createEffect, Effect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';

import { changePassword } from '../actions/change-password.actions';
import {
  changePasswordSuccess,
  changePasswordFailed,
} from '../actions/change-password-api.actions';
import { AppTranslationService } from '@cci/core/services/app-translation.service';
import { UserService } from '@cci/core/services/user-service';
import { GrowlNotifyService } from '@cci/core/services/growl-notify-service';
import { NotifyMessage } from '@cci/shared/models';

@Injectable()
export class ChangePasswordEffects {
  
  message = <NotifyMessage>{
    type: 'success',
    key: 'toast',
    life: 3000,
    closable: true,
  };
  errorMessage = <NotifyMessage>{
    type: 'error',
    key: 'toast',
    life: 3000,
    closable: true,
  };
  translate = (key: string) => <string>{};

  changePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changePassword),
      mergeMap(({ payload }) => {
        return this.userService.changePassword(payload).pipe(
          map((response) => changePasswordSuccess()),
          catchError((errors) => of(changePasswordFailed({ errors: errors })))
        );
      })
    )
  );

  changePasswordSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changePasswordSuccess),
      switchMap(() => [
        this.growlNotificationService.showSuccessMessage(
          this.setSuccessMessage(
            this.translate('changePassword.toastyMessages.successMessageBody')
          )
        ),
      ])
    ),
    { dispatch: false }
  );

  changePasswordError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changePasswordFailed),
      switchMap(() => [
        this.growlNotificationService.showErrorMessage(
          this.setErrorMessage(
            this.translate('changePassword.toastyMessages.messageBody')
          )
        ),
      ])
    ),
    { dispatch: false }
  );

  private setErrorMessage(details: string): NotifyMessage {
    this.errorMessage.detail = details;
    return this.errorMessage;
  }

  private setSuccessMessage(details: string, ): NotifyMessage {
    this.message.detail = details;
    return this.message;
  }

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private growlNotificationService: GrowlNotifyService,
    private translationService: AppTranslationService
  ) {
    this.translate = (key: string) =>
      this.translationService.getTranslation(key);
  }
}
