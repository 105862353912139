import 'rxjs/Rx';
import { Observable } from 'rxjs';

import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

import { DataService } from '../../core/services/data.service';
import * as fromRoot from '../../store/reducers';
import { getApplicationSettings } from '../../store/selectors/app-settings.selector';
import { AuthenticationService } from './authentication.service';
import { TenantForUpdate } from '@cci/shared/models/tenant-for-update';
import { SelectedModulesForUpdate } from '@cci/shared/models/selected-modules-for-update';
import { WorkDay } from '@cci/shared/models/work-day';
import { UsersForTenant } from '@cci/shared/models';

@Injectable()
export class TenantService implements OnDestroy {
  private apiUrl: string | null;
  private subscription;

  constructor(
    private dataService: DataService,
    private authenticationService: AuthenticationService,
    private store: Store<fromRoot.State>
  ) {
    this.init();
  }

  public updateTenant(
    tenantId: number,
    tenant: TenantForUpdate
  ): Observable<boolean> {
    let url = this.getApisUrl();
    url = `${url}/${tenantId}`;

    return this.dataService.put<boolean>(url, tenant, {
      headers: { 'content-type': 'application/json' },
    });
  }

  public updateSelectedModules(
    tenantId: number,
    selectedModules: SelectedModulesForUpdate
  ): Observable<boolean> {
    let url = this.getApisUrl();
    url = `${url}/${tenantId}/selected-modules`;

    return this.dataService.put<boolean>(url, selectedModules, {
      headers: { 'content-type': 'application/json' },
    });
  }

  public setupModulesLater(tenantId: number): Observable<boolean> {
    let url = this.getApisUrl();
    url = `${url}/${tenantId}/select-modules-later`;

    return this.dataService.put<boolean>(url, null, {
      headers: { 'content-type': 'application/json' },
    });
  }

  public getDailyStartStopTimeForCompany(tenantId: number): Observable<WorkDay[]> {
    let url = this.getApisUrl();
    url = `${url}/${tenantId}/daily-start-stop-times-for-company`;

    return this.dataService.get<WorkDay[]>(url, {
      headers: { 'content-type': 'application/json' },
    });
  }

  public getUsersForTenant(tenantId: number): Observable<UsersForTenant> {
    let url = this.getApisUrl();
    url = `${url}/${tenantId}/partners/all-by-page`;

    return this.dataService.get<UsersForTenant>(url, {
      headers: { 'content-type': 'application/json' },
    });
  }

  private getApisUrl(action: string = null) {
    if (action != null) {
      return `${this.apiUrl}${this.apiPath}/${action}`;
    }
    return this.apiUrl + this.apiPath;
  }

  get apiPath() {
    const apiPath = '/marketplace/api/tenant';
    return apiPath;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  init(): void {
    this.subscription = this.store
      .select(getApplicationSettings)
      .subscribe((response) => {
        if (response) {
          this.apiUrl = response.apiUrl;
          console.log(this.apiUrl);
        }
      });
  }
}
