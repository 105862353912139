import {
  Component,
  OnDestroy,
  ChangeDetectionStrategy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as rootReducers from '../../store/reducers/root.reducers';
import * as authActions from '../store/actions';
import * as authReducers from '../store/reducers';
import * as appActions from '../../core/app/actions';
import * as coreServices from '../../core/services';
import { completeAuthenticationProcedure } from '../store/actions/authentication-api.actions';

/**
 * Note: Container components are also reusable. Whether or not
 * a component is a presentation component or a container
 * component is an implementation detail.
 */
@Component({
  selector: 'app-auth-page',
  template: `<app-auth-callback></app-auth-callback>`,
})
export class AuthenticationCallbackPageComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store<rootReducers.State>,
    private windowService: coreServices.WindowRefService
  ) {}
  public ngOnInit(): void {
    this.windowService.nativeWindow.location.hash = decodeURIComponent(
      this.windowService.nativeWindow.location.hash
    );
    this.store.dispatch(completeAuthenticationProcedure());
  }

  public ngOnDestroy(): void {}
}
