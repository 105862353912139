import { AppSettingsActions } from '../actions';
import { IApplicationSettings } from '../../shared/models';

export interface AppSettingsState {
  settings: IApplicationSettings;
  pending: boolean;
  loaded: boolean;
}

const initialState: AppSettingsState = {
  settings: null,
  pending: false,
  loaded: false
};

export function reducer(
  state = initialState,
  action: AppSettingsActions.AppSettingsActionsUnion
): AppSettingsState {
  switch (action.type) {
    case AppSettingsActions.loadAppSetings.type: {
      return Object.assign({}, state, { pending: true });
    }

    case AppSettingsActions.loadAppSetingsSuccess.type: {
      return Object.assign({}, state, {
        pending: false,
        loaded: true,
        settings: action.payload
      });
    }

    case AppSettingsActions.loadAppSetingsFailed.type: {
      return Object.assign({}, state, {
        pending: false,
        loaded: false,
        settings: null
      });
    }

    default:
      return state;
  }
}

export const getAppSettingsPending = (state: AppSettingsState) => state.pending;
export const getAppSettingsLoaded = (state: AppSettingsState) => state.loaded;
export const getAppSettings = (state: AppSettingsState) => state.settings;
/* export const getDisplayFormats = (state: AppSettingsState) =>
  state.settings.displayFormats; */
