import {
  faInfoCircle as fasFaInfoCircle,
  faNetworkWired as fasFaNetworkWired,
  faHandshake as fasFaHandshake,
  faCheckCircle as fasCheckCircle,
  faTimesCircle as fasTimesCircle,
  faFileUser as fasFileUser,
  faBuilding as fasBuilding,
  faTasksAlt as fasasksAlt,
  faChartNetwork as fasChartNetwork,
  faHandshakeAlt as fasHandshakeAlt,
  faFile as fasFile,
  faBriefcase as fasBriefcase,
  faCalendarAlt as fasCalendarAlt,
  faMoneyCheckAlt as fasMoneyCheckAlt,
  faKeySkeleton as fasKeySkeleton,
  faHorizontalRule as fasHorizontalRule,
  faGripLines as fasGripLines,
  IconDefinition
} from '@fortawesome/pro-solid-svg-icons';

import {
  faInfoCircle as falFaInfoCircle,
  faNetworkWired as falFaNetworkWired,
  faHandshake as falFaHandshake,
  faCheckCircle as falCheckCircle,
  faTimesCircle as falTimesCircle,
  faFileUser as falFileUser,
  faBuilding as falBuilding,
  faTasksAlt as falasksAlt,
  faHandshakeAlt as falHandshakeAlt,
  faFile as falFile,
  faChartNetwork as falChartNetwork,
  faBriefcase as falBriefcase,
  faCalendarAlt as falCalendarAlt,
  faMoneyCheckAlt as falMoneyCheckAlt,
  faKeySkeleton as falKeySkeleton,
  faHorizontalRule as falsHorizontalRule,
  faGripLines as falGripLines
} from '@fortawesome/pro-light-svg-icons';

export const icons: any[] = [
  fasFaInfoCircle,
  fasFaNetworkWired,
  fasFaHandshake,
  falFaInfoCircle,
  falFaNetworkWired,
  falFaHandshake,
  fasCheckCircle,
  fasTimesCircle,
  falCheckCircle,
  falTimesCircle,
  fasFileUser,
  falFileUser,
  fasBuilding,
  falBuilding,
  fasasksAlt,
  falasksAlt,
  fasChartNetwork,
  falChartNetwork,
  fasFile,
  falFile,
  fasHandshakeAlt,
  falHandshakeAlt,
  fasBriefcase,
  falBriefcase,
  fasCalendarAlt,
  falCalendarAlt,
  fasMoneyCheckAlt,
  falMoneyCheckAlt,
  fasKeySkeleton,
  falKeySkeleton,
  fasHorizontalRule,
  falsHorizontalRule,
  fasGripLines,
  falGripLines
];
