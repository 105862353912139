<div class="landing-body">
  <div class="landing-wrapper">
    <div id="header">
      <div class="header-top">
        <div class="ui-g">
          <div class="ui-g-12 ui-md-4 padding-top">
            <img src="../../../../../assets/layout/images/CCI-Logo.png" class="header-logo" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
