import { createAction, props, union } from '@ngrx/store';

export const startAuthenticationProcedure = createAction(
  '[Auth] Start Authentication Procedure'
);

export const startSignOutProcedure = createAction(
  '[Auth] Start Sign Out Procedure'
);

export const completeAuthenticationProcedure = createAction(
  '[Auth] Complete Authentication Procedure'
);

export const loadAuthenticatedUserData = createAction(
  '[Auth] Load Authenticated User Data'
);

export const reloadAuthenticatedUserData = createAction(
  '[Auth] Reload Authenticated User Data'
);

export const signOut = createAction('[Auth] Sign Out');
