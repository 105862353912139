import 'rxjs/Rx';
import { Observable, of } from 'rxjs';

import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { DataService } from '../../core/services/data.service';

import * as fromRoot from '../../store/reducers';
import { getApplicationSettings } from '../../store/selectors/app-settings.selector';

import {
  JobPosition,
  UserType,
  WorkMode,
  Division,
  Country,
  County,
  ZipCode,
  CourtHouse,
  TimeZone,
  Constants,
  State,
  WorkDay,
} from '@cci-shared/models';

@Injectable()
export class ConstantsService implements OnDestroy {
  private administrationUrl: string | null;
  private apiUrl: string;
  private subscription;

  constructor(
    private dataService: DataService,
    private store: Store<fromRoot.State>
  ) {
    this.init();
  }

  getJobPositions(): Observable<JobPosition[]> {
    const url = this.getApisUrl('getjobpositions');

    return this.dataService.get<JobPosition[]>(url);
  }

  getUserTypes(): Observable<UserType[]> {
    const url = this.getApisUrl('getUserTypes');

    return this.dataService.get<UserType[]>(url);
  }

  getWorkModes(): Observable<WorkMode[]> {
    const url = this.getApisUrl('getworkModes');

    return this.dataService.get<WorkMode[]>(url);
  }

  getWeekDays(): Observable<Division[]> {
    const url = this.getApisUrl('getweekdays');

    return this.dataService.get<Division[]>(url);
  }

  getCountries(): Observable<Country[]> {
    const url = this.getApisUrl('getcountries');

    return this.dataService.get<Country[]>(url);
  }

  getCounties(): Observable<County[]> {
    const url = this.getApisUrl('getcounties');

    return this.dataService.get<County[]>(url);
  }

  getStates(): Observable<State[]> {
    const url = this.getApisUrl('getstatesprovinces');

    return this.dataService.get<State[]>(url);
  }

  getZipCodes(): Observable<ZipCode[]> {
    const url = this.getApisUrl('getzipcodes');

    return this.dataService.get<ZipCode[]>(url);
  }

  getCourtHouses(): Observable<CourtHouse[]> {
    const url = this.getApisUrl('getCourtHouses');

    return this.dataService.get<CourtHouse[]>(url);
  }

  getTimeZones(): Observable<TimeZone[]> {
    const url = this.getApisUrl('gettimeZones');

    return this.dataService.get<TimeZone[]>(url);
  }

  getDailyStartStopTimeForCompany(): Observable<WorkDay[]> {
    const url = this.getApisUrl('daily-start-stop-times-for-company');

    // return this.dataService.get<WorkDay[]>(url);
    return of<WorkDay[]>([]);
  }

  getAll(): Observable<Constants> {
    const url = this.getApisUrl('application-constants');

    return this.dataService.get<Constants>(url);
  }

  private getApisUrl(action: string = null) {
    if (action != null) {
      return `${this.administrationUrl}${this.apiPath}/${action}`;
    }
    return this.administrationUrl + this.apiPath;
  }

  get apiPath() {
    const apiPath = '/marketplace/api/master-data';
    return apiPath;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  init(): void {
    this.subscription = this.store
      .select(getApplicationSettings)
      .subscribe((response) => {
        if (response) {
          this.administrationUrl = response.apiUrl;
        }
      });
  }
}
