import 'rxjs/Rx';
import { EMPTY, Observable, Observer } from 'rxjs';

import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { DataService } from '../../core/services/data.service';
import { User } from '../../shared/models/user';
import * as fromRoot from '../../store/reducers';
import { getApplicationSettings } from '../../store/selectors/app-settings.selector';
import { PagedListUsers } from '@cci/shared/models/paged-list-users';
import { HttpResponse, HttpParams } from '@angular/common/http';
import { AuthenticatedUserData, ChangePassword } from '@cci/shared/models';
import { AuthenticationService } from './authentication.service';
import { UserInformationForUpdate } from '@cci/shared/models/user-infomation-for-update';
import { UserInformation } from '@cci/shared/models/user-infomation';

@Injectable()
export class UserService implements OnDestroy {
  private apiUrl: string | null;
  private subscription;

  constructor(
    private dataService: DataService,
    private authenticationService: AuthenticationService,
    private store: Store<fromRoot.State>
  ) {
    this.init();
  }

  getUsers(): Observable<HttpResponse<PagedListUsers>> {
    const url = this.getApisUrl('getusers');

    let params = new HttpParams().set('pageNumber', '1');
    params = params.append('pageSize', '10');
    params = params.append('searchQuery', '');
    params = params.append('orderBy', '');
    params = params.append('fields', null);

    return this.dataService.getWithObserveResponse(url, params);
  }

  getUserDetails(): Observable<UserInformation> {
    const userId = this.authenticationService.getSub();
    let url = this.getApisUrl();
    url = `${url}/${userId}/user-profile`;

    if(userId) {
      return this.dataService.get<UserInformation>(url, {
        headers: { 'content-type': 'application/json' },
      });
    } else {
      return EMPTY;
    }
  }

  getAuthenticatedUser(): Observable<AuthenticatedUserData> {
    let url = this.getApisUrl();

    const userId = this.authenticationService.getSub();
    url = `${url}/${userId}/authenticated-user`;
    return this.dataService.get<AuthenticatedUserData>(url);
  }

  changePassword(model: ChangePassword): Observable<HttpResponse<boolean>> {
    const userId = this.authenticationService.getSub();
    let url = this.getApisUrl();
    url = `${url}/${userId}/change-password`;

    return this.dataService.put<HttpResponse<boolean>>(url, model, {
      headers: { 'content-type': 'application/json' },
    });
  }

  public UpdateProfile(user: UserInformationForUpdate): Observable<boolean> {
    const userId = this.authenticationService.getSub();
    let url = this.getApisUrl();
    url = `${url}/${userId}/update-profile`;

    return this.dataService.put<boolean>(url, user, {
      headers: { 'content-type': 'application/json' },
    });
  }

  private getApisUrl(action: string = null) {
    if (action != null) {
      return `${this.apiUrl}${this.apiPath}/${action}`;
    }
    return this.apiUrl + this.apiPath;
  }

  get apiPath() {
    const apiPath = '/marketplace/api/user';
    return apiPath;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  init(): void {
    this.subscription = this.store
      .select(getApplicationSettings)
      .subscribe((response) => {
        if (response) {
          this.apiUrl = response.apiUrl;
          console.log(this.apiUrl);
        }
      });
  }
}
