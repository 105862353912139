import { TodoType } from '@cci/shared/models/todo-type';
import { createAction, props, union } from '@ngrx/store';

export const loadUserSetupSteps = createAction(
  '[User Setup Steps] Load User Setup Steps'
);

export const resetUserSetupSteps = createAction(
  '[User Setup Steps] Reset User Setup Steps'
);

export const partnerTodoStepComplete = createAction(
  '[User Setup Steps] Complete Partner Setup Step',
  props<{ payload: TodoType }>()
);

export const researcherTodoStepComplete = createAction(
  '[User Setup Steps] Complete Researcher Setup Step',
  props<{ payload: TodoType }>()
);

export const researcherRegistrationRedirect = createAction(
  '[User Setup Steps] Redirect Researcher Setup Step'
);

export const partnerRegistrationRedirect = createAction(
  '[User Setup Steps] Redirect Partner Setup Step'
);
