<div class="p-grid ui-fluid user-information-form">
  <form [formGroup]="form">
    <div *ngIf="showAccountStatus" class="p-col-12 p-md-12">
      <div class="p-grid p-grid-form-group">
        <div class="p-col-12 p-md-3 vertical-padding">
          <label class="control-label fix-label label-padding" for="userInformation-status">{{
            'userInformation.formLabels.status' | translate
          }}</label>
        </div>
        <div class="p-col-12 p-md-8 vertical-padding">
          <p-selectButton
            [options]="accountStatus"
            formControlName="accountStatusId"
          ></p-selectButton>
        </div>
      </div>
    </div>

    <div class="p-col-12 p-md-12">
      <div class="p-grid p-grid-form-group">
        <div class="p-col-12 p-md-3 vertical-padding">
          <label class="control-label fix-label label-padding" for="userInformation-userName">
            {{ 'userInformation.formLabels.username' | translate }}
          </label >
        </div>
        <div class="p-col-12 p-md-8 vertical-padding">
          <label *ngIf="readonly" class="control-label label-padding">
            <p class="label">{{ userInformation.username | translate }}</p>
          </label>
          <input
            *ngIf="!readonly"
            id="userInformation-userName"
            type="text"
            autocomplete="off"
            placeholder="{{ 'placeHolders.username' | translate }}"
            formControlName="userName"
            class="p-inputtext p-component"
          />
        </div>
      </div>
    </div>
    <hr *ngIf="readonly" class="line" />
    <div class="p-col-12 p-sm-12 p-md-12">
      <div class="p-grid p-grid-form-group">
        <div class="p-col-12 p-md-3 vertical-padding">
          <label class="control-label fix-label label-padding" for="userInformation-email"
            ><span *ngIf="!readonly" class="asterisk">*</span
            >{{ 'userInformation.formLabels.email' | translate }}</label
          >
        </div>
        <div class="p-col-12 p-md-8 vertical-padding">
          <label *ngIf="readonly" class="control-label label-padding">
            <p class="label">{{ userInformation.email | translate }}</p>
          </label>
          <input
            *ngIf="!readonly"
            id="userInformation-email"
            type="email"
            autocomplete="off"
            placeholder="{{ 'placeHolders.email' | translate }}"
            formControlName="email"
            class="p-inputtext p-component"
          />
        </div>
      </div>
    </div>
    <hr *ngIf="readonly" class="line" />
    <div class="p-col-12 p-sm-12 p-md-12">
      <div class="p-grid p-grid-form-group">
        <div class="p-col-12 p-md-3 vertical-padding">
          <label class="control-label fix-label label-padding" for="userInformation-firstName"
            ><span *ngIf="!readonly" class="asterisk">*</span
            >{{ 'userInformation.formLabels.firstName' | translate }}</label
          >
        </div>
        <div class="p-col-12 p-md-8 vertical-padding">
          <label *ngIf="readonly" class="control-label label-padding">
            <p class="label">{{ userInformation.firstName | translate }}</p>
          </label>
          <input
            *ngIf="!readonly"
            id="userInformation-firstName"
            type="text"
            autocomplete="off"
            placeholder="{{ 'placeHolders.firstName' | translate }}"
            formControlName="firstName"
            class="p-inputtext p-component"
          />
        </div>
      </div>
    </div>
    <hr *ngIf="readonly" class="line" />
    <div class="p-col-12 p-sm-12 p-md-12">
      <div class="p-grid p-grid-form-group">
        <div class="p-col-12 p-md-3 vertical-padding">
          <label class="control-label fix-label label-padding" for="userInformation-lastName"
            ><span *ngIf="!readonly" class="asterisk">*</span
            >{{ 'userInformation.formLabels.lastName' | translate }}</label
          >
        </div>
        <div class="p-col-12 p-md-8 vertical-padding">
          <label *ngIf="readonly" class="control-label label-padding">
            <p class="label">{{ userInformation.lastName | translate }}</p>
          </label>
          <input
            *ngIf="!readonly"
            id="userInformation-lastName"
            type="text"
            autocomplete="off"
            placeholder="{{ 'placeHolders.lastName' | translate }}"
            formControlName="lastName"
            class="p-inputtext p-component flag-validity"
          />
        </div>
      </div>
    </div>
    <hr *ngIf="readonly" class="line" />
    <div class="p-col-12 p-sm-12 p-md-12">
      <div class="p-grid p-grid-form-group form-container">
        <div class="p-col-12 p-md-3 vertical-padding">
          <label
            class="control-label fix-label label-padding"
            for="userInformation-phoneNumber"
            ><span>
              <i class="fal fa-info-circle" (click)="infoSideBar(phoneInfoContent)"></i>
            </span>
            {{ 'userInformation.formLabels.phoneNumber' | translate }}</label
          >
        </div>
        <div class="p-col-12 p-md-8 vertical-padding">
          <label *ngIf="readonly" class="control-label label-padding">
            <p class="label">{{ userInformation.phoneNumber | translate }}</p>
          </label>
          <input
            *ngIf="!readonly"
            formControlName="phoneNumber"
            class="p-inputtext p-component flag-validity"
            placeholder="{{
              'userInformation.placeHolders.phoneNumber' | translate
            }}"
            (keyup)="createInput()"
          />
        </div>
        <div
          class="p-col-12 p-md-9 p-md-offset-3 text-danger"
          *ngIf="!form.controls['phoneNumber'].valid && !readonly"
        >
          <i class="far fa-times-circle"></i>
          {{ 'userInformation.errorLabels.phoneNumber' | translate }}
        </div>
      </div>
    </div>
    <hr *ngIf="readonly" class="line" />
    <div class="p-col-12 p-sm-12 p-md-12">
      <div class="p-grid p-grid-form-group">
        <div class="p-col-12 p-md-3 vertical-padding">
          <label class="control-label fix-label label-padding" for="userInformation-timezone"
            ><span>
              <i class="fal fa-info-circle min-padding-right" (click)="infoSideBar(timezoneContent)"></i>
            </span>
            <span *ngIf="!readonly" class="asterisk">*</span
            >{{ 'userInformation.formLabels.timeZone' | translate }}</label
          >
        </div>
        <div class="p-col-12 p-md-8 vertical-padding">
          <label *ngIf="readonly" class="control-label label-padding">
            <p class="label">{{ timezoneValue | translate }}</p>
          </label>
          <p-dropdown
            *ngIf="!readonly"
            id="userInformation-timezone"
            placeholder="{{ 'userInformation.placeHolders.timeZone' | translate }}"
            formControlName="timeZoneId"
            [options]="timeZones"
            optionLabel="label"
            optionValue="value"
          ></p-dropdown>
        </div>
      </div>
    </div>
    <hr *ngIf="readonly" class="line" />

    <div class="p-col-12 p-md-7">
      <ng-content select=".toolbar"></ng-content>
    </div>
  </form>
</div>
