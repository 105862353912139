import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  Output,
  forwardRef,
  HostBinding,
  ViewChild,
  ElementRef,
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { TimepickerComponent } from 'ngx-bootstrap/timepicker';

const NG_TIME_PICKER_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => NgTimepickerCustomComponent),
  multi: true,
};

@Component({
  selector: 'app-ng-timepicker-custom',
  providers: [NG_TIME_PICKER_ACCESSOR],
  templateUrl: './ng-timepicker-custom.component.html',
  styleUrls: ['./ng-timepicker-custom.component.scss'],
})
export class NgTimepickerCustomComponent
  implements OnInit, AfterViewInit, ControlValueAccessor
{
  // @ViewChild('timePicker', { static: true })
  @ViewChild('timePicker', { static: true })
  timePicker: TimepickerComponent;

  // Allow the input to be disabled, and when it is make it somewhat transparent.
  @Input()
  disabled = false;
  @Input()
  showSpinners: boolean;
  @Input()
  minTime: Date = new Date();
  @Input()
  maxTime: Date = new Date();

  startTime: Date;
  value: Date;
  private dateFormat: string = 'yyyy-MM-ddTHH:mm:ss';
  private onTouch: Function;
  private onModelChange: Function;

  writeValue(value: string): void {
    // if (!this.disabled) {
    /* this.input.setProperty(value, 'textContent', value);
      this.input.setAttribute('value', value); */
    this.value = new Date(value);
    this.timePicker.writeValue(value);
    // }
    this.timePicker.setDisabledState(this.disabled);
  }
  registerOnChange(fn: Function) {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function) {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.timePicker.setDisabledState(this.disabled);
  }

  changed(e: any) {
    const date = this.datePipe.transform(e, this.dateFormat);
    this.onModelChange(date);

    if (this.value !== e) {
      this.onTouch();
    }
    //
  }

  constructor(private datePipe: DatePipe) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    console.log('');
  }
}
