import { createSelector } from '@ngrx/store';

import * as fromFeature from '@cci-auth/store/reducers';
import * as fromProfile from '@cci-auth/store/reducers/profile.reducer';

export const getProfileState = createSelector(
  fromFeature.authenticationStoreState,
  (state: fromFeature.AuthenticationState) => state.userProfile
);

export const selectUserInformation = createSelector(
  getProfileState,
  fromProfile.getUserInformation
);

export const selectUserInformationPending = createSelector(
  getProfileState,
  fromProfile.getUserProfilePending
);

export const selectUserInformationLoaded = createSelector(
  getProfileState,
  fromProfile.getUserProfileLoaded
);
