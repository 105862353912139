import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AppTranslationService } from '@cci/core/services';
import { SelectItem } from 'primeng/api';

import { State, County } from '../../../shared/models';
import { SideBarContent } from '../../models';

@Component({
  selector: 'app-add-osp',
  templateUrl: './add-osp.component.html',
  styleUrls: ['./add-osp.component.scss'],
})
export class AddOspComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() countries: SelectItem[];
  @Input() states: State[];
  @Input() counties: County[];
  @Input() areas: SelectItem[];
  @Input() marketPlaceInfoSidebarConfirmationName: string;

  @Output()
  showSideBar = new EventEmitter<SideBarContent>();

  headerText: string;
  sideBarTextForHeader: string;
  leftLabel1: string;
  leftLabel2: string;
  leftLabel3: string;
  rightLabel1: string;
  rightLabel2: string;
  placeholder: string;
  sideBarTextForLabel1: string;
  sideBarTextForLabel2: string;
  sideBarTextForLabel3: string;

  constructor(private translationService: AppTranslationService) {}

  ngOnInit() {
    this.translate = (key: string) =>
      this.translationService.getTranslation(key);

    this.headerText = this.translate('addOsp.header.text');
    this.sideBarTextForHeader = this.translate(
      'addOsp.sideBarText.sideBarTextForHeader'
    );
    this.sideBarTextForLabel1 = this.translate(
      'addOsp.sideBarText.sideBarTextForLabel1'
    );
    this.sideBarTextForLabel2 = this.translate(
      'addOsp.sideBarText.sideBarTextForLabel2'
    );
    this.sideBarTextForLabel3 = this.translate(
      'addOsp.sideBarText.sideBarTextForLabel3'
    );
    this.leftLabel1 = this.translate('addOsp.leftLabels.serviceArea');
    this.leftLabel2 = this.translate('addOsp.leftLabels.serviceCriteria');
    this.leftLabel3 = this.translate('addOsp.leftLabels.maxServicePrice');
    this.rightLabel1 = this.translate('addOsp.rightLabels.serviceArea');
    this.rightLabel2 = this.translate('addOsp.rightLabels.serviceCriteria');
    this.placeholder = this.translate('addOsp.placeholders.maxServicePrice');
  }

  translate = (key: string) => <string>{};

  onShowOspInformationSideBar(content: SideBarContent) {
    this.showSideBar.emit(content);
  }
}
