import { AuthenticatedUserData, UserInformation } from '@cci/shared/models';
import { createAction, props, union } from '@ngrx/store';
import { User } from 'oidc-client';

export const userAuthenticationProcedureComplete = createAction(
  '[Auth] User Authentication Procedure Completed',
  props<{ payload: User }>()
);

export const userAlreadyAuthenticated = createAction(
  '[Auth] User already authenticated'
);

export const userAuthenticated = createAction(
  '[Auth] User Authenticated',
  props<{ payload: User }>()
);

export const userAuthetnticationFailed = createAction(
  '[Auth] User Authentication Failed'
);

export const authenticationPending = createAction(
  '[Auth] Authentication Pending'
);

export const signOutSuccess = createAction('[Auth] Sign Out Success');

export const signOutFailed = createAction('[Auth] Sign Out Filed');

export const loadAuthenticatedUserDataSuccess = createAction(
  '[Auth] Load Autheticated User Data Success',
  props<{ payload: AuthenticatedUserData }>()
);

export const loadAuthenticatedUserDataFailed = createAction(
  '[Auth] Load Autheticated User Data Failed',
  props<{ error: any }>()
);

export const reloadAuthenticatedUserDataSuccess = createAction(
  '[Auth] Reload Autheticated User Data Success',
  props<{ payload: AuthenticatedUserData }>()
);

export const reloadAuthenticatedUserDataFailed = createAction(
  '[Auth] Reload Autheticated User Data Failed',
  props<{ error: any }>()
);

export const authenticatedUserDataLoaded = createAction(
  '[Auth] Authenticated User Data Loaded',
  props<{ payload: AuthenticatedUserData }>()
);
