import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Message } from 'primeng/api';

import { NotifyService } from '../../../core/services/notify-service';

@Component({
  selector: 'app-notifier',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnDestroy {
  msgs: Message[] = [];
  subscription: Subscription;

  constructor(private notifyService: NotifyService) {
    this.subscribeToNotifications();
  }

  subscribeToNotifications() {
    this.subscription = this.notifyService.notificationChange.subscribe(
      (arg: Message[] | Message) => {
        this.msgs = [];
        if ((<Message>arg).detail) {
          const msg = arg as Message;
          this.msgs.push(msg);
        } else {
          const msgs = arg as Message[];
          this.msgs = msgs;
        }
      }
    );
  }

  private showGrowl() {}

  private showNotificationMessage() {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
