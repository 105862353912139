import { CaptchaModule } from 'primeng/captcha';

import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
// import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
/* import { SharedModule } from 'primeng/shared'; */
import { ContextMenuModule } from 'primeng/contextmenu';
/* import { DataGridModule } from 'primeng/datagrid';
import { DataListModule } from 'primeng/datalist'; */
/* import { DataScrollerModule } from 'primeng/datascroller'; */
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { GMapModule } from 'primeng/gmap';
import { ToastModule } from 'primeng/toast';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SliderModule } from 'primeng/slider';
import { SpinnerModule } from 'primeng/spinner';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { DataViewModule } from 'primeng/dataview';
import { TabMenuModule } from 'primeng/tabmenu';
import { CardModule } from 'primeng/card';
import { TabViewModule } from 'primeng/tabview';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { CarouselModule } from 'primeng/carousel';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { ChipModule } from 'primeng/chip';
import { ImageModule } from 'primeng/image';

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  interactionPlugin
]);

export const PrimeModules = [
  AccordionModule,
  AutoCompleteModule,
  ButtonModule,
  CalendarModule,
  CheckboxModule,
  ChipsModule,
  ConfirmDialogModule,
  ContextMenuModule,
  DialogModule,
  DynamicDialogModule,
  DropdownModule,
  FileUploadModule,
  GMapModule,
  ToastModule,
  InputMaskModule,
  InputSwitchModule,
  InputTextModule,
  InputTextareaModule,
  ListboxModule,
  MenuModule,
  MenubarModule,
  MessagesModule,
  MultiSelectModule,
  OrderListModule,
  PaginatorModule,
  PanelModule,
  PanelMenuModule,
  PasswordModule,
  RadioButtonModule,
  SelectButtonModule,
  SliderModule,
  SpinnerModule,
  SplitButtonModule,
  StepsModule,
  ToggleButtonModule,
  ToolbarModule,
  TooltipModule,
  TreeModule,
  TreeTableModule,
  CaptchaModule,
  SidebarModule,
  TableModule,
  DataViewModule,
  TabMenuModule,
  CardModule,
  TabViewModule,
  OverlayPanelModule,
  ScrollPanelModule,
  FullCalendarModule,
  CarouselModule,
  ChipModule,
  ImageModule
];
