let recorderObj;
let token = 'ec807a02-4b01-4bae-a98e-6e4dcd22217a1716742850';
let config = {
    record_config: {
        screen: true,
        webcam: false,
        mic: false,
        resolution: "1080",
        aspect_ratio: 16 / 9,
        enable_markup_tool: true,
        enableSubmitAfterProcessing: true,
        extensionlessRecording: true
    }
};

export const initializeRecorder = () => {
    if (window.HVRecorder) {
        let initializeOptions = { token: token };
        const onVideoSdkInitialize = (data, error) => {
            if (error) {
                console.log(error);
            }
            if (data) {
                window.HVRecorder.RecorderWidget.create(data.key, config).then((recorder) => {
                    recorderObj = recorder;

                    recorder.on("record_details", (data) => {
                        // Set video URL
                        document.getElementById("videoEmbedUrl").innerHTML = data.embed_url;
                    });
                    recorder.on("record_started", (data) => {
                        hidePreviewVideoPopup();
                        recordStarted();
                    });
                    recorder.on("record_stopped", (data) => {
                        hidePreviewVideoPopup();
                        recordStopped();
                    });
                    recorder.on("source_uploaded", (data) => {
                        sourceUploaded();
                    });
                    recorder.on("record_error", (data) => {
                        recordError();
                        initializeRecorder();
                    });
                    recorder.on("record_aborted", (data) => {
                        hidePreviewVideoPopup();
                        abortRecord();
                        initializeRecorder();
                    });
                    recorder.on("video_cancelled", (data) => {
                        initializeRecorder();
                    });
                    
                    // For more events refer the document
                });
            }
        }
        window.HVRecorder.initialize(initializeOptions, onVideoSdkInitialize);
    }
}

//Cutom button to start recording externally
//Note: This method should be called only after SDK initialization (i.e.,once if the SDK instance is created and assigned to this recorderObj variable) 
export function startsRecording() {
    recorderObj.startRecording(config);
}

//Cutom button to stop recording externally
export function stopRecording() {
    recorderObj.stopRecording();
}

//Cutom button to cancel/abort recording externally
export function cancelRecording() {
    recorderObj.cancelRecording();
}

function recordStarted() {
    var event = new Event('record-started');
    document.dispatchEvent(event);
}

function recordStopped() {
    var event = new Event('record-stopped');
    document.dispatchEvent(event);
}

function sourceUploaded() {
    var event = new Event('source-uploaded');
    document.dispatchEvent(event);
}

function recordError() {
    var event = new Event('record-error');
    document.dispatchEvent(event);
}

function abortRecord() {
    var event = new Event('record-abort');
    document.dispatchEvent(event);
}

function hidePreviewVideoPopup() {
    const childNodelist = document.querySelectorAll("div.hvr-sdkrecorder-wrapper");
    childNodelist.forEach(item => {
        item.parentNode.style.visibility = 'hidden';
    });
}