import { Injectable } from '@angular/core';
import { Effect, Actions, ofType, createEffect } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ApplicationSettingsService } from '../../core/services/application-settings-resolver.service';
import {
  AppSettingsActionsUnion,
  loadAppSetingsSuccess,
  loadAppSetingsFailed,
  loadAppSetings
} from '../actions/app-settings.actions';

import { IApplicationSettings } from '../../shared/models';

@Injectable()
export class AppSettingsEffects {

  loadApplicationSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAppSetings.type),
      switchMap(() => {
        return this.settingsService.load().pipe(
          map((settings: IApplicationSettings) =>
            loadAppSetingsSuccess({ payload: settings })
          ),
          catchError(errors => of(loadAppSetingsFailed({ errors: errors })))
        );
      })
    )
  );

  constructor(
    private actions$: Actions<AppSettingsActionsUnion>,
    private settingsService: ApplicationSettingsService
  ) {}
}
