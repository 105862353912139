export class SideBarContent {
  constructor(
    public name: string,
    public visible: boolean,
    public position: string,
    public content: string,
    public isInfo: boolean,
    public buttonOneVisible: boolean,
    public buttonTwoVisible: boolean,
    public buttonThreeVisible: boolean,
    public headerText?: string,
    public styleClass?: string,
    public sidebarHeaderClass?: string,
    public sidebarContentClass?: string,
    public buttonOneLabel?: string,
    public buttonTwoLabel?: string,
    public buttonThreeLabel?: string,
    public source?: any, 
    public isHeaderText? :boolean,
    public showCloseIcon?: boolean,
    public buttonOneDisable?: boolean,
    public buttonTwoDisable?: boolean,
    public buttonThreeDisable?: boolean
  ) {}
}
