import 'rxjs/Rx';
import { Observable } from 'rxjs';

import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

import { DataService } from '../../core/services/data.service';
import * as fromRoot from '../../store/reducers';
import { getApplicationSettings } from '../../store/selectors/app-settings.selector';
import { AuthenticationService } from './authentication.service';
import { CourthousesByresearcherResponse, GridQueryOptions, ResearcherResponse, CourthousesForResearchersResponse, ResearchersByCourthousesResponse, InvitedResearcherResponse, CourthousesByInvitedresearcherResponse } from '@cci/shared/models';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { CustomHttpParamEncoder } from '@cci/shared/http/CustomHttpParamEncoder';

@Injectable()
export class ResearcherGridsService implements OnDestroy {
  private apiUrl: string | null;
  private subscription;

  constructor(
    private dataService: DataService,
    private authenticationService: AuthenticationService,
    private store: Store<fromRoot.State>
  ) {
    this.init();
  }

  public getResearchers(
    tenantId: number,
    page: GridQueryOptions
  ): Observable<HttpResponse<ResearcherResponse>> {
    let url = this.getApisUrl(`${tenantId}/get-researchers`);
    let params: HttpParams = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
    })
    if(page) {
      page.pageNumber ? params = params.append('pageNumber', page.pageNumber.toString()): '';
      page.pageSize ? params = params.append('pageSize', page.pageSize.toString()) : '';
      page.sortField ? params = params.append('orderBy', page.sortField) : '';
      page.sortOrder ? params = params.append('sortOrder', page.sortOrder) : '';
  
      if (page.filters) {
        Array.from(page.filters).map(item => {
          const index = page.filters.indexOf(item);
          Array.from(Object.keys(item)).map(key => {
            const valueId = Object.keys(item).indexOf(key);
            params = params.append(`Filters[${index}].${key}`, Object.values(item)[valueId]);
          });
        });
      }
    }
    return this.dataService.getWithObserveResponse(url, params);
  }

  public getInvitedResearchers(
    tenantId: number,
    page: GridQueryOptions
  ): Observable<HttpResponse<InvitedResearcherResponse>> {
    let url = this.getApisUrl(`${tenantId}/get-invited-researchers`);
    let params: HttpParams = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
    })
    if(page) {
      page.pageNumber ? params = params.append('pageNumber', page.pageNumber.toString()): '';
      page.pageSize ? params = params.append('pageSize', page.pageSize.toString()) : '';
      page.sortField ? params = params.append('orderBy', page.sortField) : '';
      page.sortOrder ? params = params.append('sortOrder', page.sortOrder) : '';
  
      if (page.filters) {
        Array.from(page.filters).map(item => {
          const index = page.filters.indexOf(item);
          Array.from(Object.keys(item)).map(key => {
            const valueId = Object.keys(item).indexOf(key);
            params = params.append(`Filters[${index}].${key}`, Object.values(item)[valueId]);
          });
        });
      }
    }

    return this.dataService.getWithObserveResponse(url, params);
  }

  public getCourthousesByResearcher(
    tenantId: number,
    researcherId: number,
    page: GridQueryOptions
  ): Observable<HttpResponse<CourthousesByresearcherResponse>> {
    let url = this.getApisUrl(`${tenantId}/get-courthouses-for-researcher`);
    let params: HttpParams = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
    })
    
    if(researcherId) {
      console.log('researcherId', researcherId);
      params = params.append('researcherId', researcherId.toString())
    }
    if(page) {
      page.pageNumber ? params = params.append('pageNumber', page.pageNumber.toString()): '';
      page.pageSize ? params = params.append('pageSize', page.pageSize.toString()) : '';
      page.sortField ? params = params.append('orderBy', page.sortField) : '';
      page.sortOrder ? params = params.append('sortOrder', page.sortOrder) : '';
  
      if (page.filters) {
        Array.from(page.filters).map(item => {
          const index = page.filters.indexOf(item);
          Array.from(Object.keys(item)).map(key => {
            const valueId = Object.keys(item).indexOf(key);
            params = params.append(`Filters[${index}].${key}`, Object.values(item)[valueId]);
          });
        });
      }
    }
    return this.dataService.getWithObserveResponse(url, params);
  }

  public getCourthousesByInvitedResearcher(
    tenantId: number,
    email: string,
    page: GridQueryOptions
  ): Observable<HttpResponse<CourthousesByInvitedresearcherResponse>> {
    let url = this.getApisUrl(`${tenantId}/get-courtHouses-by-invited-researcher`);
    let params: HttpParams = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
    })
    if(email) {
      params = params.append('email', email.toString())
    }
    if(page) {
      page.pageNumber ? params = params.append('pageNumber', page.pageNumber.toString()): '';
      page.pageSize ? params = params.append('pageSize', page.pageSize.toString()) : '';
      page.sortField ? params = params.append('orderBy', page.sortField) : '';
      page.sortOrder ? params = params.append('sortOrder', page.sortOrder) : '';
  
      if (page.filters) {
        Array.from(page.filters).map(item => {
          const index = page.filters.indexOf(item);
          Array.from(Object.keys(item)).map(key => {
            const valueId = Object.keys(item).indexOf(key);
            params = params.append(`Filters[${index}].${key}`, Object.values(item)[valueId]);
          });
        });
      }
    }
    return this.dataService.getWithObserveResponse(url, params);
  }

  public getCourthousesForResearcher(
    tenantId: number,
    page: GridQueryOptions
  ): Observable<HttpResponse<CourthousesForResearchersResponse>> {
    let url = this.getApisUrl(`${tenantId}/get-courthouses`);
    let params: HttpParams = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
    })
    if(page) {
      page.pageNumber ? params = params.append('pageNumber', page.pageNumber.toString()): '';
      page.pageSize ? params = params.append('pageSize', page.pageSize.toString()) : '';
      page.sortField ? params = params.append('orderBy', page.sortField) : '';
      page.sortOrder ? params = params.append('sortOrder', page.sortOrder) : '';
  
      if (page.filters) {
        Array.from(page.filters).map(item => {
          const index = page.filters.indexOf(item);
          Array.from(Object.keys(item)).map(key => {
            const valueId = Object.keys(item).indexOf(key);
            params = params.append(`Filters[${index}].${key}`, Object.values(item)[valueId]);
          });
        });
      }
    }
    return this.dataService.getWithObserveResponse(url, params);
  }

  public getResearchersByCourthouse(
    tenantId: number,
    courtHouseId: number,
    page: GridQueryOptions
  ): Observable<HttpResponse<ResearchersByCourthousesResponse>> {
    let url = this.getApisUrl(`${tenantId}/get-researchers-for-courthouse`);
    let params: HttpParams = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
    })
    params = params.append('courthouseId', courtHouseId);;
    if(page) {
      page.pageNumber ? params = params.append('pageNumber', page.pageNumber.toString()): '';
      page.pageSize ? params = params.append('pageSize', page.pageSize.toString()) : '';
      page.sortField ? params = params.append('orderBy', page.sortField) : '';
      page.sortOrder ? params = params.append('sortOrder', page.sortOrder) : '';
  
      if (page.filters) {
        Array.from(page.filters).map(item => {
          const index = page.filters.indexOf(item);
          Array.from(Object.keys(item)).map(key => {
            const valueId = Object.keys(item).indexOf(key);
            params = params.append(`Filters[${index}].${key}`, Object.values(item)[valueId]);
          });
        });
      }
    }
    return this.dataService.getWithObserveResponse(url, params);
  }

  public getInvitedResearchersByCourthouse(
    tenantId: number,
    courtHouseId: number,
    page: GridQueryOptions
  ): Observable<HttpResponse<InvitedResearcherResponse>> {
    let url = this.getApisUrl(`${tenantId}/get-invited-researchers-by-courthouse`);
    let params: HttpParams = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
    })
    params = params.append('courthouseId', courtHouseId);;
    if(page) {
      page.pageNumber ? params = params.append('pageNumber', page.pageNumber.toString()): '';
      page.pageSize ? params = params.append('pageSize', page.pageSize.toString()) : '';
      page.sortField ? params = params.append('orderBy', page.sortField) : '';
      page.sortOrder ? params = params.append('sortOrder', page.sortOrder) : '';
  
      if (page.filters) {
        Array.from(page.filters).map(item => {
          const index = page.filters.indexOf(item);
          Array.from(Object.keys(item)).map(key => {
            const valueId = Object.keys(item).indexOf(key);
            params = params.append(`Filters[${index}].${key}`, Object.values(item)[valueId]);
          });
        });
      }
    }
    
    return this.dataService.getWithObserveResponse(url, params);
  }

  private getApisUrl(action: string = null) {
    if (action != null) {
      return `${this.apiUrl}${this.apiPath}/${action}`;
    }
    return this.apiUrl + this.apiPath;
  }

  get apiPath() {
    const apiPath = '/marketplace/api/tenant';
    return apiPath;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  init(): void {
    this.subscription = this.store
      .select(getApplicationSettings)
      .subscribe((response) => {
        if (response) {
          this.apiUrl = response.apiUrl;
          console.log(this.apiUrl);
        }
      });
  }
}
