import {
  withLatestFrom,
  map,
  switchMap,
  filter,
  catchError,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { Actions, ofType } from '@ngrx/effects';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

import * as applicationReducer from '../reducers';

export class EffectsBase {
  protected actions$: Actions;
  protected store: Store<applicationReducer.State>;

  protected handleNavigation(
    segment: string,
    callback: (
      a: ActivatedRouteSnapshot,
      state: applicationReducer.State
    ) => Observable<any>
  ) {
    const nav = this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      map(this.firstSegment),
      filter((s) => {
        return s.url === segment;
      })
    );

    return nav.pipe(withLatestFrom(this.store)).pipe(
      switchMap((a) => callback(a[0].root, a[1])),
      catchError((e) => {
        console.log('Network error', e);
        return of(e);
      })
    );
  }

  protected firstSegment(r: RouterNavigationAction) {
    return r.payload.routerState;
  }

  constructor(actions$: Actions, store: Store<applicationReducer.State>) {
    this.actions$ = actions$;
    this.store = store;
  }
}
