import { createAction, props } from '@ngrx/store';
import {
  Country,
  JobPosition,
  UserType,
  WorkMode,
  WeekDay,
  State,
  County,
  CourtHouse,
  TimeZone,
  CourtType,
  CourtLevel,
  SearchMethods,
  RecordTypes,
  SearchTimeFrames,
  Races,
  HairColors,
  Genders,
  EyeColors
} from '@cci/shared/models';
import { WorkDay } from '@cci/shared/models/work-day';
import { AccountStatus } from '@cci/shared/models/account-status';

export const loadConstantsSuccess = createAction(
  '[Application Constants] Load Constants Success'
);

export const loadConstantsFailed = createAction(
  '[Application Constants] Load Constants Failed',
  props<{ errors: any }>()
);

export const loadCountriesSuccess = createAction(
  '[Application Constants] Load Countries Success',
  props<{ countries: Country[] }>()
);

export const loadCountriesFailed = createAction(
  '[Application Constants] Load Countries Failed',
  props<{ errors: any }>()
);

export const loadJobPositionsSuccess = createAction(
  '[Application Constants] Load Job Positions Success',
  props<{ positions: JobPosition[] }>()
);

export const loadJobPositionsFailed = createAction(
  '[Application Constants] Load Job Positions Failed',
  props<{ errors: any }>()
);

export const loadUserTypesSuccess = createAction(
  '[Application Constants] Load User Types Success',
  props<{ positions: UserType[] }>()
);

export const loadUserTypesFailed = createAction(
  '[Application Constants] Load User Types Failed',
  props<{ errors: any }>()
);

export const loadWorkModesSuccess = createAction(
  '[Application Constants] Load Work Modes Success',
  props<{ workModes: WorkMode[] }>()
);

export const loadWorkModesFailed = createAction(
  '[Application Constants] Load Work Modes Failed',
  props<{ errors: any }>()
);

export const loadWeekDaysSuccess = createAction(
  '[Application Constants] Week Days Success',
  props<{ weekDays: WeekDay[] }>()
);

export const loadWeekDaysFailed = createAction(
  '[Application Constants] Week Days Failed',
  props<{ errors: any }>()
);

export const loadStatesSuccess = createAction(
  '[Application Constants] Load States and Provinces Success',
  props<{ states: State[] }>()
);

export const loadStatesFailed = createAction(
  '[Application Constants] Load States and Provinces Failed',
  props<{ errors: any }>()
);

export const loadCountiesSuccess = createAction(
  '[Application Constants] Load Counties Success',
  props<{ counties: County[] }>()
);

export const loadCountiesFailed = createAction(
  '[Application Constants] Load Counties Failed',
  props<{ errors: any }>()
);

export const loadCourtHousesSuccess = createAction(
  '[Application Constants] Load Court Houses Success',
  props<{ courtHouses: CourtHouse[] }>()
);

export const loadCourtHousesFailed = createAction(
  '[Application Constants] Load Court Houses Failed',
  props<{ errors: any }>()
);

export const loadTimeZonesSuccess = createAction(
  '[Application Constants] Load Time Zones Success',
  props<{ timeZones: TimeZone[] }>()
);

export const loadTimeZonesFailed = createAction(
  '[Application Constants] Load Time Zones Failed',
  props<{ errors: any }>()
);

export const loadCourtTypesSuccess = createAction(
  '[Application Constants] Load Court Types Success',
  props<{ courtTypes: CourtType[] }>()
);

export const loadCourtTypesFailed = createAction(
  '[Application Constants] Load Court Types Failed',
  props<{ errors: any }>()
);

export const loadCourtLevelsSuccess = createAction(
  '[Application Constants] Load Court Levels Success',
  props<{ courtLevels: CourtLevel[] }>()
);

export const loadCourtLevelsFailed = createAction(
  '[Application Constants] Load Court Levels Failed',
  props<{ errors: any }>()
);

export const loadAccountStatusSuccess = createAction(
  '[Application Constants] Load Account Status Success',
  props<{ accountStatus: AccountStatus[] }>()
);

export const loadAccountStatusFailed = createAction(
  '[Application Constants] Load Account Status Failed',
  props<{ errors: any }>()
);

export const loadRecordTypesSuccess = createAction(
  '[Application Constants] Load Record Types',
  props<{ recordTypes: RecordTypes[] }>()
);

export const loadRecordTypesFailed = createAction(
  '[Application Constants] Load Record Types',
  props<{ errors: any }>()
);

export const loadSearchMethodsSuccess = createAction(
  '[Application Constants] Load Search Methods',
  props<{ searchMethods: SearchMethods[] }>()
);

export const loadSearchMethodsFailed = createAction(
  '[Application Constants] Load Search Methods',
  props<{ errors: any }>()
);

export const loadSearchTimeFramesSuccess = createAction(
  '[Application Constants] Load Search Time Frames',
  props<{ searchTimeFrames: SearchTimeFrames[] }>()
);

export const loadSearchTimeFramesFailed = createAction(
  '[Application Constants] Load Search Time Frames',
  props<{ errors: any }>()
);

export const loadEyeColorsSuccess = createAction(
  '[Application Constants] Load Eye Colors',
  props<{ eyeColors: EyeColors[] }>()
);

export const loadEyeColorsFailed = createAction(
  '[Application Constants] Load Eye Colors',
  props<{ errors: any }>()
);

export const loadGendersSuccess = createAction(
  '[Application Constants] Load Genders',
  props<{ genders: Genders[] }>()
);

export const loadGendersFailed = createAction(
  '[Application Constants] Load Genders',
  props<{ errors: any }>()
);

export const loadHairColorsSuccess = createAction(
  '[Application Constants] Load Hair Colors',
  props<{ hairColors: HairColors[] }>()
);

export const loadHairColorsFailed = createAction(
  '[Application Constants] Load Hair Colors',
  props<{ errors: any }>()
);

export const loadRacesSuccess = createAction(
  '[Application Constants] Load Races',
  props<{ races: Races[] }>()
);

export const loadRacesFailed = createAction(
  '[Application Constants] Load Races',
  props<{ errors: any }>()
);
