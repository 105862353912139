import { Component, Output, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Urls } from '@cci/shared/constants/url-constants';
import { AppTranslationService } from '@cci/core/services/app-translation.service';

@Component({
  selector: 'app-landing-body',
  templateUrl: './landing-body.component.html',
  styleUrls: ['./home-body.component.scss'],
})
export class LandingBodyComponent {
  @Output() signInMenuClicked = new EventEmitter();
  @Output() signUpMenuClicked = new EventEmitter();

  isAccountRecoveryPage: boolean = false;
  currentRoute: string;

  constructor(
    private router: Router,
    private translationService: AppTranslationService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.currentRoute = event['url'];
        if (this.currentRoute !== undefined && this.currentRoute !== null) {
          if (
            this.currentRoute === Urls.SendRecoverCode ||
            this.currentRoute === Urls.EnterRecoverCode
          ) {
            this.isAccountRecoveryPage = true;
          } else {
            this.isAccountRecoveryPage = false;
          }
        }
      });
  }

  public ngOnInit(): void {
    this.translate = (key: string) =>
      this.translationService.getTranslation(key);
  }

  translate = (key: string) => <string>{};
}
