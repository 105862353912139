import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';

import { NotifyMessage } from '../../shared/models';

@Injectable()
export class NotifyService {
  successSeverity = 'success';
  infoSeverity = 'info';
  warnSeverity = 'warn';
  errorSeverity = 'error';

  notificationChange: Subject<Object> = new Subject<Object>();

  constructor(private messageService: MessageService) {}

  showSuccessMessage(message: NotifyMessage) {
    this.notificationChange.next({
      severity: this.successSeverity,
      summary: message.summary,
      detail: message.detail,
      id: message.id,
    });
  }

  showInfoMessage(message: NotifyMessage) {
    this.notificationChange.next({
      severity: this.infoSeverity,
      summary: message.summary,
      detail: message.detail,
      id: message.id,
    });
  }

  showWarnMessage(message: NotifyMessage) {
    this.notificationChange.next({
      severity: this.warnSeverity,
      summary: message.summary,
      detail: message.detail,
      id: message.id,
    });
  }

  showErrorMessage(message: NotifyMessage) {
    this.notificationChange.next({
      severity: this.errorSeverity,
      summary: message.summary,
      detail: message.detail,
      id: message.id,
    });
  }
}
