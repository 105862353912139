import { createAction, props, union } from '@ngrx/store';
import { WindowSize, Modal, ModalResult } from '@cci/shared/models';

export const openModal = createAction(
  '[Modal] Open Modal',
  props<{ modal: Modal }>()
);

export const closeModal = createAction(
  '[Modal] Close Modal',
  props<{ name: string }>()
);

export const modalAccepted = createAction(
  '[Modal] Accepted',
  props<{ result: ModalResult }>()
);

export const modalClosed = createAction('[Modal] Closed');
