<div class="p-grid">
  <form [formGroup]="form">
    <app-service-area-info
      [form]="form"
      [headerText]="headerText"
      [sideBarTextForHeader]="sideBarTextForHeader"
      [sideBarTextForLabel1]="sideBarTextForLabel1"
      [sideBarTextForLabel2]="sideBarTextForLabel2"
      [sideBarTextForLabel3]="sideBarTextForLabel3"
      [leftLabel1]="leftLabel1"
      [leftLabel2]="leftLabel2"
      [leftLabel3]="leftLabel3"
      [rightLabel1]="rightLabel1"
      [rightLabel2]="rightLabel2"
      [placeholder]="placeholder"
      (showSideBar)="onShowOspInformationSideBar($event)"
      [confirmationName]="marketPlaceInfoSidebarConfirmationName"
    >
    </app-service-area-info>
  </form>
</div>
