import { LocalStorageService } from './local-storage.service';
import { WindowRefService } from './window-ref.service';
import { DataService } from './data.service';
import { SettingsService } from './settings.service';
import { NavigationService } from './navigation.service';
import { ApplicationSettingsService } from './application-settings-resolver.service';
import { SeoService } from './seo.service';
import { UsersService } from './users.service';
import { SystemService } from './system.service';
import { SessionService } from './session.service';
import { ConstantsService } from './constants-service';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from './dialog-service';
import { MessageService } from 'primeng/api';
import { NotifyService } from './notify-service';
import { AppTranslationService } from './app-translation.service';
import { LogService } from './logging/log.service';
import { LogPublishersService } from './logging/log-publishers.service';
import { AppErrorHandler } from './app-error.handler';
import { GrowlNotifyService } from './growl-notify-service';
import { UserTodosService } from './user-todos.service';
import { ResearchersService } from './researchers.service';
import { PartnerService } from './partner.service';
import { AuthenticationService } from './authentication.service';
import { ErrorLoggerService } from './error-logger-service';
import { InviteResearcherService } from './invite-researcher.service';
import { PermissionsService } from './permissions.service';
import { DashboardInfoService } from './dashboard-info.service';
import { OspService } from './osp.service';
import { OfpService } from './ofp.service';
import { UserService } from './user-service';
import { TenantService } from './tenant-service';
import { ResearcherGridsService } from './researcher-grids-service';
import { OrdersCollectionService } from './orders-collection.service';

export const services: any[] = [
  LocalStorageService,
  WindowRefService,
  DataService,
  SettingsService,
  NavigationService,
  ApplicationSettingsService,
  SeoService,
  UsersService,
  SystemService,
  SessionService,
  ConstantsService,
  ConfirmationService,
  MessageService,
  DialogService,
  NotifyService,
  AppTranslationService,
  LogService,
  LogPublishersService,
  AppErrorHandler,
  GrowlNotifyService,
  UserTodosService,
  ResearchersService,
  PartnerService,
  AuthenticationService,
  ErrorLoggerService,
  InviteResearcherService,
  PermissionsService,
  DashboardInfoService,
  OspService,
  OfpService,
  UserService,
  TenantService,
  ResearcherGridsService,
  OrdersCollectionService
];

export * from './local-storage.service';
export * from './window-ref.service';
export * from './data.service';
export * from './settings.service';
export * from './navigation.service';
export * from './application-settings-resolver.service';
export * from './seo.service';
export * from './users.service';
export * from './system.service';
export * from './session.service';
export * from './constants-service';
export * from './dialog-service';
export * from './notify-service';
export * from 'primeng/api';
export * from './app-translation.service';
export * from './logging/log.service';
export * from './logging/log-publishers.service';
export * from './app-error.handler';
export * from './growl-notify-service';
export * from './user-todos.service';
export * from './researchers.service';
export * from './partner.service';
export * from './authentication.service';
export * from './error-logger-service';
export * from './invite-researcher.service';
export * from './permissions.service';
export * from './dashboard-info.service';
export * from './osp.service';
export * from './ofp.service';
export * from './user-service';
export * from './tenant-service';
export * from './researcher-grids-service';
export * from './orders-collection.service';
