/*
  The SharedModule exists to make commonly used components,
  directives, and pipes available for use in the templates of components in many other modules.

  Should not be used as provider for services as lazyloaded modules will create their own
  service instances.
*/

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PrimeModules } from './prime.modules';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { TranslateModule } from '@ngx-translate/core';
import { MomentModule } from 'ngx-moment';
import { ArchwizardModule } from 'angular-archwizard';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { DynamicDialogModule } from 'primeng/dynamicdialog';

// Import your library
import { NgxPermissionsModule } from 'ngx-permissions';
import { QRCodeModule } from 'angularx-qrcode';

import * as fromComponents from './components';
import * as fromDirectives from './directives';
import * as fromPipes from './pipes';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    ...PrimeModules,
    MomentModule,
    ArchwizardModule,
    TranslateModule,
    FontAwesomeModule,
    StorageServiceModule,
    NgxSpinnerModule,
    TimepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgxPermissionsModule,
    QRCodeModule,
  ],
  declarations: [
    ...fromComponents.components,
    ...fromDirectives.directives,
    ...fromPipes.pipes,
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    StoreModule,
    EffectsModule,
    NgIdleKeepaliveModule,
    TranslateModule,
    MomentModule,
    ArchwizardModule,
    NgxSpinnerModule,
    StorageServiceModule,
    FontAwesomeModule,
    TimepickerModule,
    BsDatepickerModule,
    NgxPermissionsModule,
    QRCodeModule,
    ...PrimeModules,
    ...fromComponents.components,
    ...fromDirectives.directives,
    ...fromPipes.pipes,
  ],
  providers: [...fromPipes.pipes],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
