import { createReducer, on } from '@ngrx/store';
import { activateOfpActionFailed, activateOfpActionSuccess, activateOspActionFailed, activateOspActionSuccess } from '../actions/module-activation-api.actions';
import { activateOfpAction, activateOspAction } from '../actions/module-activation.actions';

export const collectionFeatureKey = 'registration';

export interface ModuleActivateState {
  createOspPending: boolean;
  createOspSuccess: boolean;
  createOspFailed: boolean;
  createOfpPending: boolean;
  createOfpSuccess: boolean;
  createOfpFailed: boolean;
  createOfpErrors: any;
  createOspErrors: any;
}

const initialState: ModuleActivateState = {
  createOspPending: false,
  createOspSuccess: false,
  createOspFailed: false,
  createOspErrors: null,
  createOfpPending: false,
  createOfpSuccess: false,
  createOfpFailed: false,
  createOfpErrors: null
};

export const reducer = createReducer(
  initialState,
  on(activateOspAction, (state, {}) => ({
    ...state,
    createOspSuccess: false,
    createOspPending: true
  })),
  on(activateOspActionSuccess, (state, {}) => ({
    ...state,
    createOspSuccess: true,
    createOspFailed: false
  })),
  on(activateOspActionFailed, (state, {}) => ({
    ...state,
    createOspSuccess: false,
    createOspFailed: true
  })),
  on(activateOfpAction, (state, {}) => ({
    ...state,
    createOfpSuccess: false,
    createOfpPending: true
  })),
  on(activateOfpActionSuccess, (state, {}) => ({
    ...state,
    createOfpSuccess: true,
    createOfpFailed: false
  })),
  on(activateOfpActionFailed, (state, { errors }) => ({
    ...state,
    createOfpSuccess: false,
    createOfpFailed: true,
    createOfpErrors: errors
  }))
);

export const getCreateOspSuccess = (state: ModuleActivateState) => state.createOspSuccess;
export const getCreateOspFailed = (state: ModuleActivateState) => state.createOspFailed;
export const getCreateOspErrors = (state: ModuleActivateState) => state.createOspErrors;
export const getCreateOfpSuccess = (state: ModuleActivateState) => state.createOfpSuccess;
export const getCreateOfpFailed = (state: ModuleActivateState) => state.createOfpFailed;
export const getCreateOfpErrors = (state: ModuleActivateState) => state.createOfpErrors;
