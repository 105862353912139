import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

import * as fromRoot from '../../store';
import { DataService } from './data.service';
import { takeUntil } from 'rxjs/operators';
import * as fromAuth from '@cci/auth/store';
import {
  CreateOsp,
  OrderFulfillmentProfileForCreate,
  OrderSubmissionProfile,
  OrderSubmissionProfileForCreate,
  OrderFulfillmentProfile,
  AuthorizedUser,
  GridQueryOptions,
  OspResults,
  UpdateOsp
} from '@cci/shared/models';
import { getApplicationSettings } from '@cci/store/selectors/app-settings.selector';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class OspService implements OnDestroy {
  private apiUrl: string | null;
  private cciApiUrl: string | null;
  private subscription;
  private authorizedUser: AuthorizedUser;
  private partnerId: number;
  private unSubscribe: Subject<void> = new Subject();

  constructor(
    private dataService: DataService,
    private store: Store<fromRoot.State>
  ) {
    this.init();
  }

  // GET OSP
  public getOrderServiceProfiles(
    tenantId: number,
    page: GridQueryOptions
  ): Observable<OspResults> {
    let url = this.getApisUrl(`${tenantId}/osp/get-order-service-profile-pricing`);

    let params = new HttpParams();
    if(page) {
      page.pageNumber ? params = params.append('pageNumber', page.pageNumber.toString()): '';
      page.pageSize ? params = params.append('pageSize', page.pageSize.toString()) : '';
      page.sortField ? params = params.append('orderBy', page.sortField) : '';
      page.sortOrder ? params = params.append('sortOrder', page.sortOrder) : '';
  
      if (page.filters) {
        Array.from(page.filters).map(item => {
          const index = page.filters.indexOf(item);
          Array.from(Object.keys(item)).map(key => {
            const valueId = Object.keys(item).indexOf(key);
            params = params.append(`Filters[${index}].${key}`, Object.values(item)[valueId]);
          });
        });
      }
    }

    return this.dataService.get<OspResults>(url, { params: params });
  }

  // CREATE OSP
  public createOsp(tenantId: number, osp: CreateOsp): Observable<CreateOsp> {
    let url = this.getApisUrl(`${tenantId}/osp/create-order-service-profile`);
    return this.dataService.post<CreateOsp>(url, osp, {
      headers: { 'content-type': 'application/json' },
    });
  }

  // CREATE DEFAULT OSP - PARTNER SETUP WIZARD
  public createDefaultOsp(
    tenantId: number,
    osp: number
  ): Observable<OrderSubmissionProfile> {
    let url = this.getApisUrl();
    url = `${url}/${tenantId}/osp/default/price/${osp}`;

    return this.dataService.put<OrderSubmissionProfile>(url, {
      headers: { 'content-type': 'application/json' },
    });
  }

  // UPDATE OSP
  public upsertOsp(tenantId: number, osp: UpdateOsp): Observable<CreateOsp> {
    let url = this.getApisUrl(`${tenantId}/osp/upsert-order-service-profile`);
    return this.dataService.post<CreateOsp>(url, osp, {
      headers: { 'content-type': 'application/json' },
    });
  }

  // UPDATE OSP PRICE
  public updateOspPrice(tenantId: number, ospId: number, price: number): Observable<CreateOsp> {
    let url = this.getApisUrl(`${tenantId}/osp/${ospId}/update-order-service-profile-price/${price}`);
    return this.dataService.put<CreateOsp>(url, {
      headers: { 'content-type': 'application/json' },
    });
  }

  // DELETE OSP
  public DeleteOsp(
    tenantId: number,
    profileId: number
  ): Observable<boolean> {
    let url = this.getApisUrl(`${tenantId}/osp/${profileId}/delete-order-service-profile`);

    return this.dataService.delete<boolean>(url);
  }

  private getApisUrl(action: string = null) {
    if (action != null) {
      return `${this.apiUrl}${this.apiPath}/${action}`;
    }
    return this.apiUrl + this.apiPath;
  }

  private get apiPath() {
    const apiPath = '/marketplace/api/tenant';
    return apiPath;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  init(): void {
    this.subscription = this.store
      .select(fromRoot.getApplicationSettings)
      .subscribe((response) => {
        if (response) {
          this.apiUrl = response.apiUrl;
        }
      });

    this.store
      .pipe(
        select(fromAuth.getAuthenticatedUserSelector),
        takeUntil(this.unSubscribe)
      )
      .subscribe((user: AuthorizedUser) => {
        this.authorizedUser = user;
      });
    if (this.authorizedUser && this.authorizedUser.authInfo) {
      this.partnerId = this.authorizedUser.authInfo.partnerInfo.partnerId;
    }
  }
}
