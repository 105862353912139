export enum PermissionsEnum {
  NotSet = 0,

  CreateOrder = 1,
  StageOrder = 2,
  EditOrder = 3,
  DeleteOrder = 4,
  ViewOrders = 5,

  CreateOrderSubmissionProfile = 21,
  EditOrderSubmissionProfile = 22,
  DeleteOrderSubmissionProfile = 23,
  ViewOrderSubmissionProfile = 24,

  CreateOrderFulfillmentProfile = 41,
  EditOrderFulfillmentProfile = 42,
  DeleteOrderFulfillmentProfile = 43,
  ViewOrderFulfillmentProfile = 44
}
