import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Modal, ModalResult } from '@cci-shared/models';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  private modal: Modal;
  private modalName: string = 'templateFormModal';
  private modalRef: any;

  @Input()
  set modalState(_modalState: Modal) {
    if (
      _modalState &&
      _modalState.name &&
      _modalState.name === this.modalName
    ) {
      this.modal = _modalState;
      this.openModal();
    }
  }

  @Output() modalClosed = new EventEmitter<string>();
  @Output() accepted = new EventEmitter<ModalResult>();

  constructor(private confirmationService: ConfirmationService) {}

  ngOnInit() {}

  openModal() {
    this.modalRef = this.confirmationService.confirm({
      message: this.modal.message,
      acceptLabel: this.modal.acceptButtonLabel,
      rejectLabel: this.modal.rejectButtonLabel,
      acceptVisible: this.modal.acceptVisible,
      rejectVisible: this.modal.rejectVisible,
      accept: () => {
        const result = new ModalResult(
          this.modal.name,
          true,
          this.modal.source
        );
        this.accepted.emit(result);
        this.modal = null;
      },
      reject: () => {
        const result = new ModalResult(
          this.modal.name,
          false,
          this.modal.source
        );
        this.accepted.emit(result);
        this.modal = null;
      }
    });
  }

  closeModal() {}
}
