import { RouterEffects } from './router.effect';
import { AppSettingsEffects } from './app-settings.effects';
import { MasterDataEffects } from './master-data.effects';
import { ChangePasswordEffects } from './change-password.effects';
import { UserSetupStepsEffects } from './user-setup-steps.effects';
import { TenantsEffects } from './tenants.effect';
import { OrdersCollectionEffects } from './orders-collection-effect';
import { ModuleActivationEffects } from './module-activation.effects';

export const effects: any[] = [
  AppSettingsEffects,
  RouterEffects,
  MasterDataEffects,
  ChangePasswordEffects,
  UserSetupStepsEffects,
  TenantsEffects,
  OrdersCollectionEffects,
  ModuleActivationEffects
];

export * from './router.effect';
export * from './app-settings.effects';
export * from './master-data.effects';
export * from './change-password.effects';
export * from './tenants.effect';
export * from './orders-collection-effect';
export * from './module-activation.effects';
