import { createAction, props, union } from '@ngrx/store';

export const activateOspActionSuccess = createAction(
  '[Partner Profile] activate Osp Success'
);

export const activateOspActionFailed = createAction(
  '[Partner Profile] activate Osp Failed',
  props<{ errors: any }>()
);

export const activateOfpActionSuccess = createAction(
  '[Partner Profile] activate Ofp Success'
);

export const activateOfpActionFailed = createAction(
  '[Partner Profile] activate Ofp Failed',
  props<{ errors: any }>()
);
