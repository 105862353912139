import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as applicationReducers from '../../store/reducers/root.reducers';
import { completeAuthenticationProcedure } from '../store/actions';

/**
 * Note: Container components are also reusable. Whether or not
 * a component is a presentation component or a container
 * component is an implementation detail.
 */
@Component({
  selector: 'app-log-out-page',
  template: `<app-log-out-callback></app-log-out-callback>`,
})
export class LogOutCallbackPageComponent implements OnInit, OnDestroy {
  constructor(private store: Store<applicationReducers.State>) {}

  public ngOnInit(): void {
    this.store.dispatch(completeAuthenticationProcedure());
  }

  public ngOnDestroy(): void {}
}
