import { NotificationComponent } from '@cci-shared/components/notification/notification.component';
import { GrowlNotificationComponent } from '@cci-shared/components/growl-notification/growl-notification.component';
import { ActionBarComponent } from '@cci-shared/components/action-bar/action-bar.component';
import { GoogleAutoCompleteComponent } from '@cci-shared/components/maps/google/auto-complete/auto-complete.component';
import { NgSideBarComponent } from '@cci-shared/components/ng-side-bar/ng-side-bar.component';
import { AddOspComponent } from '@cci-shared/components/add-osp/add-osp.component';
import { AddOfpComponent } from '@cci-shared/components/add-ofp/add-ofp.component';
import { ServiceAreaInfoComponent } from '@cci-shared/components/service-area-info/service-area-info.component';
import { ServiceAreaInfoReadonlyComponent } from '@cci-shared/components/service-area-info-readonly/service-area-info-readonly.component';
import { StatisticCardComponent } from '@cci-shared/components/statistic-card/statistic-card.component';
import { NgDatepickerCustomComponent } from '@cci-shared/components/ng-datepicker-custom/ng-datepicker-custom.component';
import { NgTimepickerCustomComponent } from '@cci-shared/components/ng-timepicker-custom/ng-timepicker-custom.component';
import { NgStatisticCardComponent } from '@cci-shared/components/ng-statistic-card/ng-statistic-card.component';
import { RegistrationCompleteComponent } from '@cci-shared/components/registration-complete/registration-complete.component';
import { UserInformationComponent } from '@cci-shared/components/user-information/user-information.component';
import { ModalComponent } from '@cci-shared/components/modal/modal.component';
import { ActivateAuthenticatorComponent } from '@cci-shared/components/activate-authenticator/activate-authenticator.component';
import { TwoFactorActivatedComponent } from '@cci-shared/components/two-factor-activated/two-factor-activated.component';
import { TwoFactorComponent } from '@cci-shared/components/two-factor/two-factor.component';
import { LandingBodyComponent } from '@cci-shared/components/body/landing-body.component';
import { TwoFactorNotActivatedComponent } from '@cci-shared/components/two-factor-not-activated/two-factor-not-activated.component';
import { TwoFactorOptionsComponent } from '@cci-shared/components/two-factor-options/two-factor-options.component';
import { TwoFactorMethodComponent } from '@cci-shared/components/two-factor-method/two-factor-method.component';
import { TwoFactorMethodListComponent } from '@cci-shared/components/two-factor-method-list/two-factor-method-list.component';
import { TwoFactorSmsComponent } from '@cci-shared/components/two-factor-sms/two-factor-sms.component';
import { InformationViewComponent } from '@cci-shared/components/information-view/information-view.component';
import { MasterScheduleTableComponent } from '@cci-shared/components/master-schedule-table/master-schedule-table.component';
import { MasterTimeScheduleComponent } from '@cci-shared/components/master-time-schedule/master-time-schedule.component';
import { MobileViewComponent } from '@cci-shared/components/mobile-view/mobile-view.component';
// import { LandingMenuComponent } from '@cci-shared/components/landing-menu/landing-menu.component';

export const components: any[] = [
  StatisticCardComponent,
  NotificationComponent,
  GrowlNotificationComponent,
  ActionBarComponent,
  GoogleAutoCompleteComponent,
  NgSideBarComponent,
  AddOspComponent,
  AddOfpComponent,
  ServiceAreaInfoComponent,
  ServiceAreaInfoReadonlyComponent,
  NgDatepickerCustomComponent,
  NgTimepickerCustomComponent,
  NgStatisticCardComponent,
  RegistrationCompleteComponent,
  UserInformationComponent,
  ModalComponent,
  ActivateAuthenticatorComponent,
  TwoFactorActivatedComponent,
  TwoFactorComponent,
  LandingBodyComponent,
  TwoFactorNotActivatedComponent,
  TwoFactorOptionsComponent,
  TwoFactorMethodComponent,
  TwoFactorMethodListComponent,
  TwoFactorSmsComponent,
  InformationViewComponent,
  MasterScheduleTableComponent,
  MasterTimeScheduleComponent,
  MobileViewComponent,
  // LandingMenuComponent
];

export * from '@cci-shared/components/statistic-card/statistic-card.component';
export * from '@cci-shared/components/notification/notification.component';
export * from '@cci-shared/components/growl-notification/growl-notification.component';
export * from '@cci-shared/components/action-bar/action-bar.component';
export * from '@cci-shared/components/maps/google/auto-complete/auto-complete.component';
export * from '@cci-shared/components/ng-side-bar/ng-side-bar.component';
export * from '@cci-shared/components/add-osp/add-osp.component';
export * from '@cci-shared/components/add-ofp/add-ofp.component';
export * from '@cci-shared/components/service-area-info/service-area-info.component';
export * from '@cci-shared/components/service-area-info-readonly/service-area-info-readonly.component';
export * from '@cci-shared/components/ng-datepicker-custom/ng-datepicker-custom.component';
export * from '@cci-shared/components/ng-timepicker-custom/ng-timepicker-custom.component';
export * from '@cci-shared/components/ng-statistic-card/ng-statistic-card.component';
export * from '@cci-shared/components/registration-complete/registration-complete.component';
export * from '@cci-shared/components/user-information/user-information.component';
export * from '@cci-shared/components/modal/modal.component';
export * from '@cci-shared/components/activate-authenticator/activate-authenticator.component';
export * from '@cci-shared/components/two-factor-activated/two-factor-activated.component';
export * from '@cci-shared/components/two-factor/two-factor.component';
export * from '@cci-shared/components/body/landing-body.component';
export * from '@cci-shared/components/two-factor-not-activated/two-factor-not-activated.component';
export * from '@cci-shared/components/two-factor-options/two-factor-options.component';
export * from '@cci-shared/components/two-factor-method/two-factor-method.component';
export * from '@cci-shared/components/two-factor-method-list/two-factor-method-list.component';
export * from '@cci-shared/components/two-factor-sms/two-factor-sms.component';
export * from '@cci-shared/components/information-view/information-view.component';
export * from '@cci-shared/components/master-schedule-table/master-schedule-table.component';
export * from '@cci-shared/components/master-time-schedule/master-time-schedule.component';
export * from '@cci-shared/components/mobile-view/mobile-view.component';
// export * from '@cci-shared/components/landing-menu/landing-menu.component';
