<div class="p-grid ui-fluid two-factor-sms">
  <form [formGroup]="form">
    <div class="p-col-12 pt-4">
      <div class="p-grid p-grid-form-group">
        <div class="p-col-12 text-center">
          <h2>
            {{ 'twoFactor.twoFactorSms.header' | translate }}
          </h2>
          <p>
            {{ 'twoFactor.twoFactorSms.subHeader' | translate }}
          </p>
        </div>
      </div>
    </div>

    <!-- <div class="p-col-12">
      <div class="p-grid p-grid-form-group">
        <div class="p-col-12">
          <label class="control-label" for="two-factor-sms-country-code">{{
            'common.labels.CountryCode' | translate
          }}</label>
        </div>
        <div class="p-col-12">
          <input
            type="text"
            id="two-factor-sms-country-code"
            autocomplete="off"
            placeholder="{{
              'twoFactor.twoFactorActivated.placeHolders.sixDigitVerificationCode'
                | translate
            }}"
            formControlName="countryCode"
            class="p-inputtext p-component"
          />
          <div
            class="ui-message text-danger ui-corner-all"
            *ngIf="countryCodeControlInvalid"
          >
            <i class="fa fa-close"></i>
            Country code is required.
          </div>
        </div>
      </div>
    </div> -->

    <div class="p-col-12">
      <div class="p-grid p-grid-form-group">
        <div class="p-col-12">
          <label class="control-label" for="two-factor-sms-phone-number">{{
            'common.labels.PhoneNumber' | translate
          }}</label>
        </div>
        <div class="p-col-12">
          <input
            type="text"
            id="two-factor-sms-phone-number"
            autocomplete="off"
            placeholder="{{ 'placeHolders.phoneNumber' | translate }}"
            formControlName="phoneNumber"
            class="p-inputtext p-component"
          />
          <div
            class="ui-message text-danger ui-corner-all"
            *ngIf="phoneNumberControlInvalid"
          >
            <i class="fa fa-close"></i>
            Phone number is required.
          </div>
        </div>
      </div>
    </div>

    <div class="p-col-12">
      <div class="p-grid p-grid-form-group">
        <div class="p-col-6">
          <button
            pButton
            type="button"
            class="p-button"
            label="{{ 'buttons.SendAuthenticationCode' | translate }}"
            [disabled]="!form.valid || twoFactorSmsPending"
            (click)="onSendVerificationCode()"
          ></button>
        </div>
      </div>
    </div>

    <div class="p-col-12">
      <div class="p-grid p-grid-form-group">
        <div class="p-col-12">
          <p>
            {{ 'twoFactor.twoFactorSms.enterVerificationCode' | translate }}
          </p>
        </div>
        <div class="p-col-12">
          <input
            type="text"
            id="two-factor-sms-verification-code"
            autocomplete="off"
            placeholder="{{
              'twoFactor.twoFactorActivated.placeHolders.sixDigitVerificationCode'
                | translate
            }}"
            formControlName="verificationCode"
            class="p-inputtext p-component"
          />
          <div
            class="ui-message text-danger ui-corner-all"
            *ngIf="verificationCodeControlInvalid"
          >
            <i class="fa fa-close"></i>
            Verification code is required.
          </div>
          <div
            class="ui-message text-danger ui-corner-all"
            *ngIf="verificationCodeControlIsNotValid"
          >
            <i class="fa fa-close"></i>
            Verification code is not valid.
          </div>
        </div>
      </div>
    </div>

    <div class="p-col-12">
      <div class="p-grid p-grid-form-group">
        <div class="p-col-8">
          <button
            pButton
            type="button"
            class="p-button button-full-width"
            label="{{ 'buttons.Verify' | translate }}"
            [disabled]="
              !canVerify || twoFactorSmsPending || !twoFactorSmsCodeSent
            "
            (click)="onVerifyPhoneNumber()"
          ></button>
        </div>
        <div class="p-col-4">
          <button
            pButton
            type="button"
            class="p-button button-full-width"
            label="{{ 'buttons.Cancel' | translate }}"
            (click)="onCancel()"
          ></button>
        </div>
      </div>
    </div>
  </form>
</div>
