import 'rxjs/Rx';
import { Observable } from 'rxjs';

import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpParams, HttpResponse } from '@angular/common/http';

import { DataService } from '../../core/services/data.service';
import { Tenant } from '../../shared/models/tenant';
import * as fromRoot from '../../store/reducers';
import { getApplicationSettings } from '../../store/selectors/app-settings.selector';

@Injectable()
export class SystemService implements OnDestroy {
  private registrationUrl: string | null;
  private subscription;

  constructor(
    private dataService: DataService,
    private store: Store<fromRoot.State>
  ) {
    this.init();
  }

  getTenants(): Observable<Tenant[]> {
    const url = this.getApisUrl('GetAllTenants');

    return this.dataService.get<Tenant[]>(url);
  }

  getTenant(guid: string): Observable<Tenant> {
    let url = this.getApisUrl('GetTenantById');

    url = `${url}/${guid}`;

    return this.dataService.get<Tenant>(url);
  }

  updateTenant(id: number, tenant: Tenant): Observable<HttpResponse<Tenant>> {
    let url = this.getApisUrl('UpdateTenant');
    url = `${url}/${id}`;

    const params = new HttpParams().set('id', id.toString());

    return this.dataService.put(url, tenant, { params: params });
  }

  private getApisUrl(action: string = null) {
    if (action != null) {
      return `${this.registrationUrl}${this.apiPath}/${action}`;
    }
    return `${this.registrationUrl}${this.apiPath}`;
  }

  get apiPath(): string {
    const apiPath = '/marketplace/api/Tenant';
    return apiPath;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  init(): void {
    this.subscription = this.store
      .select(getApplicationSettings)
      .subscribe((response) => {
        if (response) {
          this.registrationUrl = response.administrationUrl;
          console.log(this.registrationUrl);
        }
      });
  }
}
