<div class="p-grid two-factor-options">
    <div class="p-col-12 option-seperator">
      <div class="p-grid p-grid-form-group">
        <div class="p-col-6">
          <label>{{ provider.name }}
            <fa-icon
              [icon]="['fal', 'info-circle']"
              (click)="infoSideBar(twofactorOptionSidebarInfo)"
            ></fa-icon>
          </label>
        </div>
        <div class="p-col-6">
          <p-selectButton
            [options]="provider.active ? configuredOptions: notconfiguredOptions"
            [(ngModel)]="selectedOption"
            (onChange)="onShowSelectTwoFactorOption(provider)"
          ></p-selectButton>
        </div>
      </div>
    </div>
</div>
