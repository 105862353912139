import { Action } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { SideBarContent, SideBarClosedResult } from '../../../shared/models';

export enum LayoutActionTypes {
  NAVIGATE_HOME = '[App] Navigate Home',
  LOAD_NAVIGATION_MENU_ITEMS = '[App] Load Navigation Menu Items',
  SHOW_APPLICATION_MENU = '[App] Show Application Menu',
  SET_APPLICATION_SIDEBAR_ACTIVE = '[App] Set Application Sidebar Menu Active',
  SET_APPLICATION_MOBILE_MENU_ACTIVE = '[App] Set Application Mobile Menu Active',
  SET_APPLICATION_MENU_BUTTON_STATE = '[App] Set Application Menu Button State',
  SET_APPLICATION_MENU_STATE = '[App] Set Application Menu State',
  SET_APPLICATION_SUBMENU_ACTIVE_INDEX = '[App] Set Application Sub Menu Active Index',
  SET_APPLICATION_SUBMENU_HOVER = '[App] Set Application Sub Menu Hover',
  SET_ACTIVE_MODULE_HEADER_TEXT = '[App] Set Active Module Header Text',
  NavigationMenuItemsLoaded = '[App] Navigation Menu Items Loaded',
  SHOW_FOOTER = '[Footer] Show',
  HIDE_FOOTER = '[Footer] Hide',
  ShowSideBar = '[Side Bar] = Show Side Bar',
  HideSideBar = '[Side Bar] = Hide Side Bar',
  HideSideBarNotificationRecived = '[Side Bar] = Hide Side Bar Notification Recieved',
  SetActiveMenu = '[Tob Bar] = Active Menu For set Breadcrumbs'
}

export class NavigateHome implements Action {
  readonly type = LayoutActionTypes.NAVIGATE_HOME;
}

export class LoadNavigationMenuItemsAction implements Action {
  readonly type = LayoutActionTypes.LOAD_NAVIGATION_MENU_ITEMS;
}

export class NavigationMenuItemsLoaded implements Action {
  readonly type = LayoutActionTypes.NavigationMenuItemsLoaded;
  constructor(public payload: Array<MenuItem>) {}
}

export class ShowApplicationMenu implements Action {
  readonly type = LayoutActionTypes.SHOW_APPLICATION_MENU;
}

export class SetApplicationSidebarActive implements Action {
  readonly type = LayoutActionTypes.SET_APPLICATION_SIDEBAR_ACTIVE;

  constructor(public payload: boolean) {}
}

export class SetApplicationMobileMenuActive implements Action {
  readonly type = LayoutActionTypes.SET_APPLICATION_MOBILE_MENU_ACTIVE;

  constructor(public payload: boolean) {}
}

export class SetApplicationMenuButtonState implements Action {
  readonly type = LayoutActionTypes.SET_APPLICATION_MENU_BUTTON_STATE;

  constructor(public payload: boolean) {}
}

export class SetApplicationMenuState implements Action {
  readonly type = LayoutActionTypes.SET_APPLICATION_MENU_STATE;

  constructor(public payload: boolean) {}
}

export class SetApplicationSubMenuActiveIndex implements Action {
  readonly type = LayoutActionTypes.SET_APPLICATION_SUBMENU_ACTIVE_INDEX;

  constructor(public payload: number) {}
}

export class SetApplicationSubMenuHover implements Action {
  readonly type = LayoutActionTypes.SET_APPLICATION_SUBMENU_HOVER;

  constructor(public payload: boolean) {}
}

export class SetActiveModuleHeaderText implements Action {
  readonly type = LayoutActionTypes.SET_ACTIVE_MODULE_HEADER_TEXT;

  constructor(public payload: string) {}
}

export class ShowFooter implements Action {
  readonly type = LayoutActionTypes.SHOW_FOOTER;

  constructor() {}
}

export class HideFooter implements Action {
  readonly type = LayoutActionTypes.HIDE_FOOTER;

  constructor() {}
}

export class ShowSideBar implements Action {
  readonly type = LayoutActionTypes.ShowSideBar;
  constructor(public payload: SideBarContent) {}
}

export class HideSideBar implements Action {
  readonly type = LayoutActionTypes.HideSideBar;
  constructor(public payload: SideBarClosedResult) {}
}

export class HideSideBarNotifiationRecieved implements Action {
  readonly type = LayoutActionTypes.HideSideBarNotificationRecived;
}

export class SetActiveMenu implements Action {
  readonly type = LayoutActionTypes.SetActiveMenu;
  constructor(public payload: string) {}
}

export type LayoutActions =
  | NavigateHome
  | LoadNavigationMenuItemsAction
  | ShowApplicationMenu
  | SetApplicationSidebarActive
  | SetApplicationMobileMenuActive
  | SetApplicationMenuState
  | SetApplicationMenuButtonState
  | SetApplicationSubMenuActiveIndex
  | SetApplicationSubMenuHover
  | SetActiveModuleHeaderText
  | ShowFooter
  | HideFooter
  | ShowSideBar
  | HideSideBar
  | HideSideBarNotifiationRecieved
  | NavigationMenuItemsLoaded
  | SetActiveMenu;
