<div class="p-grid ui-fluid two-factor-options">
  <form>
    <div class="p-col-12 pt-4">
      <div class="p-grid p-grid-form-group">
        <div class="p-col-12 text-center">
          <h2>
            {{
              'twoFactor.twoFactorOptions.header'
                | translate
            }}
          </h2>
          <p>
            {{
              'twoFactor.twoFactorOptions.subHeader'
                | translate
            }}
          </p>
        </div>
      </div>
    </div>

    <div class="p-col-12 options">
      <div class="p-grid p-grid-form-group">
        <div class="p-col-12 option_container  text-center">
          <p>
            {{
              'twoFactor.twoFactorOptions.authenticatorOptionHeader' | translate
            }}
          </p>
          <button
            pButton
            type="button"
            label="{{
              'twoFactor.twoFactorOptions.buttons.setUpUsingAnApp' | translate
            }}"
            class="p-button button-full-width"
            (click)="onSetupUsingAnApp()"
          ></button>
        </div>
        <div class="p-col-12 option_container  text-center">
          <p>
            {{ 'twoFactor.twoFactorOptions.smsOptionHeader' | translate }}
          </p>
          <button
            pButton
            type="button"
            label="{{
              'twoFactor.twoFactorOptions.buttons.setUpUsingSms' | translate
            }}"
            class="p-button button-full-width"
            (click)="onSetupUsingSms()"
          ></button>
        </div>
      </div>
    </div>
  </form>
</div>
