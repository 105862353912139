import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SideBarContent, TwoFactorProvider } from '@cci/shared/models';
import { FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-two-factor-method-list',
  templateUrl: './two-factor-method-list.component.html',
  styleUrls: ['./two-factor-method-list.component.scss'],
})
export class TwoFactorMethodListComponent implements OnInit {
  @Input()
  public form: FormGroup;
  @Input() public providersAray: FormArray;
  @Input() public providers: TwoFactorProvider[];

  @Output() showSelectTwoFactorOption = new EventEmitter<TwoFactorProvider>();
  @Output()
  showSideBar = new EventEmitter<SideBarContent>();

  constructor() {}

  ngOnInit() {
    console.log(this.providers);
  }

  onShowSelectTwoFactorOption(provider: TwoFactorProvider) {
    this.showSelectTwoFactorOption.emit(provider);
  }

  onShowSideBar(content) {
    this.showSideBar.emit(content);
  }
 
}
