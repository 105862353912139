import { MenuItem } from 'primeng/api';

export let initialMenuItems: Array<MenuItem> = [
  {
    label: 'Home',
    icon: 'fa fa-fw fa-home',
    routerLink: ['/auth/my-dashboard'],
    routerLinkActiveOptions: { exact: true },
  },
  {
    label: 'Orders',
    icon: 'fa fa-fw fa-folder',
    routerLink: ['auth/orders'],
    routerLinkActiveOptions: { exact: true },
  },
  {
    label: 'Researchers',
    icon: 'fal fa-handshake faInfoCircle',
    routerLink: ['/auth/user-profile/user-affiliation'],
    routerLinkActiveOptions: { exact: true },
  },
  {
    label: 'Module Mgtm',
    routerLink: [],
    routerLinkActiveOptions: { exact: true },
    items: [
      {
        label: 'Marketplace',
        icon: 'fal fa-network-wired',
        routerLink: ['/auth/module-management/marketplace'],
      },
    ],
  },
  {
    label: 'Profile',
    icon: 'fa fa-fw fa-user-circle',
    routerLink: ['/auth/user-profile/'],
    routerLinkActiveOptions: { exact: true },
    items: [
      {
        label: 'Account Management',
        icon: 'fa fa-fw fa-columns',
        routerLink: ['/auth/user-profile'],
      },
      {
        label: 'Vacation',
        icon: 'fa fa-fw fa-code',
        routerLink: ['/auth/user-profile/time-off'],
      },
      {
        label: 'Work Profile',
        icon: 'fa fa-fw fa-list-alt',
        routerLink: ['/auth/user-profile/work-profile'],
      },
      {
        label: 'Work Schedule',
        icon: 'fa fa-fw fa-square',
        routerLink: ['/auth/user-profile/work-schedule'],
      },
      {
        label: 'User Information',
        icon: 'fa fa-fw fa-table',
        routerLink: ['/auth/user-profile/user-information'],
      },
      {
        label: 'Change Password',
        icon: 'fa fa-fw fa-square',
        routerLink: ['/auth/user-profile/change-password'],
      },
    ],
  },
  {
    label: 'Reports',
    icon: 'fa fa-fw fa-user-circle',
    routerLink: ['/auth/user-profile'],
    routerLinkActiveOptions: { exact: true },
  },
  {
    label: 'Users',
    icon: 'fa fa-fw fa-users',
    routerLink: ['/auth/users-management'],
    routerLinkActiveOptions: { exact: true },
  },
  {
    label: 'Roles',
    icon: 'fa fa-fw fa-genderless',
    routerLink: ['/auth/roles-management'],
    routerLinkActiveOptions: { exact: true },
  },
  {
    label: 'Tenants',
    icon: 'fa fa-fw fa-university fa-key',
    routerLink: ['/auth/tenants'],
    routerLinkActiveOptions: { exact: true },
  },
];
