<!-- <div [formGroup]="form">
  <div formArrayName="providersArray">
    <div *ngFor="let item of providers; let idx = index">
      <app-two-factor-method [provider]="item"> </app-two-factor-method>
    </div>
  </div>
</div>
 -->

<!-- <div *ngFor="let item of providers; let idx = index">
  <app-two-factor-method [provider]="item"> </app-two-factor-method>
</div> -->

<ul class="providers">
  <li *ngFor="let provider of providers" class="provider">
    <app-two-factor-method
      [provider]="provider"
      (showSideBar)="onShowSideBar($event)"
      (showSelectTwoFactorOption)="onShowSelectTwoFactorOption($event)"
    >
    </app-two-factor-method>
  </li>
</ul>
