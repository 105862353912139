import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, OnInit, ErrorHandler, LOCALE_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { StoreModule, Store } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  StoreRouterConnectingModule,
  DefaultRouterStateSerializer,
  RouterStateSerializer,
} from '@ngrx/router-store';

// not used in production
/* mport { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { storeFreeze } from 'ngrx-store-freeze'; */

import { AppErrorHandler } from './modules/core/services/app-error.handler';

import { CoreModule } from './modules/core/';
import { SharedModule } from '@cci/shared/shared.module';
import { CustomRouterStateSerializer } from '@cci/shared/utils/custom-routerstate-serializer';
import { RootRoutingModule } from './root-routing.module';
import { effects, ROOT_REDUCERS, metaReducers } from './modules/store';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
// Import your library
import { NgxPermissionsModule } from 'ngx-permissions';

import { RootComponent } from './root.component';
import { AuthenticationModule } from './modules/auth';
import * as fromCoreServices from './modules/core/services';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
// import { library } from '@fortawesome/fontawesome-svg-core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import * as fontIcons from './font-awesome';

/* import { environment } from '../environments/environment'; */

@NgModule({
  declarations: [RootComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    NgxPermissionsModule.forRoot(),
    /**
     * StoreModule.forRoot is imported once in the root module, accepting a reducer
     * function or object map of reducer functions. If passed an object of
     * reducers, combineReducers will be run creating your application
     * meta-reducer. This returns all providers for an @ngrx/store
     * based application.
     */
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),

    /**
     * @ngrx/router-store keeps router state up-to-date in the store.
     */
    StoreRouterConnectingModule.forRoot({
      serializer: DefaultRouterStateSerializer,
    }),
    /*  !environment.production ? StoreDevtoolsModule.instrument() : [], */

    /**
     * EffectsModule.forRoot() is imported once in the root module and
     * sets up the effects class to be initialized immediately when the
     * application starts.
     *
     * See: https://github.com/ngrx/platform/blob/master/docs/effects/api.md#forroot
     */
    EffectsModule.forRoot(effects),

    NgIdleKeepaliveModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: fromCoreServices.TranslateLanguageLoader,
      },
    }),

    SharedModule,
    CoreModule.forRoot(null),
    AuthenticationModule,
    RootRoutingModule,
    TimepickerModule.forRoot(),
  ],
  providers: [
    /**
     * The `RouterStateSnapshot` provided by the `Router` is a large complex structure.
     * A custom RouterStateSerializer is used to parse the `RouterStateSnapshot` provided
     * by `@ngrx/router-store` to include only the desired pieces of the snapshot.
     */
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    { provide: ErrorHandler, useClass: AppErrorHandler },
  ],
  bootstrap: [RootComponent],
})
export class RootModule {
  constructor(private library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons(...fontIcons.icons);
  }
}

export class WindowRefService {
  public getWindow(): Window {
      return window;
  }
}

export function languageFactory(windowRef: WindowRefService): string {
return windowRef.getWindow().navigator.language;
}

export const localeProvider = {
provide: LOCALE_ID,
deps: [WindowRefService],
useFactory: languageFactory
};
