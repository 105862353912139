import { InjectionToken } from '@angular/core';
import {
  ActionReducerMap,
  createSelector,
  createFeatureSelector,
  ActionReducer,
  MetaReducer,
  combineReducers,
  Action,
} from '@ngrx/store';

import { environment } from '../../../../environments/environment';
import { RouterStateUrl } from '../../shared/utils';
import * as fromRouter from '@ngrx/router-store';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */

import * as fromAppSettings from './app-settings-reducer';
import * as applicationLayout from '../../core/app/reducers';
import * as fromConstants from './constants.reducer';
import * as fromLayout from './layout.reducer';
import * as fromModal from './modal.reducer';
import * as fromChangePassword from './change-password.reducer';
import * as fromUserSetupSteps from './user-setup-steps.reducer';
import * as fromTenants from './tenants.reducer';
import * as fromOrders from './orders-collection.reducer';
import * as fromModuleActivation from './module-activation.reducer';
import * as fromFieldProcessingRecording from './field-processing-recording.reducer';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  appSettings: fromAppSettings.AppSettingsState;
  app: applicationLayout.State;
  constants: fromConstants.ConstantsState;
  router: fromRouter.RouterReducerState<any>;
  layout: fromLayout.LayoutState;
  modal: fromModal.ModalState;
  changePassword: fromChangePassword.ChangePasswordState;
  userSetupSteps: fromUserSetupSteps.UserTodoState;
  tenant: fromTenants.TenantState;
  ordersCollection: fromOrders.OrdersState
  moduleActivation: fromModuleActivation.ModuleActivateState,
  fieldProcessingRecording: fromFieldProcessingRecording.FieldProcessingRecording
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const ROOT_REDUCERS = new InjectionToken<
  ActionReducerMap<State, Action>
>('Root reducers token', {
  factory: () => ({
    appSettings: fromAppSettings.reducer,
    app: applicationLayout.reducer,
    constants: fromConstants.reducer,
    router: fromRouter.routerReducer,
    layout: fromLayout.reducer,
    modal: fromModal.reducer,
    changePassword: fromChangePassword.reducer,
    userSetupSteps: fromUserSetupSteps.reducer,
    tenant: fromTenants.reducer,
    ordersCollection: fromOrders.reducer,
    moduleActivation: fromModuleActivation.reducer,
    fieldProcessingRecording: fromFieldProcessingRecording.reducer
  }),
});

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return function (state: State, action: any): State {
    // console.log('state', state);
    // console.log('action', action);

    return reducer(state, action);
  };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [logger] /* [logger] */
  : [];

/**
 * Selectors for Configuration Reducers if any
 */
export const getAppSettingsState = createFeatureSelector<fromAppSettings.AppSettingsState>(
  'appSettings'
);

/**
 * Selectors for App Reducers if any
 */

export const getApplicationStateSelector = createFeatureSelector<applicationLayout.State>(
  'app'
);
export const applicationState = createSelector(
  getApplicationStateSelector,
  (state: applicationLayout.State) => state
);

/**
 * Reducers for the router state
 */
export const getRouterState = createFeatureSelector<
  fromRouter.RouterReducerState<RouterStateUrl>
>('router');

// Layout
/**
 * Selectors for Configuration Reducers if any
 */
export const getLayoutState = createFeatureSelector<fromLayout.LayoutState>(
  'layout'
);

// Layout
/**
 * Selectors for Configuration Reducers if any
 */
export const getModalState = createFeatureSelector<fromModal.ModalState>(
  'modal'
);

// ChangePassword
/**
 * Selectors for Configuration Reducers if any
 */
export const getChangePasswordState = createFeatureSelector<fromChangePassword.ChangePasswordState>(
  'changePassword'
);

// User Setup Steps
/**
 * Selectors for Configuration Reducers if any
 */
export const getSetupStepsState = createFeatureSelector<fromUserSetupSteps.UserTodoState>(
  'userSetupSteps'
);

// Tenants
/**
 * Selectors for Configuration Reducers if any
 */
 export const getTenantsState = createFeatureSelector<fromTenants.TenantState>(
  'tenant'
);

// Orders
/**
 * Selectors for Configuration Reducers if any
 */
 export const getOrdersdState = createFeatureSelector<fromOrders.OrdersState>(
  'ordersCollection'
);

// Module Activation
/**
 * Selectors for Configuration Reducers if any
 */
export const getModuleActivationState = createFeatureSelector<fromModuleActivation.ModuleActivateState>(
  'moduleActivation'
);

// Field Processing
/**
 * Selectors for Configuration Reducers if any
 */
export const getFieldProcessingRecordingState = createFeatureSelector<fromFieldProcessingRecording.FieldProcessingRecording>(
  'fieldProcessingRecording'
);

export const getLayoutMenuItems = createSelector(
  applicationState,
  applicationLayout.getMainMenuItems
);
export const getLayoutMenuLayoutStatic = createSelector(
  applicationState,
  applicationLayout.getMenuLayoutStatic
);
export const getLayoutMobileMenuActive = createSelector(
  applicationState,
  applicationLayout.getMobileMenuActive
);
export const getLayoutMenuClickedState = createSelector(
  applicationState,
  applicationLayout.getApplicationMenuClickState
);
export const getLayoutMenuButtonClickedState = createSelector(
  applicationState,
  applicationLayout.getApplicationMenuButtonClickedState
);
export const getLayoutApplicationSubmenuActiveIndex = createSelector(
  applicationState,
  applicationLayout.getApplicationSubmenuActiveIndex
);
export const getLayoutApplicationSubmenuHover = createSelector(
  applicationState,
  applicationLayout.getApplicationSubmenuHover
);
export const getLayoutApplicationSidebarActive = createSelector(
  applicationState,
  applicationLayout.getApplicationSidebarActive
);
export const getLayoutApplicationFooterActive = createSelector(
  applicationState,
  applicationLayout.getApplicationFooterActive
);

export const getActiveModuleHeader = createSelector(
  applicationState,
  applicationLayout.getActiveModuleHeaderText
);

export const getOpenSideBarContent = createSelector(
  applicationState,
  applicationLayout.getOpenSideBarContent
);

export const getClosedSideBarResult = createSelector(
  applicationState,
  applicationLayout.getClosedSideBarResult
);

export const getActiveMenu = createSelector(
  applicationState,
  applicationLayout.getActiveMenu
);
