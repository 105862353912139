import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationGuard, UserProfileGuard } from './modules/core/gaurds';

const routes: Routes = [
  { path: '',
    redirectTo: '/auth',
    pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/main.module').then((m) => m.MainModule),
    canActivate: [AuthenticationGuard, UserProfileGuard],
  },
  {
    path: 'noauth',
    loadChildren: () =>
      import('./modules/registration/registration.module').then(
        (m) => m.RegistrationModule
      ),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./modules/error-pages/error-pages.module').then(
        (m) => m.ErrorPagesModule
      ),
  },
  {
    path: '**',
    pathMatch: 'full',
    loadChildren: () =>
      import('./modules/error-pages/error-pages.module').then(
        (m) => m.ErrorPagesModule
      ),
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    /* preloadingStrategy: PreloadAllModules, */
    enableTracing: true,
    relativeLinkResolution: 'legacy'
}),
  ], // {enableTracing: true}
  exports: [RouterModule],
})
export class RootRoutingModule {}
