import { createAction, props, union } from '@ngrx/store';

export const activateOspAction = createAction(
  '[Partner Profile] activate Osp',
  props<{
    tenantId: number;
    osp: number;
  }>()
);

export const activateOfpAction = createAction(
  '[Partner Profile] activate Ofp',
  props<{
    tenantId: number;
    ofp: number;
  }>()
);