
<form [formGroup]="form">
  <div class="p-col-12 p-lg-10 p-lg-offset-1 select-module-form">
    <div class="ui-fluid">
      <div class="ui-g">
        <div class="ui-g-12 ui-sm-12">
          <div class="ui-grid ui-grid-responsive ui-grid-pad ui-fluid"
                style="margin: 10px 0px"
          >
            <p-panel styleClass="master-schedule-panel">
              <p-header>
                <span *ngIf="isMasterSchedule" style="font-size: 20px">{{ 'masterSchedulePage.panel.header.majorLabel' | translate }}</span>
                <span *ngIf="!isMasterSchedule && schedule == 'countySchedule'" style="font-size: 20px">{{ 'countySchedulePage.panel.header.majorLabel' | translate }}</span>
                <span *ngIf="!isMasterSchedule && schedule !== 'countySchedule'" style="font-size: 20px">{{ 'serviceAreaSchedulePage.panel.header.majorLabel' | translate }}</span>
                <p style="font-size: 18px">{{ 'masterSchedulePage.panel.header.minorLabel' | translate }}</p>
              </p-header>
              
                <div class="p-col-12 p-md-12 p-sm-12">
                  <div class="p-grid p-grid-form-group">
                    <div class="p-lg-6 p-lg-offset-4 p-md-offset-4 p-md-6 p-sm-12">
                      <div class="p-grid">
                        <div class="p-col-6">
                          <label
                            class="header-label"
                            >{{ 'masterSchedulePage.start' | translate }}</label
                          >
                          <hr class="line">
                        </div>
                        <div class="p-col-6">
                          <label
                            class="header-label"
                            >{{ 'masterSchedulePage.end' | translate }}</label
                          >
                          <hr class="line">
                        </div>
                    </div>
                  </div>
                </div>
                </div>
                <div *ngFor="let schedule of masterSchedule.controls; let i=index" class="p-col-12 p-md-12">
                  <app-master-time-schedule 
                    [form]="form"
                    [scheduleForm]="schedule"
                    [index]="i"
                    [masterSchedule]="masterSchedule"
                    [weekDays]="weekDays"
                    [dailyStartStopTimeForCompany]="dailyStartStopTimeForCompany"
                    [showActive]="showActive"
                    [isResearcherPayPerOrder]="isResearcherPayPerOrder"
                    [isPartner]="isPartner"
                  ></app-master-time-schedule>
                </div>
                  
            </p-panel>
            <div *ngIf="isPartner || (isResearcherPayPerOrder && !priceDisabled)" class="p-col-12 p-lg-12 form-container">
              <div class="p-grid module">
                <div class="p-col-12 padding-left">
                  <span class="reset-span">
                    <button
                      pButton
                      type="button"
                      class="p-button tool-bar"
                      label="{{ 'buttons.resetToDefault' | translate }}"
                      [disabled]="!isPristinedScheduleForm"
                      (click)="onResetDefault()"
                    ></button>
                    <span class="infoIcon">
                      <i class="fal fa-info-circle"></i>
                    </span>
                  </span>
                </div>
                <div *ngIf="showError" class="p-col-12 p-md-12 padding-left">
                  <label class="text-danger"
                    >{{ 'invitePartners.errorLabel' | translate }}
                  </label>
                </div>
                <div class="p-col-12 p-md-12 padding-left">
                  <label
                    >{{ 'invitePartners.OrdersLabel' | translate }}
                  </label>
                  <div class="p-col-3 p-md-3 p-sm-6 padding-left">
                    <input
                      id="orders"
                      type="number"
                      min="1"
                      (keypress)="onKeyPress($event)"
                      placeholder="{{ 'invitePartners.placeholders.ex' | translate }}"
                      class="p-inputtext p-component flag-validity"
                      formControlName="maxOrders"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="(!isPartner && !isResearcherPayPerOrder) || (isResearcherPayPerOrder && priceDisabled)" class="p-col-12 p-md-12 padding-left">
              <label
                >{{ 'invitePartners.OrdersLabel' | translate }}
              </label>
              <div class="p-col-3 p-md-3 p-sm-6 padding-left">
                <input
                  id="orders"
                  type="number"
                  min="1"
                  placeholder="{{ 'invitePartners.placeholders.ex' | translate }}"
                  class="p-inputtext p-component flag-validity"
                  formControlName="maxOrders"
                  pInputText 
                  disabled
                />
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
  </div>
</form>
