import { TodosByUser } from '@cci/shared/models/todos-by-user';
import { createAction, props, union } from '@ngrx/store';

export const loadUserSetupStepsSuccess = createAction(
  '[User Setup Steps Api] Load User Setup Steps Success',
  props<{ payload: TodosByUser }>()
);

export const loadUserSetupStepsFailed = createAction(
  '[User Setup Steps Api] Load User Setup Steps Failed',
  props<{ errors: any }>()
);

export const resetUserSetupStepsSuccess = createAction(
  '[User Setup Steps Api] Reset User Setup Steps Success'
);

export const resetUserSetupStepsFailed = createAction(
  '[User Setup Steps Api] Reset User Setup Steps Failed',
  props<{ errors: any }>()
);
