import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { User } from 'oidc-client';
import { asyncScheduler, EMPTY as empty, of } from 'rxjs';

import {
  catchError,
  debounceTime,
  map,
  skip,
  switchMap,
  takeUntil,
  mergeMap,
} from 'rxjs/operators';
import {
  completeAuthenticationProcedure,
  reloadAuthenticatedUserData,
  signOut,
  startAuthenticationProcedure,
  startSignOutProcedure,
} from '../actions/authentication-api.actions';
import {
  authenticatedUserDataLoaded,
  authenticationPending,
  loadAuthenticatedUserDataFailed,
  loadAuthenticatedUserDataSuccess,
  reloadAuthenticatedUserDataFailed,
  reloadAuthenticatedUserDataSuccess,
  signOutSuccess,
  userAlreadyAuthenticated,
  userAuthenticated,
  userAuthenticationProcedureComplete,
  userAuthetnticationFailed,
} from '../actions/authentication.actions';
import { NavigationMenuItemsLoaded } from '../../../core/app/actions/layout-actions';
import { go } from '@cci/store/actions/router.action';

import { UsersService } from '@cci/core/services/users.service';
import { AuthenticationService } from '@cci/core/services/authentication.service';
import { LocalStorageService } from '@cci/core/services/local-storage.service';

import { StorageKeys } from '@cci/shared/constants/storage-keys';
import { Urls } from '@cci/shared/constants/url-constants';
import { UserService } from '@cci/core/services/user-service';

@Injectable()
export class AuthenticationEffects {
  startAuthenticationProcedure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startAuthenticationProcedure),
      mergeMap(() => {
        return this.service.startAuthentication().pipe(
          map(() => authenticationPending()),
          catchError((error) => of(userAuthetnticationFailed()))
        );
      })
    )
  );

  completeAuthenticationProcedure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(completeAuthenticationProcedure),
      mergeMap(() => {
        return this.service.completeAuthentication().pipe(
          map((user: User) => {
            if (user && !user.expired) {
              return userAuthenticated({ payload: user });
            } else {
              return userAuthetnticationFailed();
            }
          }),
          catchError((error) => of(userAuthetnticationFailed()))
        );
      })
    )
  );

  authenticatedUserDataLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticatedUserDataLoaded),
      mergeMap((data) => [
        loadAuthenticatedUserDataSuccess({ payload: data.payload }),
        new NavigationMenuItemsLoaded(data.payload.navigationMenus),
      ])
    )
  );

  loadAuthenticatedUserData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userAuthenticationProcedureComplete, authenticatedUserDataLoaded),
      mergeMap(() => {
        return this.userService.getAuthenticatedUser().pipe(
          mergeMap((result) => [
            loadAuthenticatedUserDataSuccess({ payload: result }),
            new NavigationMenuItemsLoaded(result.navigationMenus),
          ]),
          catchError((error) =>
            of(loadAuthenticatedUserDataFailed({ error: error }))
          )
        );
      })
    )
  );

  reloadAuthenticatedUserData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reloadAuthenticatedUserData),
      mergeMap(() => {
        return this.userService.getAuthenticatedUser().pipe(
          mergeMap((result) => [
            reloadAuthenticatedUserDataSuccess({ payload: result }),
            new NavigationMenuItemsLoaded(result.navigationMenus),
          ]),
          catchError((error) =>
            of(reloadAuthenticatedUserDataFailed({ error: error }))
          )
        );
      })
    )
  );

  signOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signOut, userAuthetnticationFailed),
      mergeMap(() => {
        return this.service.isAuthorized().pipe(
          map((isAuthorized: boolean) => {
            if (isAuthorized) {
              return startSignOutProcedure();
            } else {
              return startAuthenticationProcedure();
            }
          })
        );
      })
    )
  );

  startSignOutProcedure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startSignOutProcedure),
      mergeMap(() => {
        return this.service.startSignoutMainWindow().pipe(
          map(() => signOutSuccess()),
          catchError((error) => of(userAuthetnticationFailed()))
        );
      })
    )
  );

  userAuthenticated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userAuthenticated),
      mergeMap(() => {
        let urlToActivate = <string>(
          this.localStorage.get(StorageKeys.ACTIVATE_URL)
        );

        if (!urlToActivate && this.router.url.length > 1) {
          urlToActivate = this.router.url;
        }

        if (
          !urlToActivate ||
          urlToActivate.length === 0 ||
          urlToActivate === Urls.ResearcherRegistration ||
          urlToActivate === Urls.PartnerRegistration ||
          this.router.url
        ) {
          urlToActivate = Urls.Home;
        }

        return [
          go({
            path: [urlToActivate],
          }),
        ];
      })
    )
  );

  userAlreadyAuthenticated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userAlreadyAuthenticated),
      mergeMap(() => {
        return this.service.isAuthorized().pipe(
          map((isAuthorized: boolean) => {
            if (isAuthorized) {
              const user = this.service.getAuthorizedUser();
              return userAuthenticated({ payload: user });
            } else {
              return userAuthetnticationFailed();
            }
          }),
          catchError((error) => of(userAuthetnticationFailed()))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private service: AuthenticationService,
    private userService: UserService,
    private localStorage: LocalStorageService,
    private router: Router
  ) {}
}
