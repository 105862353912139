import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { SideBarContent, WeekDay, WorkDay } from '@cci/shared/models';
import { AppTranslationService } from '@cci/core/services';

@Component({
  selector: 'app-master-time-schedule',
  templateUrl: './master-time-schedule.component.html',
  styleUrls: ['./master-time-schedule.component.scss']
})
export class MasterTimeScheduleComponent implements OnInit {
  @Output()
  showSideBar = new EventEmitter<SideBarContent>();
  @Input() form: FormGroup;
  @Input() masterSchedule: FormArray;
  @Input() scheduleForm: FormGroup;
  @Input() formArray: FormArray;
  @Input() weekDays: WeekDay[];
  @Input() dailyStartStopTimeForCompany: WorkDay[];
  @Input() index: number;
  @Input() showActive: boolean;
  @Input() isResearcherPayPerOrder: boolean;
  @Input() isPartner: boolean;
  isMarketPlaceModule = false;
  isAffiliateModule = false;
  day: string;
  startTime;
  endTime;
  isDisabled: boolean;
  checked: boolean;

  constructor(
    private translationService: AppTranslationService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.translate = (key: string) =>
      this.translationService.getTranslation(key);

    if (!this.isResearcherPayPerOrder && !this.isPartner) {
      this.scheduleForm.controls.startTime.disable();
      this.scheduleForm.controls.endTime.disable();
    }

    this.day = this.weekDays[this.index].name;
    const control = <FormArray>this.scheduleForm.controls['weekDays'];
    control.push(
      this.patchValues(
        this.weekDays[this.index].id,
        this.weekDays[this.index].name
      )
    );

    this.setDefaultTimeSchedule(this.index);
  }

  setDefaultTimeSchedule(index) {
    Array.from(this.dailyStartStopTimeForCompany).map((item) => {
      if (item.weekDayId === index + 1) {
        const startTime = item['startTime'].toString().split('Z')[0]; // Stop  convertion UTC to Local
        const endTime = item['endTime'].toString().split('Z')[0];

        this.startTime = new Date(startTime);
        this.endTime = new Date(endTime);
        this.scheduleForm.controls.startTime.setValue(this.startTime);
        this.scheduleForm.controls.endTime.setValue(this.endTime);
        this.startTime.setTime(this.startTime);
        this.endTime.setTime(this.endTime);
        this.startTime = this.startTime;
        this.endTime = this.endTime;

        this.checked = !item.isDisabled;
        this.isDisabled = item.isDisabled;

        this.scheduleForm.controls['weekDayId'].setValue(item.weekDayId);
        this.scheduleForm.controls['isDisabled'].setValue(item.isDisabled);
        this.scheduleForm.controls['isDefault'].setValue(!item.isDefault);
      }
    });
  }

  onChangeStartTime(value) {
    this.scheduleForm.controls.startTime.setValue(this.startTime);
    this.startTime = value;
  }

  onChangeEndTime(value) {
    this.scheduleForm.controls.endTime.setValue(this.endTime);
    this.endTime = value;
  }

  patchValues(id, name) {
    return this.fb.group({
      id: [id],
      name: [name],
    });
  }

  translate = (key: string) => <string>{};

  onActive(isActive) {
    this.isDisabled = !isActive['checked'];
    this.checked = isActive['checked'];
    this.scheduleForm.controls['isDisabled'].setValue(!isActive['checked']);
  }
}
