import { Injectable } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { loadDailyStartStopTimeForCompany } from '../actions/tenants.actions';
import {
  loadDailyStartStopTimeForCompanyFailed,
  loadDailyStartStopTimeForCompanySuccess,
} from '../actions/tenants-api.actions';
import { TenantService } from '@cci/core/services/tenant-service';

@Injectable()
export class TenantsEffects {
  loadDailyStartStopTimeForCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDailyStartStopTimeForCompany),
      switchMap((params) => {
        return this.service
          .getDailyStartStopTimeForCompany(params.tenantId)
          .pipe(
            map((response) =>
              loadDailyStartStopTimeForCompanySuccess({
                dailyStartStopTimeForCompany: response,
              })
            ),
            catchError((errors) =>
              of(loadDailyStartStopTimeForCompanyFailed({ errors: errors }))
            )
          );
      })
    )
  );

  constructor(private actions$: Actions, private service: TenantService) {}
}
