import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';



@Component({
    selector: 'app-auth-callback',
    templateUrl: './auth-callback.component.html',
    styleUrls: ['./auth-callback.component.scss']
})
export class AuthenticationCallbackComponent {

}

