import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

import * as fromRoot from '../../store';
import { DataService } from './data.service';
import { AuthenticationService } from './authentication.service';

import * as fromAuth from '@cci/auth/store';
import { takeUntil } from 'rxjs/operators';
import {
  CreateOfp,
  OrderFulfillmentProfileForCreate,
  OrderFulfillmentProfile,
  AuthorizedUser,
  GridQueryOptions,
  OfpResults,
  UpdateOfp
} from '@cci/shared/models';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class OfpService implements OnDestroy {
  private apiUrl: string | null;
  private cciApiUrl: string | null;
  private subscription;
  private authorizedUser: AuthorizedUser;
  private partnerId: number;
  private unSubscribe: Subject<void> = new Subject();

  constructor(
    private dataService: DataService,
    private authenticationService: AuthenticationService,
    private store: Store<fromRoot.State>
  ) {
    this.init();
  }

  // GET OFP
  public getOrderFullfillmentProfiles(
    tenantId: number,
    page: GridQueryOptions
  ): Observable<OfpResults> {
    let url = this.getApisUrl(`${tenantId}/ofp/get-order-fulfillment-profile-pricing`);
    let params = new HttpParams();
    if(page) {
      page.pageNumber ? params = params.append('pageNumber', page.pageNumber.toString()): '';
      page.pageSize ? params = params.append('pageSize', page.pageSize.toString()) : '';
      page.sortField ? params = params.append('orderBy', page.sortField) : '';
      page.sortOrder ? params = params.append('sortOrder', page.sortOrder) : '';
  
      if (page.filters) {
        Array.from(page.filters).map(item => {
          const index = page.filters.indexOf(item);
          Array.from(Object.keys(item)).map(key => {
            const valueId = Object.keys(item).indexOf(key);
            params = params.append(`Filters[${index}].${key}`, Object.values(item)[valueId]);
          });
        });
      }
    }

    return this.dataService.get<OfpResults>(url, { params: params });
  }

  // CREATE OFP
  public createOfps(tenantId: number, ofp: CreateOfp): Observable<CreateOfp> {
    let url = this.getApisUrl(`${tenantId}/ofp/create-order-fulfillment-profile`);
    return this.dataService.post<CreateOfp>(url, ofp, {
      headers: { 'content-type': 'application/json' },
    });
  }

  // UPDATE OFP
  public upsertOfp(tenantId: number, ofp: UpdateOfp): Observable<CreateOfp> {
    let url = this.getApisUrl(`${tenantId}/ofp/upsert-order-fulfillment-profile`);
    return this.dataService.put<CreateOfp>(url, ofp, {
      headers: { 'content-type': 'application/json' },
    });
  }

  // CREATE DEFAULT OFP - PARTNER SETUP WIZARD
  public createDefaultOfp(
    tenantId: number,
    ofp: number
  ): Observable<OrderFulfillmentProfile> {
    let url = this.getApisUrl();
    url = `${url}/${tenantId}/ofp/default/price/${ofp}`;

    return this.dataService.put<OrderFulfillmentProfile>(url, {
      headers: { 'content-type': 'application/json' },
    });
  }

  // UPDATE OFP PRICE
  public updateOfpPrice(tenantId: number, ospId: number, price: number): Observable<CreateOfp> {
    let url = this.getApisUrl(`${tenantId}/ofp/${ospId}/update-order-fulfillment-profile-price/${price}`);
    return this.dataService.put<CreateOfp>(url, {
      headers: { 'content-type': 'application/json' },
    });
  }

  // DELETE OFP
  public DeleteOfp(
    tenantId: number,
    profileId: number
  ): Observable<boolean> {
    let url = this.getApisUrl(`${tenantId}/ofp/${profileId}/delete-order-fullfillment-profile`);

    return this.dataService.delete<boolean>(url);
  }

  private getApisUrl(action: string = null) {
    if (action != null) {
      return `${this.apiUrl}${this.apiPath}/${action}`;
    }
    return this.apiUrl + this.apiPath;
  }

  private get apiPath() {
    const apiPath = '/marketplace/api/tenant';
    return apiPath;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  init(): void {
    this.subscription = this.store
      .select(fromRoot.getApplicationSettings)
      .subscribe((response) => {
        if (response) {
          this.apiUrl = response.apiUrl;
          console.log(this.apiUrl);
        }
      });

    this.store
      .pipe(
        select(fromAuth.getAuthenticatedUserSelector),
        takeUntil(this.unSubscribe)
      )
      .subscribe((user: AuthorizedUser) => {
        this.authorizedUser = user;
      });
    if (this.authorizedUser && this.authorizedUser.authInfo) {
      this.partnerId = this.authorizedUser.authInfo.partnerInfo.partnerId;
    }
  }
}
