import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class AuthorizedRequestInterceptor implements HttpInterceptor {
  constructor(private auth: AuthenticationService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.auth.isAuthorized2) {
      request = request.clone({
        headers: request.headers.set(
          'Authorization',
          this.auth.getAuthorizationHeaderValue()
        )
      });

      request = request.clone({
        headers: request.headers.set('X-User-Sub', this.auth.getSub())
      });

      /*  request = request.clone({
        headers: request.headers.set('X-Tenant', this.auth.getTenant())
      }); */
    }
    return next.handle(request);
  }
}
