import { createSelector } from '@ngrx/store';

import * as fromFeature from '@cci/store/reducers';
import * as fromTenants from '@cci/store/reducers/tenants.reducer';

export const selectTenantsState = createSelector(
  fromFeature.getTenantsState,
  (state: fromTenants.TenantState) => state
);

export const getDailyStartStopTimeForCompanyLoaded = createSelector(
  selectTenantsState,
  fromTenants.getDailyStartStopTimeForCompanyLoaded
);

export const getDailyStartStopTimeForCompany = createSelector(
  selectTenantsState,
  fromTenants.getDailyStartStopTimeForCompany
);
