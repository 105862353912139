
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  LogPublisher,
  LogConsole,
  LogLocalStorage,
  LogWebApi,
  LogPublisherConfig
} from './log-publishers';
import { HttpClient, HttpResponse } from '@angular/common/http';

const PUBLISHERS_FILE = 'assets/log-publishers.json';

@Injectable()
export class LogPublishersService {
  constructor(private http: HttpClient) {
    this.buildPublishers();
  }

  publishers: LogPublisher[] = [];

  buildPublishers(): void {
    // tslint:disable-next-line:prefer-const
    let logPub: LogPublisher;

    /* this.getLoggers().subscribe(response => {
      for (const pub of response.filter(p => p.isActive)) {
        switch (pub.loggerName.toLowerCase()) {
          case 'console':
            logPub = new LogConsole();
            break;
          case 'localstorage':
            logPub = new LogLocalStorage();
            break;
          case 'webapi':
            logPub = new LogWebApi(this.http);
            break;
        }

        // Set location, if any, of the logging
        logPub.location = pub.loggerLocation;
        // Add publisher to array
        this.publishers.push(logPub);
      }
    }); */
  }

  getLoggers(): Observable<LogPublisherConfig[]> {
    return this.http
      .get<HttpResponse<LogPublisherConfig[]>>(PUBLISHERS_FILE).pipe(
      map(response => response.body),
      catchError(this.handleErrors),);
  }

  private handleErrors(error: any): Observable<any> {
    const errors: string[] = [];
    let msg = '';

    msg = 'Status: ' + error.status;
    msg += ' - Status Text: ' + error.statusText;
    if (error.json()) {
      msg += ' - Exception Message: ' + error.json().exceptionMessage;
    }

    errors.push(msg);

    console.error('An error occurred', errors);

    return observableThrowError(errors);
  }
}
