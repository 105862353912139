<p-sidebar [(visible)]="content.visible" [dismissible]="false" [blockScroll]="true" [position]="content.position"
  [baseZIndex]="10000" [styleClass]="content.styleClass" [showCloseIcon]="content.showCloseIcon">
  <h1 *ngIf="content.isHeaderText" class="sidebarHeader" style="color:#150734">{{content.headerText}}</h1>
  <h3 class="sidebar-content" style="color:#150734" [innerHTML]='content.content'></h3>
  <button *ngIf="!content.isInfo && content.buttonOneVisible" pButton type="button"
    (click)="handleButtonOneClick($event)" [label]="content.buttonOneLabel" [disabled]="content.buttonOneDisable"
    [className]="content.name == 
    'invitedPartnerSidebarConfirmationName || masterScheduleWarningSidebarConfirmationName' ? 
    'btn default-enable warning sidebar-button': 'btn default-enable success sidebar-button'"
    ></button>
  <button *ngIf="!content.isInfo && content.buttonTwoVisible" pButton type="button"
    (click)="handleButtonTwoClick($event)" [label]="content.buttonTwoLabel"
    class="btn default-enable secondary sidebar-button" [disabled]="content.buttonTwoDisable"></button>
  <button *ngIf="content.isInfo && content.buttonThreeVisible" pButton type="button"
    (click)="handleButtonThreeClick($event)" [label]="content.buttonThreeLabel" class="btn default-enable secondary"
    [disabled]="content.buttonThreeDisable"></button>
  <button *ngIf="!content.isInfo && content.buttonThreeVisible" pButton type="button"
    (click)="handleButtonThreeClick($event)" [label]="content.buttonThreeLabel" class="btn default-enable success"
    [disabled]="content.buttonThreeDisable"></button>
</p-sidebar>