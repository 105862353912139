<input
  type="text"
  class="form-control"
  [id]="itemId"
  [placeholder]="placeholderLabel"
  bsDatepicker
  [class.is-invalid]="invalidClassNameActive"
  #dpYMD="bsDatepicker"
  [bsValue]="value"
  [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }"
  (bsValueChange)="changed($event)"
/>
<ng-content select="div"></ng-content>
