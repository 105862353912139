import { createReducer, on } from '@ngrx/store';

import { changePassword } from '@cci-partner/store/actions/change-password.actions';
import {
  changePasswordSuccess,
  changePasswordFailed
} from '@cci-partner/store/actions/change-password-api.actions';

export interface ChangePasswordState {
  pending: boolean;
  successful: boolean;
  errors: any;
}

export const initialState: ChangePasswordState = {
  pending: false,
  successful: false,
  errors: null
};

export const reducer = createReducer(
  initialState,
  on(changePassword, state => ({
    ...state,
    pending: true
  })),
  on(changePasswordSuccess, state => ({
    ...state,
    pending: false,
    successful: true
  })),
  on(changePasswordFailed, (state, { errors }) => ({
    ...state,
    pending: false,
    errors: errors
  }))
);

export const getChangePasswordPending = (state: ChangePasswordState) =>
  state.pending;
export const getChangePasswordSuccessful = (state: ChangePasswordState) =>
  state.successful;
export const getChangePasswordErrors = (state: ChangePasswordState) => {
  return state.errors;
}
