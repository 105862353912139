import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';

import { go } from '../actions/router.action';
import {
  loadUserSetupStepsFailed,
  loadUserSetupStepsSuccess,
} from '../actions/user-setup-steps-api.actions';
import {
  loadUserSetupSteps,
  partnerRegistrationRedirect,
  researcherRegistrationRedirect,
} from '../actions/user-setup-steps.actions';

import { UserTodosService } from '@cci/core/services/user-todos.service';
import { Urls } from '@cci/shared/constants/url-constants';

@Injectable()
export class UserSetupStepsEffects {
  loadConstants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserSetupSteps),
      switchMap(() => {
        return this.service.loadTodos().pipe(
          mergeMap((response) => [
            loadUserSetupStepsSuccess({ payload: response }),
          ]),
          catchError((errors) =>
            of(loadUserSetupStepsFailed({ errors: errors }))
          )
        );
      })
    )
  );

  researcherRegistrationRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(researcherRegistrationRedirect),
      map(() => {
        return go({
          path: [Urls.ResearcherRegistration],
        });
      })
    )
  );

  partnerRegistrationRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(partnerRegistrationRedirect),
      map(() => {
        return go({
          path: [Urls.PartnerRegistration],
        });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private service: UserTodosService,
    private router: Router
  ) {}
}
