import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';



@Component({
    selector: 'app-log-out-callback',
    templateUrl: './logout-callback.component.html',
})
export class LogOutCallbackComponent {

}

