import { createReducer, on } from '@ngrx/store';

import { WorkDay } from '@cci/shared/models';
import { loadDailyStartStopTimeForCompany } from '../actions/tenants.actions';
import { loadDailyStartStopTimeForCompanyFailed, loadDailyStartStopTimeForCompanySuccess } from '../actions/tenants-api.actions';

export const collectionFeatureKey = 'tenant';

export interface TenantState {
  loaded: boolean;
  pending: boolean;
  dailyStartStopTimeForCompany: WorkDay[];
}

export const initialState: TenantState = {
  loaded: false,
  pending: false,
  dailyStartStopTimeForCompany: null
};

export const reducer = createReducer(
  initialState,
  on(loadDailyStartStopTimeForCompany, state => ({
    ...state,
    pending: true
  })),
  on(loadDailyStartStopTimeForCompanySuccess, (state, { dailyStartStopTimeForCompany }) => ({
    ...state,
    pending: false,
    dailyStartStopTimeForCompany: dailyStartStopTimeForCompany,
    loaded: true
  })),
  on(loadDailyStartStopTimeForCompanyFailed, (state, {}) => ({
    ...state,
    pending: false,
    loaded: false
  }))
);

export const getDailyStartStopTimeForCompany = (state: TenantState) => {
  return state.dailyStartStopTimeForCompany;
}
export const getDailyStartStopTimeForCompanyPending = (state: TenantState) => state.pending;
export const getDailyStartStopTimeForCompanyLoaded = (state: TenantState) => {
  return state.loaded;
}
