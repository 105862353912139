import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { tap, map, filter, take, switchMap } from 'rxjs/operators';

import * as fromRoot from '../../store';
import * as fromAuthentication from '../../auth/store';
import { AuthenticationService } from '../services/authentication.service';
import { userAuthenticated } from '../../auth/store';

@Injectable()
export class HasSessionGuard implements CanActivate {
  constructor(
    private store: Store<fromRoot.State>,
    private authService: AuthenticationService
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.checkStore().pipe(
      map((hasSession) => {
        if (!hasSession) {
          return true;
        }
        return false;
      })
    );
  }

  checkStore(): Observable<boolean> {
    return this.authService.isAuthorized().pipe(
      map((isAuthorized) => {
        if (isAuthorized) {
          const user = this.authService.getAuthorizedUser();
          this.store.dispatch(userAuthenticated({ payload: user }));
          return true;
        } else {
          return false;
        }
      }),
      take(1)
    );
  }
}
