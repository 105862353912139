import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  loadUserProfile
} from '../actions/profile.actions';
import {
  loadUserProfileSuccess,
  loadUserProfileFailed
} from '../actions/profile-api.actions';

import { go } from '@cci/store/actions/router.action';
import { Urls } from '@cci/shared';

import { UserService } from '@cci/core/services/user-service';

@Injectable()
export class UserProfileEffects {
  loadUserinformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserProfile),
      mergeMap(() => {
        return this.userService.getUserDetails().pipe(
          map((userProfile) =>
            loadUserProfileSuccess({ userInformation: userProfile })
          ),
          catchError((error) => of(loadUserProfileFailed(error)))
        );
    })
  ));

  constructor(private actions$: Actions, private userService: UserService) {}
}
