import { throwError as observableThrowError, Observable, from, of } from 'rxjs';

import { catchError, map, filter } from 'rxjs/operators';
import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromRoot from '../../store/reducers';
import {
  UserManager,
  UserManagerSettings,
  User,
  Log,
  WebStorageStateStore,
} from 'oidc-client';
import { getApplicationSettings } from '../../store/selectors/app-settings.selector';
import { IApplicationSettings } from '../../shared/models/application-settings.model';
import { completeAuthenticationProcedure } from '@cci/auth/store/actions/authentication-api.actions';
import { debug } from 'console';

@Directive()
@Injectable()
export class AuthenticationService {
  private manager: UserManager;
  private user: User;

  @Output() onAccessTokenExpiring = new EventEmitter();

  constructor(private store: Store<fromRoot.State>) {
    Log.logger = console;
    // Log.level = Log.DEBUG;

    this.store
      .select(getApplicationSettings)
      .pipe(filter((data) => data !== null))
      .subscribe((settings: IApplicationSettings) => {
        if (settings !== null) {
          this.manager = new UserManager(getClientSettings(settings));
          this.registerEvents();
        }
      });
  }

  startAuthentication(): Observable<any> {
    return from(this.manager.signinRedirect());
  }

  completeAuthentication(): Observable<User> {
    return from(this.manager.signinRedirectCallback()).pipe(
        map((user) => {
        this.user = user;
        this.registerEvents();
        return user;
      })
    );
  }

  completeAuthentication2(): Promise<void> {
    return this.manager.signinRedirectCallback().then((user) => {
      this.user = user;
      this.registerEvents();
    });
  }

  getUser(): Observable<User> {
    return from(this.manager.getUser()).pipe(
      map<User, User>((user) => {
        this.user = user;
        return user;
      })
    );
  }

  getAuthorizedUser(): User {
    return this.user;
  }

  /**
   *
   *
   * @returns {Observable<boolean>}
   * @memberof AuthService
   */
  isAuthorized(): Observable<boolean> {
    return of(this.user != null && !this.user.expired);
  }

  /**
   *
   *
   * @returns {boolean}
   * @memberof AuthService
   */
  get isAuthorized2(): boolean {
    return this.user != null && !this.user.expired;
  }

  /**
   *
   *
   * @returns {string}
   * @memberof AuthService
   */
  getAuthorizationHeaderValue(): string {
    return `${this.user.token_type} ${this.user.access_token}`;
  }

  startSignoutMainWindow(): Observable<any> {
      if (this.user && this.user.id_token) {
      return from(
        this.manager.signoutRedirect({ id_token_hint: this.user.id_token })
      ).pipe(
        map<any, any>((response) => {
          console.log('signed out started: ', response);
          return response;
        })
      );
    } else {
      return from(this.manager.signoutRedirect()).pipe(
        map<any, any>((response) => {
          console.log('signed out started: ', response);
          return response;
        })
      );
    }
  }

  endSignoutMainWindow(): Observable<any> {
    return from(this.manager.signoutRedirectCallback()).pipe(
      map<any, any>((response) => {
        console.log('signed out completed:', response);
        return response;
      })
    );
  }

    renewUserSession(): Observable<number> {
    return from(this.manager.signinSilent()).pipe(
      map((user) => {
        this.user = user;
        return user.expires_in;
      })
    );
  }

  private registerEvents() {
    this.manager.events.removeSilentRenewError(() => {});
    this.manager.events.removeUserSignedOut(() => {});
    this.manager.events.removeUserSignedOut(() => {});
    this.manager.events.removeAccessTokenExpired(() => {});
    this.manager.events.removeAccessTokenExpiring(() => {});

      this.manager.events.addSilentRenewError((param) => {
          debugger;
      this.handleLogOut();
    });

    this.manager.events.addUserSignedOut(() => {
        debugger;
        this.handleLogOut();
    });

    this.manager.events.addAccessTokenExpired(() => {
        debugger;
        this.handleLogOut();
    });

    this.manager.events.addAccessTokenExpiring(() => {
        debugger;
        this.handleAccessTokenExipring();
    });
  }

  getAccessTokenExpiringEmitter(): EventEmitter<any> {
    return this.onAccessTokenExpiring;
  }

  private handleAccessTokenExipring() {
    // this.onAccessTokenExpiring.emit(null);
  }

    private handleLogOut() {
        debugger;
    this.store.dispatch(completeAuthenticationProcedure());
  }

  getToken(): string {
    if (this.user) {
      return this.user.access_token;
    }
    return '';
  }

  getTenant(): string {
    if (this.user) {
      return this.user.profile.tenant_id;
    }
    return '';
  }

    getSub(): string {
    if (this.user) {
      return this.user.profile.sub;
    }
    return '';
  }

  getExpiresIn(): number {
    if (this.user) {
      return this.user.expires_in;
    }
    return -1;
  }

  clearState() {
    return from(this.manager.clearStaleState()).pipe(
      map(() => {
        console.log('Clear state state completed.');
      }),
      catchError(this.handleError)
    );
  }

  removeUser() {
    return from(this.manager.removeUser()).pipe(
      map(() => {
        console.log('removeUser success.');
      }),
      catchError(this.handleError)
    );
  }

  revokeAccessToken() {
    return from(this.manager.revokeAccessToken()).pipe(
      map(() => {
        console.log('revokeAccessToken success');
      }),
      catchError(this.handleError)
    );
  }

  private handleError(error: any) {
    console.error('server error:', error);
    if (error instanceof Response) {
      let errMessage = '';
      try {
        // errMessage = error.json();
      } catch (err) {
        errMessage = error.statusText;
      }
      return observableThrowError(errMessage);
    }
    return observableThrowError(error || 'server error');
  }
}

export function getClientSettings(
  settings: IApplicationSettings
): UserManagerSettings {
  return {
    authority: settings.authority, // environment.identityServer,
    client_id: settings.clientId,
    redirect_uri: settings.redirectUri, // environment.webServer + '/auth.html',
    post_logout_redirect_uri: settings.postLogoutRedirectUri, // environment.webServer + '/index.html',

    // if we choose to use popup window instead for logins
    /* popup_redirect_uri: window.location.protocol + "//" + window.location.host + "/popup.html",
         popupWindowFeatures: "menubar=yes,location=yes,toolbar=yes,width=1200,height=800,left=100,top=100;resizable=yes",*/

    // these two will be done dynamically from the buttons clicked, but are
    // needed if you want to use the silent_renew
    response_type: settings.responseType,
    scope: settings.scope,

    // silent renew will get a new access_token via an iframe
    // just prior to the old access_token expiring (60 seconds prior)
    silent_redirect_uri: settings.silentRedirectUri,
    automaticSilentRenew: settings.automaticSilentRenew,

    // will revoke (reference) access tokens at logout time
    revokeAccessTokenOnSignout: settings.revokeAccessTokenOnSignout,

    // this will allow all the OIDC protocol claims to be visible in the window. normally a client app
    // wouldn't care about them or want them taking up space
    filterProtocolClaims: false,

    // this will toggle if profile endpoint is used
    loadUserInfo: settings.loadUserInfo,
    // nonce: 'N' + Math.random() + '' + Date.now(),
    // state: Date.now() + '' + Math.random()

      acr_values: `tenant:${settings.tenant}`,

      monitorSession: false,

    userStore: new WebStorageStateStore({ store: window.localStorage }),
  };
}
