import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { DataService } from './data.service';
import * as fromRoot from '../../store/reducers';
import { UserSetUpStep } from '../../shared/models/user-setup-step';
import { HttpResponse } from '@angular/common/http';
import { TodosByUser } from '../../shared/models/todos-by-user';
import { getApplicationSettings } from '../../store/selectors/app-settings.selector';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class UserTodosService implements OnDestroy {
  private userApiUrl: string | null;
  private subscription;

  constructor(
    private dataService: DataService,
    private authenticationService: AuthenticationService,
    private store: Store<fromRoot.State>
  ) {
    this.init();
  }

  public loadTodos(): Observable<TodosByUser> {
    const userId = this.authenticationService.getSub();

    let url = this.getApisUrl('getUserTodos');
    url = `${url}/user/${userId}`;

    return this.dataService.get<TodosByUser>(url);
  }

  public updateTodo(
    id: number,
    todo: UserSetUpStep
  ): Observable<HttpResponse<UserSetUpStep>> {
    let url = this.getApisUrl('updateUserTodoItem');
    const userId = this.authenticationService.getSub();
    url = `${url}/user/${userId}/todo/${id}`;
    return this.dataService.put(url, todo);
  }

  private getApisUrl(action: string = null) {
    if (action != null) {
      return `${this.userApiUrl}${this.apiPath}/${action}`;
    }
    return this.userApiUrl + this.apiPath;
  }

  get apiOldPath() {
    const apiPath = '/marketplace/api/v1/Todo';
    return apiPath;
  }

  get apiPath() {
    const apiPath = '/marketplace/api/Todo';
    return apiPath;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  init(): void {
    this.subscription = this.store
      .select(getApplicationSettings)
      .subscribe((response) => {
        if (response) {
          this.userApiUrl = response.apiUrl;
          console.log(this.userApiUrl);
        }
      });
  }
}
