import { Component, OnInit, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { AppTranslationService } from '@cci/core/services';

import { WeekDay, WorkDay } from '@cci/shared/models';
import { MasterTimeScheduleComponent } from '../master-time-schedule/master-time-schedule.component';

@Component({
  selector: 'app-master-schedule-table',
  templateUrl: './master-schedule-table.component.html',
  styleUrls: ['./master-schedule-table.component.scss']
})
export class MasterScheduleTableComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() masterSchedule: FormArray;
  @Input() formArray: FormArray;
  @Input() weekDays: WeekDay[];
  @Input() dailyStartStopTimeForCompany: WorkDay[];
  @Input()
  masterScheduleInfoSidebarConfirmationName;
  @Input() isMasterSchedule: boolean;
  @Input() schedule: string;
  @Input() showActive: boolean;
  @Input() showError: boolean;
  @Input() priceDisabled: boolean;
  @Input() isResearcherPayPerOrder: boolean;
  @Input() isPartner: boolean;
  @Input() maxOrders: FormControl;
  @ViewChild(MasterTimeScheduleComponent)
  masterTimeSchedule: MasterTimeScheduleComponent;
  isMarketPlaceModule = false;
  isAffiliateModule = false;
  minStartTime = new Date();
  maxEndTime = new Date();
  ordersControl: number;
  startTime = new Date();
  endTime = new Date();

  constructor(
    private translationService: AppTranslationService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.translate = (key: string) =>
      this.translationService.getTranslation(key);

    this.masterSchedule.controls = [];
    
    this.addmasterSchedule();
  }

  onKeyPress(event) {
    return event.charCode>=48&&event.charCode<=57
  }

  onResetDefault() {
    this.masterSchedule.controls = [];
    this.addmasterSchedule();
  }

  get isPristinedScheduleForm(): boolean {
    let dirty: boolean;
    for(let i in this.masterSchedule.value) {
      
      const item = this.dailyStartStopTimeForCompany[i];
      const startTime = item['startTime'];
      const endTime = item['endTime'];

      this.startTime = new Date(startTime);
      this.endTime = new Date(endTime);
      
      if((!!this.masterSchedule.value[i]['startTime'] && this.masterSchedule.value[i]['startTime'].toString() != this.startTime.toString()) || 
      (!!this.masterSchedule.value[i]['endTime'] && this.masterSchedule.value[i]['endTime'].toString() != this.endTime.toString()) || 
      (!this.isResearcherPayPerOrder && !this.masterSchedule.value[i]['active'])) {
        dirty = true;
        break;
      } else {
        dirty = false;
      }
    }
    return dirty;
  }

  translate = (key: string) => <string>{};

  public static defaultMasterSchedule(): WorkDay {
    const minStartTime = new Date();
    const maxEndTime = new Date();
    const masterSchedule: WorkDay = {
      weekDayId: null,
      weekDays: [],
      startTime: minStartTime,
      endTime: maxEndTime,
      isDefault: true,
      isDisabled: false
    };

    return masterSchedule;
  }

  public static createMasterSchedules(
    fb: FormBuilder,
    masterSchedule: WorkDay
  ): FormGroup {
    return fb.group({
      weekDayId: [null],
      weekDays: new FormArray([]),
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
      isDefault: [],
      isDisabled: []
    });
  }

  addmasterSchedule() {
    const uniqueArr = [...new Map(this.dailyStartStopTimeForCompany.map(item => [item['weekDayId'], item])).values()]
    
    if (this.weekDays.length > 0) {
      for (let i = 0; i < uniqueArr.length; i++) {
        this.masterSchedule.push(
          MasterScheduleTableComponent.createMasterSchedules(
            this.fb,
            MasterScheduleTableComponent.defaultMasterSchedule()
          )
        );
      }
      this.cd.detectChanges();
      return false;
    }
  }
}
