import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { DashboardInfo } from '@cci/shared/models';
import { SelectItem } from 'primeng/api';
@Component({
  selector: 'app-information-view',
  templateUrl: './information-view.component.html',
  styleUrls: ['./information-view.component.scss']
})
export class InformationViewComponent implements OnInit {
  @Input() areaInfo: DashboardInfo[];
  @Input() courtHouses: SelectItem[];
  @Input() tabHeaderOne: string;
  @Input() tabHeaderTwo: string;
  @Output() onReportAnIssue = new EventEmitter();

  ngOnInit() {
  }

  reportAnIssue(event) {
    this.onReportAnIssue.emit();
  }
}
