import { Action, createAction, props, union } from '@ngrx/store';
import { NavigationExtras } from '@angular/router';
/* 
export const GO = '[Router] Go';
export const BACK = '[Router] Back';
export const FORWARD = '[Router] Forward';
export const ROUTER_NAVIGATION_TYPE = '[Router] Navigation';

export class Go implements Action {
  readonly type = GO;
  constructor(
    public payload: {
      path: any[];
      query?: object;
      extras?: NavigationExtras;
    }
  ) {}
}

export class Back implements Action {
  readonly type = BACK;
}

export class Forward implements Action {
  readonly type = FORWARD;
}

export class RouteNavigation implements Action {
  readonly type = ROUTER_NAVIGATION_TYPE;
  constructor(
    public payload: {
      path: string;
      params?: any;
      queryParams?: any;
      data?: any;
    }
  ) {}
} */

// export type Actions = Go | Back | Forward | RouteNavigation;

export const go = createAction(
  '[Router] Go',
  props<{ path: any[]; query?: any; extras?: NavigationExtras }>()
);

export const back = createAction('[Router] Back');

export const forward = createAction('[Router] Forward');

export const routeNavigation = createAction(
  '[Router] Navigation',
  props<{ path: string; params?: any; queryParams?: any; data?: any }>()
);
