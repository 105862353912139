
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { IApplicationSettings } from '../../shared/models/application-settings.model';
import { Observable ,  Subject } from 'rxjs';


@Injectable()
export class SettingsService {
  serverSettings: IApplicationSettings;
  // observable that is fired when settings are loaded from server
  private settingsLoadedSource = new Subject();
  settingsLoaded$ = this.settingsLoadedSource.asObservable();
  isReady = false;

  constructor(private httpClient: HttpClient) {}

  load(): Observable<IApplicationSettings> {
    const baseURI = document.baseURI.endsWith('/')
      ? document.baseURI
      : `${document.baseURI}/`;
    const url = `${baseURI}Home/Settings`;
    return this.httpClient.get<IApplicationSettings>(url);
  }

  loadPromise(): Promise<IApplicationSettings> {
    const baseURI = document.baseURI.endsWith('/')
      ? document.baseURI
      : `${document.baseURI}/`;
    const url = `${baseURI}Home/Settings`;
    return this.httpClient
      .get(url).pipe(
      map((res: HttpResponse<IApplicationSettings>) => res.body))
      .toPromise();
  }
}
