import { UserSetUpStep } from '@cci/shared/models';
import { TodoType } from '@cci/shared/models/todo-type';
import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromUserSetupSteps from '../reducers/user-setup-steps.reducer';
import * as fromAuth from '../../auth/store/selectors';

export const selectSetupSetState = createSelector(
  fromFeature.getSetupStepsState,
  (state: fromUserSetupSteps.UserTodoState) => state
);

export const selectSetupSteps = createSelector(
  selectSetupSetState,
  fromUserSetupSteps.getUserSetupSteps
);

export const selectUserSetupStepsLoaded = createSelector(
  selectSetupSetState,
  fromUserSetupSteps.getUserSetupStepsLoaded
);

export const selectUserSetupStepsLoading = createSelector(
  selectSetupSetState,
  fromUserSetupSteps.getUserSetupStepsPending
);

export const selectResearcherAccountActivated = createSelector(
  selectSetupSteps,
  (todos: UserSetUpStep[]) => {
    if (!todos || Object.keys(todos).length === 0) {
      return false;
    }
    const done = todos[TodoType.ResearcherAccountActivated].completed;
    return done;
  }
);

export const selectServiceAreaSetupComplete = createSelector(
  selectSetupSteps,
  (todos: UserSetUpStep[]) => {
    if (!todos || Object.keys(todos).length === 0) {
      return false;
    }
    const done = todos[TodoType.SetupServiceArea].completed;
    return done;
  }
);

export const selectMasterScheduleSetupComplete = createSelector(
  selectSetupSteps,
  (todos: UserSetUpStep[]) => {
    if (!todos || Object.keys(todos).length === 0) {
      return false;
    }
    const done = todos[TodoType.SetupMasterSchedule].completed;
    return done;
  }
);

export const selectUserInformationSetupCompete = createSelector(
  selectSetupSteps,
  (todos: UserSetUpStep[]) => {
    if (!todos || Object.keys(todos).length === 0) {
      return false;
    }
    const done = todos[TodoType.SetupUserInformation].completed;
    return done;
  }
);

export const selectPartnerCompanyInformationSetupCompete = createSelector(
  selectSetupSteps,
  (todos: UserSetUpStep[]) => {
    if (!todos || Object.keys(todos).length === 0) {
      return false;
    }
    const done = todos[TodoType.SetupCompanyInformation].completed;
    return done;
  }
);

export const selectPartnerSelectModuleCompete = createSelector(
  selectSetupSteps,
  (todos: UserSetUpStep[]) => {
    if (!todos || Object.keys(todos).length === 0) {
      return false;
    }
    const done = todos[TodoType.SelectModule].completed;
    return done;
  }
);

export const selectPartnerMarketPlaceModuleSetupCompete = createSelector(
  selectSetupSteps,
  (todos: UserSetUpStep[]) => {
    if (!todos || Object.keys(todos).length === 0) {
      return false;
    }
    const done = todos[TodoType.SetUpMarketPlaceModule].completed;
    return done;
  }
);

export const selectPartnerAffiliateModuleSetupCompete = createSelector(
  selectSetupSteps,
  (todos: UserSetUpStep[]) => {
    if (!todos || Object.keys(todos).length === 0) {
      return false;
    }
    const done = todos[TodoType.SetUpAffiliateModule].completed;
    return done;
  }
);

export const selectIndexOfPartnerSetup = createSelector(
  selectSetupSteps,
  (todos: UserSetUpStep[]) => {
    let index = 0;

    if (!todos || Object.keys(todos).length === 0) {
      return false;
    }
    let done = todos[TodoType.SetupUserInformation].completed;
    if (done) {
      index = 1;
    }

    done = todos[TodoType.SetupCompanyInformation].completed;
    if (done) {
      index = 2;
    }

    return done;
  }
);

export const selectUserSetupSteps = createSelector(
  selectSetupSteps,
  (todos: UserSetUpStep[]) => {
    if (!todos || Object.keys(todos).length === 0) {
      return null;
    }
    return todos;
  }
);

export const selectPartnerRequiredTodosComplete = createSelector(
  selectSetupSteps,
  (todos: UserSetUpStep[]) => {
    if (!todos || Object.keys(todos).length === 0) {
      return false;
    }
    const setupCompanyInformationCompleted =
      todos[TodoType.SetupCompanyInformation].completed;
    const setupUserInformation = todos[TodoType.SetupUserInformation].completed;
    return setupCompanyInformationCompleted && setupUserInformation;
  }
);

export const selectAccountActivated = createSelector(
  selectSetupSteps,
  fromAuth.getAuthenticatedUserIsPartner,
  (todos: UserSetUpStep[], isPartner: boolean) => {
    if (!todos || Object.keys(todos).length === 0) {
      return false;
    }

    if (!isPartner) {
      const researcher = todos[TodoType.ResearcherAccountActivated];
      return researcher.completed;
    } else {
      const partner = todos[TodoType.PartnerAccountActivated];
      return partner.completed;
    }
  }
);
