import { LayoutActionTypes, LayoutActions } from '../actions';
import * as menus from '../app-menus';
import { MenuItem } from 'primeng/api';
import { SideBarContent, SideBarClosedResult } from '../../../shared/models';

export interface State {
  menuItems: Array<MenuItem>;
  menuLayoutStatic: boolean;
  mobileMenuActive: boolean;
  sidebarActive: boolean;
  footerActive: boolean;
  menuClickState: boolean;
  menuButtonClickState: boolean;
  reset: boolean;
  activeSubmenuIndex: number;
  submenuHover: boolean;
  activeModuleHeaderText: string;

  openSideBarContent: SideBarContent;
  closedSideBarResult: SideBarClosedResult;
  activeMenu: string;
}

const initialState: State = {
  menuItems: menus.initialMenuItems,
  menuLayoutStatic: true,
  mobileMenuActive: false,
  sidebarActive: false,
  footerActive: false,
  menuClickState: false,
  menuButtonClickState: false,
  reset: false,
  activeSubmenuIndex: null,
  submenuHover: false,
  activeModuleHeaderText: '',
  openSideBarContent: null,
  closedSideBarResult: null,
  activeMenu: null
};

export function reducer(state = initialState, action: LayoutActions): State {
  switch (action.type) {
    case LayoutActionTypes.LOAD_NAVIGATION_MENU_ITEMS: {
      return Object.assign({}, state);
    }
    case LayoutActionTypes.SHOW_APPLICATION_MENU: {
      return Object.assign({}, state, {
        menuLayoutStatic: !state.menuLayoutStatic,
      });
    }
    case LayoutActionTypes.SET_APPLICATION_SIDEBAR_ACTIVE: {
      return Object.assign({}, state, { sidebarActive: action.payload });
    }
    case LayoutActionTypes.SET_APPLICATION_MOBILE_MENU_ACTIVE: {
      return Object.assign({}, state, { mobileMenuActive: action.payload });
    }
    case LayoutActionTypes.SET_APPLICATION_MENU_BUTTON_STATE: {
      return Object.assign({}, state, {
        menuButtonClickedState: action.payload,
      });
    }
    case LayoutActionTypes.SET_APPLICATION_MENU_STATE: {
      return Object.assign({}, state, { menuClickState: action.payload });
    }
    case LayoutActionTypes.SET_APPLICATION_SUBMENU_ACTIVE_INDEX: {
      return Object.assign({}, state, { activeSubmenuIndex: action.payload });
    }
    case LayoutActionTypes.SET_APPLICATION_SUBMENU_HOVER: {
      return Object.assign({}, state, { submenuHover: action.payload });
    }
    case LayoutActionTypes.SET_ACTIVE_MODULE_HEADER_TEXT: {
      return Object.assign({}, state, {
        activeModuleHeaderText: action.payload,
      });
    }
    case LayoutActionTypes.SHOW_FOOTER: {
      return Object.assign({}, state, {
        footerActive: true,
      });
    }
    case LayoutActionTypes.HIDE_FOOTER: {
      return Object.assign({}, state, {
        footerActive: false,
      });
    }

    case LayoutActionTypes.ShowSideBar: {
      const content = action.payload;
      return Object.assign({}, state, {
        openSideBarContent: content,
        closedSideBarResult: null,
      });
    }

    case LayoutActionTypes.HideSideBar: {
      const result = action.payload;
      if(!!result) {
        return Object.assign({}, state, {
          openSideBarContent: null,
          closedSideBarResult: result,
        });
      }      
    }

    case LayoutActionTypes.HideSideBarNotificationRecived: {
      return Object.assign({}, state, {
        closedSideBarResult: null,
      });
    }

    case LayoutActionTypes.NavigationMenuItemsLoaded: {
      const menuItems = action.payload;

      return Object.assign({}, state, {
        menuItems: menuItems,
      });
    }

    case LayoutActionTypes.SetActiveMenu: {
      const result = action.payload;

      return Object.assign({}, state, {
        activeMenu: result
      });
    }
  }

  return state;
}

export const getMainMenuItems = (state: State) => state.menuItems;
export const getMenuLayoutStatic = (state: State) => state.menuLayoutStatic;
export const getMobileMenuActive = (state: State) => state.mobileMenuActive;
export const getApplicationMenuButtonClickedState = (state: State) =>
  state.menuButtonClickState;
export const getApplicationMenuClickState = (state: State) =>
  state.menuClickState;
export const getApplicationSubmenuActiveIndex = (state: State) =>
  state.activeSubmenuIndex;
export const getApplicationSubmenuHover = (state: State) => state.submenuHover;
export const getApplicationSidebarActive = (state: State) =>
  state.sidebarActive;
export const getApplicationFooterActive = (state: State) => state.footerActive;
export const getActiveModuleHeaderText = (state: State) => {
  return state.activeModuleHeaderText;
};

export const getOpenSideBarContent = (state: State) => state.openSideBarContent;
export const getClosedSideBarResult = (state: State) =>
  state.closedSideBarResult;
export const getActiveMenu = (state: State) => state.activeMenu;
