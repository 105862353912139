import { take, takeUntil } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Store } from '@ngrx/store';

import { AuthenticationService } from './authentication.service';
import { NotifyService } from './notify-service';
import * as fromRoot from '../../store/reducers';
import * as authActions from '../../auth/store/actions';
import { getApplicationSettings } from '../../store/selectors/app-settings.selector';
import { signOut } from '../../auth/store/actions';

@Injectable()
export class SessionService implements OnDestroy {
  idleTimeout = 1500;
  timeoutAfterIdle = 300;
  inIdlePeriod = false;
  sessionEndingNotificaionKey = 'sessionEndingNotificaionKeyCCI';
  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private idleSerice: Idle,
    private authenticationService: AuthenticationService,
    private notifyService: NotifyService,
    private store: Store<fromRoot.State>
  ) {
    store
      .select(getApplicationSettings)
      .pipe(take(1))
      .subscribe((response) => {
        if (response) {
          idleSerice.stop();
          const expiresIn = authenticationService.getExpiresIn();
          console.log(
            `Expires in: ${expiresIn}::Idle timeout: ${this.idleTimeout}`
          );

          this.timeoutAfterIdle = response.timeoutAfterIdleTimeout;

          this.setTimeoutPeriods(expiresIn);

          if (this.idleTimeout <= 0 || expiresIn <= 0) {
            this.store.dispatch(signOut());
          }

          this.authenticationService
            .getAccessTokenExpiringEmitter()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
              this.onAccessTokenExpiring();
            });

          let subscription = idleSerice.onIdleEnd
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
              this.userIdlePeriodEnd();
            });
          subscription = idleSerice.onTimeout
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
              this.userSessionTimedOut();
            });

          subscription = idleSerice.onIdleStart
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
              this.userIdlePeriodStart();
            });
          subscription = idleSerice.onTimeoutWarning
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((countdown) => {
              this.sendUserTimeoutWarning(countdown);
            });

          this.reset();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private userIdlePeriodStart(): void {
    this.inIdlePeriod = true;
    console.log('You idle time start::' + Date.now().toString());
  }

  private userIdlePeriodEnd(): void {
    console.log('Renew session Idle period end::' + Date.now().toString());
    this.renewAccessToken();
  }

  private setTimeoutPeriods(expiresIn: number) {
    if (this.timeoutAfterIdle > expiresIn) {
      this.idleTimeout = 1;
      this.timeoutAfterIdle = expiresIn - 1;
    } else {
      this.idleTimeout = expiresIn - this.timeoutAfterIdle;
    }

    // sets an idle timeout of 5 seconds, for testing purposes.
    this.idleSerice.setIdle(this.idleTimeout);

    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idleSerice.setTimeout(this.timeoutAfterIdle);

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idleSerice.setInterrupts(DEFAULT_INTERRUPTSOURCES);
  }

  private userSessionTimedOut(): void {
    console.log('Session ends, time to logout::' + Date.now().toString());

    this.store.dispatch(signOut());
  }

  private sendUserTimeoutWarning(countdown: any): void {
    this.notifyService.showWarnMessage({
      summary: 'Session Ending',
      detail: 'You will time out in ' + countdown + ' seconds!!!',
      id: this.sessionEndingNotificaionKey,
    });
    console.log('You will time out in ' + countdown + ' seconds!!!');
  }

  private onAccessTokenExpiring() {
    console.log('Access token expiring::' + Date.now().toString());

    if (!this.inIdlePeriod) {
      this.renewAccessToken();
    }
  }

  private renewAccessToken() {
    this.authenticationService.renewUserSession().subscribe((expiresIn) => {
      if (expiresIn > 0) {
        this.idleSerice.stop();
        this.setTimeoutPeriods(expiresIn);
        this.reset();
        this.inIdlePeriod = false;
      }
    });
  }

  private reset() {
    this.idleSerice.watch();
  }
}
