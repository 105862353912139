import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { AuthenticatorSetup } from '@cci/shared/models/authenticator-setup';
import { ValidationErrorHandler } from '@cci/shared/utils/validation-error-handler';

@Component({
  selector: 'app-activate-authenticator',
  templateUrl: './activate-authenticator.component.html',
  styleUrls: ['./activate-authenticator.component.scss'],
})
export class ActivateAuthenticatorComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() public authenticatorSetup: AuthenticatorSetup = <
    AuthenticatorSetup
  >{};
  @Input() public generatingQrCode: boolean;
  @Input() public pending: boolean;
  @Input()
  set twoFactorAppCodeVerified(verified: boolean) {
    this.onAppVerified(verified);
  }
  @Input()
  set errors(errors: string[]) {
    this.handleErrors(errors);
  }

  @Output() activateTwoFactor = new EventEmitter<string>();
  @Output() cancel = new EventEmitter();
  @Output() methodActivated = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  get verificationCodeControl(): FormControl {
    return this.form.get('verificationCode') as FormControl;
  }

  get verificationCodeControlInvalid() {
    return (
      this.verificationCodeControl.hasError('required') &&
      this.verificationCodeControl.touched
    );
  }

  get verificationCodeControlIsNotValid() {
    return (
      this.verificationCodeControl.hasError('verificationCodeInvalid') &&
      this.verificationCodeControl.touched
    );
  }

  onActivateTwoFactor(form: FormGroup) {
    const { valid, value } = form;

    if (valid) {
      this.activateTwoFactor.emit(this.verificationCodeControl.value);
    }
  }

  onCancel() {
    this.cancel.emit();
  }

  onAppVerified(verified: boolean) {
    if (verified) {
      this.methodActivated.emit();
    }
  }

  handleErrors(errors: any) {
    if (!this.form || !errors) {
      return;
    }

    ValidationErrorHandler.handleValidationErrors(this.form, errors);
  }
}
