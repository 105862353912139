import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { State, County } from '../../../shared/models';
import { SelectItem } from 'primeng/api';
import { SideBarContent } from '../../models';
import { AppTranslationService } from '@cci/core/services/app-translation.service';

@Component({
  selector: 'app-add-ofp',
  templateUrl: './add-ofp.component.html',
  styleUrls: ['./add-ofp.component.scss'],
})
export class AddOfpComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() countries: SelectItem[];
  @Input() states: State[];
  @Input() counties: County[];
  @Input() areas: SelectItem[] = [];
  @Input() affiliatedInfoSidebarConfirmationName: string;

  @Output()
  showSideBar = new EventEmitter<SideBarContent>();

  headerText: string;
  sideBarTextForHeader: string;
  leftLabel1: string;
  leftLabel2: string;
  leftLabel3: string;
  rightLabel1: string;
  rightLabel2: string;
  placeholder: string;
  sideBarTextForLabel1: string;
  sideBarTextForLabel2: string;
  sideBarTextForLabel3: string;
  styleClassforSideBar: string;

  constructor(private translationService: AppTranslationService) {}

  ngOnInit() {
    this.translate = (key: string) =>
      this.translationService.getTranslation(key);

    this.headerText = this.translate('addOfp.header.text');
    this.sideBarTextForHeader = this.translate(
      'addOfp.sideBarText.sideBarTextForHeader'
    );
    this.sideBarTextForLabel1 = this.translate(
      'addOfp.sideBarText.sideBarTextForLabel1'
    );
    this.sideBarTextForLabel2 = this.translate(
      'addOfp.sideBarText.sideBarTextForLabel2'
    );
    this.sideBarTextForLabel3 = this.translate(
      'addOfp.sideBarText.sideBarTextForLabel3'
    );
    this.leftLabel1 = this.translate('addOfp.leftLabels.serviceArea');
    this.leftLabel2 = this.translate('addOfp.leftLabels.serviceCriteria');
    this.leftLabel3 = this.translate('addOfp.leftLabels.maxServicePrice');
    this.rightLabel1 = this.translate('addOfp.rightLabels.serviceArea');
    this.rightLabel2 = this.translate('addOfp.rightLabels.serviceCriteria');
    this.placeholder = this.translate('addOfp.placeholders.maxServicePrice');
  }

  translate = (key: string) => <string>{};

  onShowOspInformationSideBar(content: SideBarContent) {
    this.showSideBar.emit(content);
  }
}
