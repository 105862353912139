import { Injectable } from '@angular/core';

import { Actions, ofType, Effect, createEffect } from '@ngrx/effects';
import { EMPTY as empty, of, Observable } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  mergeMap
} from 'rxjs/operators';

import {
  loadOrders,
  loadOrdersPageUnion,
  refreshOrders
} from '../actions/orders-collection-page.actions';
import { submitOrderPageUnion } from '../../orders/store/actions/order.actions';
import { NotifyMessage, Pagination } from '@cci/shared/models';
import { AppTranslationService, GrowlNotifyService, OrdersCollectionService, TenantService } from '@cci/core/services';
import { LoadOrdersFailed, LoadOrdersSuccess } from '../actions/orders-collection-api.actions';

@Injectable()
export class OrdersCollectionEffects {

  debounce = 400;
  message = <NotifyMessage>{
    type: 'success',
    key: 'toast',
    life: 5000,
    closable: true,
  };
  errorMessage = <NotifyMessage>{
    type: 'error',
    key: 'toast',
    life: 5000,
    closable: true,
  };
  translate = (key: string) => <string>{};
  
  resendSuccessBody: string;

  loadOrders$ =  createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrders.type, refreshOrders.type),
      map(action => action),
      switchMap(params =>
        this.ordersCollectionService
          .getMarketplaceOrders(params.tenantId, params.queryOptions)
          .pipe(
            map((response) => {
              const pagination: Pagination = JSON.parse(
                response.headers.get('x-pagination')
              );
              return LoadOrdersSuccess({
                orders: response.body,
                pagination: pagination,
              });
            }),
            catchError(error => of(LoadOrdersFailed(error)))
          )
      )
    )
  );

  private setSuccessMessage(summery: string, details: string, ): NotifyMessage {
    this.message.detail = details;
    this.message.summary = summery;
    return this.message;
  }

  private setErrorMessage(summery: string, details: string): NotifyMessage {
    this.errorMessage.detail = details;
    this.errorMessage.summary = summery;
    return this.errorMessage;
  }

  constructor(
    private actions$: Actions<loadOrdersPageUnion | submitOrderPageUnion>,
    private ordersCollectionService: OrdersCollectionService,
    private translationService: AppTranslationService
  ) {
    this.translate = (key: string) =>
    this.translationService.getTranslation(key);
  }
}
