import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromLayout from '../reducers/layout.reducer';

export const selectLayout = createSelector(
  fromFeature.getLayoutState,
  (state: fromLayout.LayoutState) => {
    return state;
  }
);

export const selectExtraSmallScreens = createSelector(
  selectLayout,
  state => state.windowWidth <= 600
);
export const selectSmallScreens = createSelector(
  selectLayout,
  state => state.windowWidth > 600 && state.windowWidth < 768
);
export const selectMediumScreens = createSelector(
  selectLayout,
  state => state.windowWidth > 768 && state.windowWidth < 992
);
export const selectLargeScreens = createSelector(
  selectLayout,
  state => state.windowWidth > 992 && state.windowWidth < 1200
);
export const selectXtraLargeScreens = createSelector(
  selectLayout,
  state => state.windowWidth > 1200
);
export const selectMediumOrBelowScreens = createSelector(
  selectLayout,
  state => {
    return state.windowWidth < 992;
  }
);
