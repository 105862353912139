import { SeoService } from '../services';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  RouterStateSnapshot
} from '@angular/router';
@Injectable()
export class SeoGuard implements CanActivate, CanActivateChild {
  public constructor(private seo: SeoService) {}
  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    this.setSeoData(route);
    return true;
  }

  public canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    this.setSeoData(route);
    return true;
  }

  setSeoData(route: ActivatedRouteSnapshot) {
    this.seo.setTitle([route.data['title']]).setDescription(route.data['desc']);
  }
}
