import {
  Component,
  OnInit,
  Input,
  Output,
  forwardRef,
  HostBinding,
  ViewChild,
  ElementRef,
  HostListener,
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker';

const NG_DATE_PICKER_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NgDatepickerCustomComponent),
  multi: true,
};

@Component({
  selector: 'app-ng-datepicker-custom',
  providers: [NG_DATE_PICKER_ACCESSOR],
  templateUrl: './ng-datepicker-custom.component.html',
  styleUrls: ['./ng-datepicker-custom.component.scss'],
})
export class NgDatepickerCustomComponent
  implements OnInit, ControlValueAccessor
{
  // @ViewChild(BsDatepickerDirective, { static: true })
  @ViewChild(BsDatepickerDirective, { static: true })
  datepicker: BsDatepickerDirective;

  // Allow the input to be disabled, and when it is make it somewhat transparent.
  @Input()
  disabled = false;
  @Input()
  itemId: string;
  @Input()
  placeholderLabel: string;
  @Input()
  invalidClassNameActive: boolean;

  startTime: Date;
  value: string;
  private dateFormat: string = 'yyyy-MM-ddTHH:mm:ss';
  private onTouch: Function;
  private onModelChange: Function;

  writeValue(value: string): void {
    if (!this.disabled) {
      /* this.input.setProperty(value, 'textContent', value);
        this.input.setAttribute('value', value); */
      this.value = value;
    }
  }
  registerOnChange(fn: Function) {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function) {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  changed(e: any) {
    var date = this.datePipe.transform(e, this.dateFormat);
    this.onModelChange(date);
    this.onTouch();
  }

  constructor(private datePipe: DatePipe) {}

  ngOnInit() {}

  @HostListener('window:scroll')
  onScrollEvent() {
    this.datepicker.hide();
  }
}
