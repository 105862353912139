import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';

@Injectable()
export class DataService {
  constructor(private httpClient: HttpClient) {}

  get<T>(url: string, options?: any): Observable<T> {
    return this.doGet<T>(url, options);
  }

  doGet<T>(
    url: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<T> {
    return this.httpClient.get<T>(url, options).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  getWithObserveResponse<T>(
    url: string,
    parameters?: HttpParams
  ): Observable<HttpResponse<T>> {
    const params: HttpParams = parameters;

    return this.httpClient
      .get<T>(url, { params: params, observe: 'response' })
      .pipe(catchError((error: any) => observableThrowError(error)));
  }

  post<T>(url: string, data: any, options?: any): Observable<T> {
    return this.doPost<T>(url, data, options);
  }

  put<T>(url: string, data: any, options?: any): Observable<T> {
    return this.doPut<T>(url, data, options);
  }

  private doPost<T>(
    url: string,
    data: any,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<T> {
    return this.httpClient
      .post<T>(url, data, options)
      .pipe(catchError((error: any) => observableThrowError(error)));
  }

  private doPut<T>(
    url: string,
    data: any,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<T> {
    return this.httpClient.put<T>(url, data, options).pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  patch<T>(url: string, data: any, options?: any): Observable<T> {
    return this.doPatch<T>(url, data, options);
  }

  private doPatch<T>(
    url: string,
    data: any,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<T> {
    return this.httpClient
      .patch<T>(url, data, options)
      .pipe(catchError((error: any) => observableThrowError(error)));
  }

  delete<T>(url: string, options?: any): Observable<T> {
    return this.doDelete<T>(url, options);
  }

  private doDelete<T>(
    url: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<T> {
    console.log('data.service deleting');

    return this.httpClient
      .delete<T>(url, options)
      .pipe(catchError((error: any) => observableThrowError(error)));
  }
}
