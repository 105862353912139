import 'rxjs/Rx';
import { Observable, Observer } from 'rxjs';

import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';

import { DataService } from '../../core/services/data.service';

import * as fromRoot from '../../store/reducers';
import { getApplicationSettings } from '../../store/selectors/app-settings.selector';
import {
  PpoResearcherToBeInvited,
  ResearcherToBeInvited,
} from '@cci/shared/models/ResearcherToBeInvited';
import { InviteSuccessRequest } from '@cci/shared/models';
import { CustomHttpParamEncoder } from '@cci/shared/http/CustomHttpParamEncoder';

@Injectable()
export class InviteResearcherService implements OnDestroy {
  private cciApiUrl: string | null;
  private subscription;
  /**
   *
   */
  constructor(
    private dataService: DataService,
    private store: Store<fromRoot.State>
  ) {
    this.init();
  }

  inviteResearcher(tenantId: number, action: PpoResearcherToBeInvited): Observable<any> {
    const url = this.getApisUrl(`${tenantId}/researcher-invitation/pay-per-order`);

    return this.dataService.post(url, action);
  }

  inviteNppoResearcher(tenantId: number, action: ResearcherToBeInvited): Observable<Boolean> {
    const url = this.getApisUrl(`${tenantId}/researcher-invitation/not-pay-per-order`);

    return this.dataService.post(url, action);
  }

  processInvite(
    inviteRequest: InviteSuccessRequest
  ): Observable<HttpResponse<boolean>> {
    let url = this.getApisUrl('processInvite');
    url = `${url}`;

    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() }).set(
      'inviteId',
      inviteRequest.inviteId.toString()
    );
    params = params.append('password', inviteRequest.password);

    return this.dataService.getWithObserveResponse<boolean>(url, params);
  }

  private getApisUrl(action: string = null) {
    if (action != null) {
      return `${this.cciApiUrl}${this.apiPath}/${action}`;
    }
    return this.cciApiUrl + this.apiPath;
  }

  get apiPath() {
    const apiPath = '/marketplace/api/tenant';
    return apiPath;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  init(): void {
    this.subscription = this.store
      .select(getApplicationSettings)
      .subscribe((response) => {
        if (response) {
          this.cciApiUrl = response.apiUrl;
          console.log(this.cciApiUrl);
        }
      });
  }
}
