import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { NavigationMenuArgs } from '../../core/app/models';

@Injectable()
export class NavigationService {
  // Observable navItem source
  private _menuItemSource = new ReplaySubject<NavigationMenuArgs>();
  // Observable navItem stream
  navItemClick$ = this._menuItemSource.asObservable();
  // service command
  navMenuClick(args: NavigationMenuArgs) {
    this._menuItemSource.next(args);
  }
}
