import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromAuthentication from '../reducers/authentication.reducer';
import { SelectItem } from 'primeng/api';

export const authenticationStatusState = createSelector(
  fromFeature.authenticationStoreState,
  (state: fromFeature.AuthenticationState) => state?.authenticationState
);

export const getUserAuthenticatedSelector = createSelector(
  authenticationStatusState,
  fromAuthentication.getIsAuthenticated
);
export const getUserAuthenticationPendingSelector = createSelector(
  authenticationStatusState,
  fromAuthentication.getAuthenticationPending
);
export const getAuthenticatedUserSelector = createSelector(
  authenticationStatusState,
  fromAuthentication.getAuthenticatedUser
);
export const getUserNotAuthenticatedSelector = createSelector(
  authenticationStatusState,
  fromAuthentication.getIsSignedOut
);

export const getAuthenticatedUserIsPartner = createSelector(
  authenticationStatusState,
  fromAuthentication.getAuthenticatedUserIsPartner
);

export const getAuthenticatedUserIsAdmin = createSelector(
  authenticationStatusState,
  fromAuthentication.getAuthenticatedUserIsAdmin
);

export const SelectResearcherIsPayPerOrder = createSelector(
  authenticationStatusState,
  fromAuthentication.getIsPayPerOrder
);

export const getPartnerResearchers = createSelector(
  authenticationStatusState,
  fromAuthentication.getPartnerResearchers
);

export const getPartnerResearchersAsSelectedItems = createSelector(
  getPartnerResearchers,
  (entities) => {
    return entities.map(
      (item) => <SelectItem>{ label: item.name, value: item.tenantId }
    );
  }
);

export const getPartnerResearchersWithAllAsSelectedItems = createSelector(
  getPartnerResearchers,
  (entities) => {
    let items = entities.map(
      (item) => <SelectItem>{ label: item.name, value: item.tenantId }
    );

    items = [<SelectItem>{ label: 'All', value: null }, ...items];

    return items;
  }
);

export const getPrimaryPartnerResearcherId = createSelector(
  authenticationStatusState,
  fromAuthentication.getPrimaryPartnerResearcher
);

export const getPartnerCompany = createSelector(
  getAuthenticatedUserSelector,
  (user) => {
    return user.authInfo.partnerInfo? user.authInfo.partnerInfo.tenant: null;
  }
);

export const getUserRoleAndPermissions = createSelector(
  authenticationStatusState,
  fromAuthentication.getUserRoleAndPermissions
);

export const selectAuthenticatedResearcherId = createSelector(
  authenticationStatusState,
  fromAuthentication.getAuthenticatedResearcherId
);

export const selectPartnerModules = createSelector(
  authenticationStatusState,
  fromAuthentication.getPartnerModules
);
