import { createReducer, on } from '@ngrx/store';
import { resizeWindowAction } from '../actions/layout.actions';

export interface LayoutState {
  windowHeight: number;
  windowWidth: number;
}

const initialState: LayoutState = {
  windowHeight: window.screen.height,
  windowWidth: window.screen.width
};

export const reducer = createReducer(
  initialState,
  on(resizeWindowAction, (state, { windowSize }) => ({
    ...state,
    windowHeight: windowSize.height,
    windowWidth: windowSize.width
  }))
);

export const getWindowWidth = (state: LayoutState) => state.windowWidth;
export const getWindowHeight = (state: LayoutState) => state.windowHeight;
