<div class="ui-g registration-complete">
  <div *ngIf="isPartner" class="ui-g-12">
    <label
      class="control-label text"
    >
      {{ 'registrationComplete.text1' | translate }}
    </label>
    <br><br/>
    <label
      class="control-label text"
    >
      {{ 'registrationComplete.text2' | translate }}
    </label>
    <br><br/><br><br/>
  </div>
  <div *ngIf="!isPartner && isResearcherPayPerOrder" class="ui-g-12">
    <label
      class="control-label text"
    >
      {{ 'registrationComplete.ppo.text1' | translate }}
    </label>
    <br><br/>
    <label
      class="control-label text"
    >
      {{ 'registrationComplete.ppo.text2' | translate }}
    </label>
    <br><br/>
    <label
      class="control-label text"
    >
      {{ 'registrationComplete.ppo.text3' | translate }}
    </label>
    <br><br/><br><br/>
  </div>
  <div *ngIf="!isPartner && !isResearcherPayPerOrder" class="ui-g-12">
    <label
      class="control-label text"
    >
      {{ 'registrationComplete.nppo.text1' | translate }}
    </label>
    <br><br/>
    <label
      class="control-label text"
    >
      {{ 'registrationComplete.nppo.text2' | translate }}
    </label>
    <br><br/>
    <label
      class="control-label text"
    >
      {{ 'registrationComplete.nppo.text3' | translate }}
    </label>
    <br><br/><br><br/>
  </div>
</div>