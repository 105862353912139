import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { DataService } from './data.service';
import * as fromRoot from '@cci/store/reducers';
import { getApplicationSettings } from '@cci/store/selectors/app-settings.selector';
import { AuthenticationService } from './authentication.service';
import { DashboardInfo } from '@cci/shared/models';

@Injectable()
export class DashboardInfoService implements OnDestroy {
  private userApiUrl: string | null;
  private subscription;

  constructor(
    private dataService: DataService,
    private authenticationService: AuthenticationService,
    private store: Store<fromRoot.State>
  ) {
    this.init();
  }

  public GetMarketPlaceDashboardInfo(): Observable<DashboardInfo[]> {
    const userId = this.authenticationService.getSub();

    let url = this.getApisUrl('market-place');
    url = `${url}`;

    return this.dataService.get<DashboardInfo[]>(url);
  }

  public GetAffiliateDashboardInfo(): Observable<DashboardInfo[]> {
    const userId = this.authenticationService.getSub();

    let url = this.getApisUrl('affiliate');
    url = `${url}`;

    return this.dataService.get<DashboardInfo[]>(url);
  }

  private getApisUrl(action: string = null) {
    if (action != null) {
      return `${this.userApiUrl}${this.apiPath}/${action}`;
    }
    return this.userApiUrl +this.apiPath;
  }

  get apiPath() {
    const apiPath = '/marketplace/api/Dashboard';
    return apiPath;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  init(): void {
    this.subscription = this.store
      .select(getApplicationSettings)
      .subscribe((response) => {
        if (response) {
          this.userApiUrl = response.apiUrl;
        }
      });
  }
}
