export enum TodoType {
  None = 0,

  SetupUserInformation = 1,

  SetupMasterSchedule = 2,

  SetupServiceArea = 3,

  ResearcherAccountActivated = 4,

  SetupCompanyInformation = 5,

  SelectModule = 6,

  SetUpMarketPlaceModule = 7,

  SetUpAffiliateModule = 8,

  PartnerAccountActivated = 9,
}
