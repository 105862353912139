import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';

import * as fromAuth from '../../auth/store';
import { map, take, switchMap, catchError, tap } from 'rxjs/operators';
import {
  completeAuthenticationProcedure,
  authenticatedUserDataLoaded,
} from '../../auth/store/actions';
import { LocalStorageService, UserService, UsersService } from '../services';
import { StorageKeys } from '../../shared/constants/storage-keys';
import { PermissionsEnum } from '@cci/shared/models';

@Injectable()
export class AuthenticationGuard implements CanActivate {
  private permissions = PermissionsEnum;

  constructor(
    private store: Store<fromAuth.State>,
    private localStorage: LocalStorageService,
    private userService: UserService,
    private permissionsService: NgxPermissionsService,
    private rolesService: NgxRolesService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    this.storeActivatedUrl(state.url);
    return this.userIsAuthenticated().pipe(
      switchMap((auhenticated) => {
        if (auhenticated) {
          return of(true);
        } else {
          return of(false);
        }
      }),
      catchError(() => of(false))
    );
  }

  userIsAuthenticated() {
    return this.isAuhenticated().pipe(
      switchMap((authenticated) => {
        if (authenticated) {
          return this.hasPermissionsInStore().pipe(
            switchMap((hasPermissions) => {
              if (hasPermissions) {
                return of(hasPermissions);
              }

              return this.hasPermissionsInApi();
            }),
            catchError(() => of(false))
          );
        }

        this.store.dispatch(completeAuthenticationProcedure());
        return of(false);
      })
    );
  }

  isAuhenticated() {
    return this.store.pipe(
      select(fromAuth.getUserAuthenticatedSelector),
      map((authenticated) => {
        if (!authenticated) {
          this.store.dispatch(completeAuthenticationProcedure());
          return false;
        } else {
          return true;
        }
      }),
      take(1)
    );
  }

  hasPermissionsInStore() {
    return this.store.pipe(
      select(fromAuth.getUserRoleAndPermissions),
      map((data) => {
        const hasPermissions = !!data;
        return hasPermissions;
      }),
      take(1)
    );
  }

  hasPermissionsInApi() {
    return this.userService.getAuthenticatedUser().pipe(
      map((result) => authenticatedUserDataLoaded({ payload: result })),
      tap((action) => {
        this.permissionsService.loadPermissions(
          action.payload.permissions.permissions.map(
            (item) => this.permissions[item.value]
          )
        );

        if (action.payload.permissions.rolePermissions) {
          action.payload.permissions.rolePermissions.forEach((item) =>
            this.rolesService.addRole(
              item.name,
              item.permissions.map((perm) => this.permissions[perm.value])
            )
          );
        }
        return this.store.dispatch(action);
      }),
      map((result) => !!result),
      catchError((error) => {
        this.router.navigate(['/404']);

        return of(false);
      })
    );
  }

  private storeActivatedUrl(url: string): void {
    if (!url.includes('/noauth')) {
      this.localStorage.set(StorageKeys.ACTIVATE_URL, url);
    }
  }
}
