import { Injectable, ErrorHandler } from '@angular/core';
import { LogService, LogLevel, LogEntry } from './logging/log.service';
import { NotifyMessage } from '@cci/shared/models';
import { GrowlNotifyService } from './growl-notify-service';

@Injectable()
export class AppErrorHandler extends ErrorHandler {
  constructor(
    private logger: LogService,
    private growlNotificationService: GrowlNotifyService
  ) {
    super();
  }

  handleError(error: any) {
    /*  if (
      confirm(
        'Fatal Error!\nAn unresolved error has occured. Do you want to reload the page to correct this?\n\nError: ' +
          error.message
      )
    ) {
      // window.location.reload(true);
    } */

    this.growlNotificationService.showErrorMessage(error.message);

    super.handleError(error.log);
  }
}
