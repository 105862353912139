import { createSelector, createFeatureSelector } from '@ngrx/store';
import { SelectItem } from 'primeng/api';

import * as fromRoot from '../reducers';
import * as fromConstants from '../reducers/constants.reducer';

export const getConstantsState = createFeatureSelector<
  fromConstants.ConstantsState
>('constants');

export const getJobPositionsPending = createSelector(
  getConstantsState,
  fromConstants.getJobPositionsPending
);

export const getJobPositionsLoaded = createSelector(
  getConstantsState,
  fromConstants.getJobPositionsLoaded
);

export const getJobPositions = createSelector(
  getConstantsState,
  fromConstants.getJobPositions
);

// export const getJobPositionsForDropdown = createSelector(
//   getJobPositions,
//   entities => {
//     return entities.map(
//       item => <SelectItem>{ label: item.name, value: item.id }
//     );
//   }
// );

export const getUserTypesPending = createSelector(
  getConstantsState,
  fromConstants.getUserTypesPending
);

export const getUserTypesLoaded = createSelector(
  getConstantsState,
  fromConstants.getUserTypesLoaded
);

export const getUserTypes = createSelector(
  getConstantsState,
  fromConstants.getUserTypes
);

export const getApplicationPending = createSelector(
  getConstantsState,
  fromConstants.getApplicationPending
);

export const getWorkModesLoaded = createSelector(
  getConstantsState,
  fromConstants.getWorkModesLoaded
);

export const getWorkModes = createSelector(
  getConstantsState,
  fromConstants.getWorkModes
);

export const getWeekDaysLoaded = createSelector(
  getConstantsState,
  fromConstants.getWeekDaysLoaded
);

export const getWeekDays = createSelector(
  getConstantsState,
  fromConstants.getWeekDays
);

export const getStatesLoaded = createSelector(
  getConstantsState,
  fromConstants.getStatesLoaded
);

export const getStates = createSelector(
  getConstantsState,
  fromConstants.getStates
);

export const getStatesAsSelectedItem = createSelector(
  getStates,
  entities => {
    return entities.map(
      item => <SelectItem>{ label: item.name, value: item.abbreviation }
    );
  }
);

export const getStatesWithId = createSelector(
  getStates,
  entities => {
    return entities.map(
      item => <SelectItem>{ label: item.name, value: item.id }
    )
  }
);

export const getStatesAsSelectedItemWithStatus = createSelector(
  getStates,
  entities => {
    let states = entities.map(
      item => <SelectItem>{ label: item.name, value: item.abbreviation, disabled: !item.hasCourtHouse }
    );

    states = states.sort(function (a, b) {
      if(a.disabled == b.disabled) return 0;
      if (a.disabled == false) return -1;
      if (b.disabled == false) return 1;
    
      if (a.disabled < b.disabled)
          return -1;
      if (a.disabled > b.disabled)
          return 1;
      return 0;
    });

    return states;
  }
);

export const getCountriesLoaded = createSelector(
  getConstantsState,
  fromConstants.getCountriesLoaded
);

export const getCountries = createSelector(
  getConstantsState,
  fromConstants.getCountries
);

export const getCountriesAsSelectedItem = createSelector(
  getCountries,
  entities => {
    let countries =  entities.map(
      item => <SelectItem>{ label: item.name, value: item.abbreviation }
    );
    countries = countries.sort(function (a, b) {
      if(a.label == b.label) return 0;
      if (a.value == 'US') return -1;
      if (b.value == 'US') return 1;
    
      if (a.label < b.label)
          return -1;
      if (a.label > b.label)
          return 1;
      return 0;
    });

    return countries;
  }
);

export const getStatesProvincesAsSelectedItem = createSelector(
  getStates,
  entities => {
    return entities.map(
      item => <SelectItem>{ label: item.name, value: item.abbreviation }
    );
  }
);

export const getStatesAndProvincesAsSelectedItem = createSelector(
  getStates,
  entities => {
    return entities.map(
      item => <SelectItem>{ label: item.name, value: item.abbreviation }
    );
  }
);

export const getCountiesLoaded = createSelector(
  getConstantsState,
  fromConstants.getCountiesLoaded
);

export const getCounties = createSelector(
  getConstantsState,
  fromConstants.getCounties
);

export const getCountiesAsSelectedItem = createSelector(
  getCounties,
  entities => {
    return entities.map(
      item => <SelectItem>{ label: item.name, value: item.id }
    );
  }
);

export const getCountiesWithAbbreviationAsSelectedItem = createSelector(
  getCounties,
  entities => {
    return entities.map(
      item => <SelectItem>{ label: item.stateAbbreviation+' - '+item.name, value: item.id }
    );
  }
);

export const getCountiesWithAbbreviationAsSelectedItemWithStatus = createSelector(
  getCounties,
  entities => {
    return entities.map(
      item => <SelectItem>{ label: item.stateAbbreviation+' - '+item.name, value: item.id , disabled: !item.hasCourtHouse }
    );
  }
);

export const getStateAsSelectedItem = createSelector(
  getStates,
  entities => {
    return entities.map(
      item => <SelectItem>{ label: item.name, value: item.id }
      );
  }
);

export const getCourtHousesLoaded = createSelector(
  getConstantsState,
  fromConstants.getCourtHousesLoaded
);

export const getCourtHousesEntities = createSelector(
  getConstantsState,
  fromConstants.getCourtHouses
);

export const getAllCourtHouses = createSelector(
  getCourtHousesEntities,
  entities => {
    return Object.keys(entities).map(id => entities[parseInt(id, 10)]);
  }
);

export const getCourtHousesWithCountyCode = createSelector(
  getAllCourtHouses,
  entities => {
    return entities.map(
      item => <SelectItem>{ label: item.name, value: item.county }
    );
  }
);

export const getCourtHousesAsSelectedItem = createSelector(
  getAllCourtHouses,
  entities => {
    return entities.map(
      item => <SelectItem>{ label: item.name, value: item.id }
    );
  }
);

export const getTimeZonesLoaded = createSelector(
  getConstantsState,
  fromConstants.getTimeZonesLoaded
);

export const getTimeZones = createSelector(
  getConstantsState,
  fromConstants.getTimeZones
);

export const getTimeZonesAsSelectedItem = createSelector(
  getTimeZones,
  entities => {
    let timezones =  entities.map(
      item => <SelectItem>{ label: item.value, value: item.key }
    );
    timezones = timezones.sort(function (a, b) {
      if(a.label == b.label) return 0;
      if (a.value == 'Atlantic Standard Time') return -1;
      if (b.value == 'Atlantic Standard Time') return 1;
      if (a.value == 'Eastern Standard Time') return -1;
      if (b.value == 'Eastern Standard Time') return 1;
      if (a.value == 'US Eastern Standard Time') return -1;
      if (b.value == 'US Eastern Standard Time') return 1;
      if (a.value == 'Central Standard Time') return -1;
      if (b.value == 'Central Standard Time') return 1;
      if (a.value == 'Mountain Standard Time') return -1;
      if (b.value == 'Mountain Standard Time') return 1;
      if (a.value == 'US Mountain Standard Time') return -1;
      if (b.value == 'US Mountain Standard Time') return 1;
      if (a.value == 'Pacific Standard Time') return -1;
      if (b.value == 'Pacific Standard Time') return 1;
      if (a.value == 'Alaskan Standard Time') return -1;
      if (b.value == 'Alaskan Standard Time') return 1;
      if (a.value == 'Hawaiian Standard Time') return -1;
      if (b.value == 'Hawaiian Standard Time') return 1;
    
      return 0;
    });

    return timezones;
  }
);

export const getCourtTypesLoaded = createSelector(
  getConstantsState,
  fromConstants.getCourtTypesLoaded
);

export const getCourtTypes = createSelector(
  getConstantsState,
  fromConstants.getCourtTypes
);

export const getCourtTypesAsSelectedItem = createSelector(
  getCourtTypes,
  entities => {
    return entities.map(
      item => <SelectItem>{ label: item.value, value: item.key }
    );
  }
);

export const getCourtLevelsLoaded = createSelector(
  getConstantsState,
  fromConstants.getCourtLevelsLoaded
);

export const getCourtLevels = createSelector(
  getConstantsState,
  fromConstants.getCourtLevels
);

// export const getCourtLevelsAsSelectedItem = createSelector(
//   getCourtLevels,
//   entities => {
//     const courtLevels = entities.map(
//       item => <SelectItem>{ label: item.name, value: item.id }
//     );

//     return courtLevels;
//   }
// );

export const getConstantsLoaded = createSelector(
  getConstantsState,
  fromConstants.getConstantsLoaded
);

export const getAccountStatus = createSelector(
  getConstantsState,
  fromConstants.getAccountStatus
);

export const getAccountStatusAsSelectedItem = createSelector(
  getAccountStatus,
  entities => {
    const courtLevels = entities.map(
      item => <SelectItem>{ label: item.name, value: item.id }
    );

    return courtLevels;
  }
);

export const getRecordTypesLoaded = createSelector(
  getConstantsState,
  fromConstants.getRecordTypesLoaded
);

export const getRecordTypes = createSelector(
  getConstantsState,
  fromConstants.getRecordTypes
);

export const getRecordTypesAsSelectedItem = createSelector(
  getRecordTypes,
  entities => {
    const recordTypes = entities.map(
      item => <SelectItem>{ label: item.value, value: item.key }
    );

    return recordTypes;
  }
);

export const getSearchMethodsLoaded = createSelector(
  getConstantsState,
  fromConstants.getSearchMethodsLoaded
);

export const getSearchMethods = createSelector(
  getConstantsState,
  fromConstants.getSearchMethods
);

export const getSearchMethodsAsSelectedItem = createSelector(
  getSearchMethods,
  entities => {
    const searchMethods = entities.map(
      item => <SelectItem>{ label: item.value, value: item.key }
    );

    return searchMethods;
  }
);

export const getSearchTimeFramesLoaded = createSelector(
  getConstantsState,
  fromConstants.getSearchTimeFramesLoaded
);

export const getSearchTimeFrames = createSelector(
  getConstantsState,
  fromConstants.getSearchTimeFrames
);

export const getSearchTimeFramesAsSelectedItem = createSelector(
  getSearchTimeFrames,
  entities => {
    const searchTimeFrames = entities.map(
      item => <SelectItem>{ label: item.value, value: item.key }
    );

    return searchTimeFrames;
  }
);

export const getEyeColors = createSelector(
  getConstantsState,
  fromConstants.getEyeColors
);
export const getEyeColorsAsSelectedItem = createSelector(
  getEyeColors,
  entities => {
    const eyeColors = entities.map(
      item => <SelectItem>{ label: item.value, value: item.key }
    );

    return eyeColors;
  }
);

export const getGenders = createSelector(
  getConstantsState,
  fromConstants.getGenders
);
export const getGendersAsSelectedItem = createSelector(
  getGenders,
  entities => {
    const genders = entities.map(
      item => <SelectItem>{ label: item.value, value: item.key }
    );

    return genders;
  }
);

export const getHairColors = createSelector(
  getConstantsState,
  fromConstants.getHairColors
);
export const getHairColorsAsSelectedItem = createSelector(
  getHairColors,
  entities => {
    const hairColors = entities.map(
      item => <SelectItem>{ label: item.value, value: item.key }
    );

    return hairColors;
  }
);

export const getRaces = createSelector(
  getConstantsState,
  fromConstants.getRaces
);
export const getRacesAsSelectedItem = createSelector(
  getRaces,
  entities => {
    const races = entities.map(
      item => <SelectItem>{ label: item.value, value: item.key }
    );

    return races;
  }
);