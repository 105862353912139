import { GridQueryOptions } from '@cci/shared/models';
import { createAction, props, union } from '@ngrx/store';

export const loadOrders = createAction(
  '[Orders Collection Page] Load Orders',
  props<{
    tenantId: number;
    queryOptions: GridQueryOptions;
  }>()
);

export const refreshOrders = createAction(
  '[Orders Collection Page] refresh Orders'
);

const all = union({
  loadOrders
});

export type loadOrdersPageUnion = typeof all;
