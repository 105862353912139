<div class="p-col-12">
  <div [formGroup]="form">
    <div class="p-grid p-grid-form-group">
      <div class="p-col-12">
        <label
          class="control-label"
          style="font-size:x-large; text-align:center"
        ><span>
          {{ headerText | translate }}
        </span>
          <i *ngIf="confirmationName === 'affiliatedInfoSidebarConfirmationName'; else other"
          class="fal fa-info-circle faInfoCircleHeader" 
          (click)="infoSideBar(sideBarTextForHeader, 'p-sidebar-md infoIconMarketplace')"
          ></i>
          <ng-template #other>
            <i class="fal fa-info-circle faInfoCircleHeader" 
            (click)="infoSideBar(sideBarTextForHeader, 'p-sidebar-md note')"
            ></i>
          </ng-template>
        </label>
        <br /><br />

        <div class="p-col-12 p-lg-8 p-lg-offset-2 p-md-8 p-md-offset-2 p-sm-12">
          <div class="p-grid p-grid-form-group">
            <div class="p-col-12 p-md-6 vertical-padding">
              <label class="control-label fix-label label-padding">
                <i class="fal fa-info-circle faInfoCircle" 
                (click)="infoSideBar(sideBarTextForLabel1, 'p-sidebar-md note')"
                ></i>
                {{ leftLabel1 | translate}}
              </label>
            </div>
            <div class="p-col-12 p-md-6 vertical-padding">
              <input
                type="text"
                autocomplete="off"
                value="{{ rightLabel1 | translate }}"
                [disabled]="true"
                class="p-inputtext p-component"
              />
            </div>
          </div>
        </div>

        <div class="p-col-12 p-lg-8 p-lg-offset-2 p-md-8 p-md-offset-2 p-sm-12">
          <div class="p-grid p-grid-form-group">
            <div class="p-col-12 p-md-6 vertical-padding">
              <label class="control-label fix-label label-padding">
                <i class="fal fa-info-circle faInfoCircle" 
                (click)="infoSideBar(sideBarTextForLabel2, 'p-sidebar-md infoIconMarketplace')"
                ></i>
                {{ leftLabel2 | translate}}
              </label>
            </div>
            <div class="p-col-12 p-md-6 vertical-padding">
              <input
                type="text"
                autocomplete="off"
                value="{{ rightLabel2 | translate }}"
                [disabled]="true"
                class="p-inputtext p-component"
              />
            </div>
          </div>
        </div>
        
        <div class="p-col-12 p-lg-8 p-lg-offset-2 p-md-8 p-md-offset-2 p-sm-12">
          <div class="p-grid p-grid-form-group">
            <div class="p-col-12 p-md-6 vertical-padding">
              <label class="control-label fix-label label-padding"
              for="marketplacemodule-maxprice"
              >
              <i class="fal fa-info-circle faInfoCircle" 
              (click)="infoSideBar(sideBarTextForLabel3, 'p-sidebar-md note')"
              ></i>
              <span class="asterisk">*</span>{{ leftLabel3 | translate}}
              </label>
            </div>
            <div class="p-col-12 p-md-6 vertical-padding">
              <input
              id="marketplacemodule-maxprice"
              type="text"
              autocomplete="off"
              placeholder="{{ placeholder | translate }}"
              formControlName="price"
              class="p-inputtext p-component"
              (keyup)="createInput()"
            />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
