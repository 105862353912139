import { createReducer, on } from '@ngrx/store';

import { User } from 'oidc-client';
import { AuthorizedUser } from '@cci/shared/models';
import { PartnerResearcherType } from '../../../shared/models';

import { startAuthenticationProcedure } from '../actions/authentication-api.actions';
import {
  authenticationPending,
  loadAuthenticatedUserDataSuccess,
  reloadAuthenticatedUserDataSuccess,
  signOutSuccess,
  userAuthenticated,
  userAuthetnticationFailed,
} from '../actions/authentication.actions';

export interface AuthenticationState {
  isAuthenticated: boolean;
  pending: boolean;
  user: AuthorizedUser;
  sessionActive: boolean;
}

const initialState: AuthenticationState = {
  user: null,
  isAuthenticated: false,
  pending: false,
  sessionActive: false,
};

export const reducer = createReducer(
  initialState,
  on(startAuthenticationProcedure, (state) => ({
    ...state,
    isAuthenticated: true,
  })),
  on(authenticationPending, (state) => ({
    ...state,
    pending: true,
    sessionActive: false,
  })),
  on(userAuthenticated, (state, { payload }) => {
    return {
      ...state,
      isAuthenticated: true,
      pending: false,
      sessionActive: true,
      user: Object.assign({}, state.user, payload),
    };
  }),
  on(
    loadAuthenticatedUserDataSuccess,
    reloadAuthenticatedUserDataSuccess,
    (state, { payload }) => {
      return {
        ...state,
        isAuthenticated: true,
        pending: false,
        sessionActive: true,
        user: Object.assign({}, state.user, {
          authInfo: payload,
        }),
      };
    }
  ),
  on(signOutSuccess, (state) => ({
    ...state,
    initialState,
  })),
  on(userAuthetnticationFailed, (state) => ({
    ...state,
    user: null,
    isAuthenticated: false,
    pending: false,
    sessionActive: false,
  }))
);

export const getIsAuthenticated = (state: AuthenticationState) => {
  return state?.isAuthenticated;
};
export const getAuthenticationPending = (state: AuthenticationState) => {
  return state?.pending;
};
export const getAuthenticatedUser = (state: AuthenticationState) => {
  return state?.user;
};
export const getAuthenticatedUserIsPartner = (state: AuthenticationState) => {
  if (!state?.user || !state?.user.authInfo) {
    return false;
  }

  return state?.user.authInfo && state?.user.authInfo.isPartner ? true : false;
};
export const getAuthenticatedUserIsAdmin = (state: AuthenticationState) => {
  if (!state?.user || !state?.user.authInfo) {
    return false;
  }

  return state?.user.authInfo && state?.user.authInfo.partnerInfo.isAdmin
    ? true
    : false;
};
export const getIsPayPerOrder = (state: AuthenticationState) => {
  return state?.user.authInfo.researcherInfo.isPayPerOrder;
};
export const getPrimaryPartnerResearcher = (state: AuthenticationState) => {
  const researcher = state?.user.authInfo.researcherInfo.tenants.find(
    (item) => item.isPrimary
  );
  return researcher.tenantId;
};
export const getIsSignedOut = (state: AuthenticationState) => {
  return !state?.isAuthenticated;
};
export const getSessionIsActive = (state: AuthenticationState) => {
  return state?.sessionActive;
};
export const getPartnerResearchers = (state: AuthenticationState) => {
  if (!state?.user || !state?.user.authInfo) {
    return [];
  }
  return state?.user.authInfo.researcherInfo
    ? state?.user.authInfo.researcherInfo.tenants
    : [];
};

export const getUserRoleAndPermissions = (state: AuthenticationState) => {
  if (state?.user.authInfo) {
    return state?.user.authInfo.permissions;
  } else {
    return null;
  }
};

export const getAuthenticatedResearcherId = (state: AuthenticationState) => {
  return state?.user.authInfo.researcherInfo.researcherId;
};

export const getEmployers = (state: AuthenticationState) =>
  state?.user.authInfo.researcherInfo.tenants;

export const getPartnerModules = (state: AuthenticationState) =>
  state?.user.authInfo.partnerInfo.tenant.modules;
