import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TwoFactorProvider } from '@cci/shared/models/two-factor-provider';
import { AppTranslationService } from '@cci/core/services/app-translation.service';
import { SelectItem } from 'primeng/api';
import { SideBarContent } from '@cci/shared/models';

@Component({
  selector: 'app-two-factor-method',
  templateUrl: './two-factor-method.component.html',
  styleUrls: ['./two-factor-method.component.scss'],
})
export class TwoFactorMethodComponent implements OnInit {
  @Input() public provider: TwoFactorProvider;
  @Output() showSelectTwoFactorOption = new EventEmitter<TwoFactorProvider>();
  @Output()
  showSideBar = new EventEmitter<SideBarContent>();

  configuredText: string;
  configuredOptions: SelectItem[];
  notconfiguredOptions: SelectItem[];
  selectedOption: number;
  twofactorOptionSidebarInfo: string;
  twoFactorMethodSidebarConfirmationName = "twoFactorMethodSidebarConfirmationName";

  translate = (key: string) => <string>{};
  constructor(private translationService: AppTranslationService) {
    this.translate = (key: string) =>
      this.translationService.getTranslation(key);
  }

  ngOnInit(): void {
    this.configuredOptions = [
      {
        label: this.translate('common.strings.configured'),
        value: 1,
      },
      {
        label: this.translate('common.strings.deConfigured'),
        value: 2,
      }
    ];
    this.notconfiguredOptions = [
      {
        label: this.translate('common.strings.configure'),
        value: 1,
      },
      {
        label: this.translate('common.strings.notConfigured'),
        value: 2,
      }
    ];

    this.twofactorOptionSidebarInfo = this.provider.name == 'SMS' ? this.translate('twoFactor.twoFactorOptions.sidebarInfo.sms') : this.translate('twoFactor.twoFactorOptions.sidebarInfo.authenticator')

    this.selectedOption = this.provider.active? 1: 2;
    // if (this.provider.value === null) {
    //   this.configuredText = this.provider.active
    //     ? this.translate('common.strings.configured')
    //     : this.translate('common.strings.notConfigured');
    // } else {
    //   this.configuredText = this.provider.value;
    // }
  }

  onShowSelectTwoFactorOption(provider: TwoFactorProvider) {
    this.showSelectTwoFactorOption.emit(provider);
  }

  infoSideBar(text) {
    const content = new SideBarContent(
      this.twoFactorMethodSidebarConfirmationName,
      true,
      'bottom',
      text,
      true,
      false,
      false,
      true,
      '',
      'p-sidebar-md note',
      'sidebarHeader',
      'sidebarContent',
      '',
      '',
      'Got It'
    );
    this.showSideBar.emit(content);
  }
}
