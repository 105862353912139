import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromModal from '../reducers/modal.reducer';

export const selectModalSate = createSelector(
  fromFeature.getModalState,
  (state: fromModal.ModalState) => {
    return state;
  }
);

export const selectOpenedModal = createSelector(
  selectModalSate,
  fromModal.getOpenedModal
);

export const selectOpenedModalName = createSelector(
  selectOpenedModal,
  modal => modal.name
);

export const selectModalResult = createSelector(
  selectModalSate,
  fromModal.getModalResullt
);
