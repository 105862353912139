import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { SideBarContent } from '../../models/side-bar-content';
import { SideBarClosedResult } from '../../models/side-bar-closed-result';

@Component({
  selector: 'app-ng-side-bar',
  templateUrl: './ng-side-bar.component.html',
  styleUrls: ['./ng-side-bar.component.scss']
})
export class NgSideBarComponent implements OnInit {
  @Input()
  set sideBarState(content: SideBarContent) {
    this.content = Object.assign({}, content);
  }

  @Output()
  closeModal = new EventEmitter<SideBarClosedResult>();

  content: SideBarContent = new SideBarContent(
    '',
    false,
    'bottom',
    '',
    false,
    false,
    false,
    false,
    '',
    ''
  );

  constructor() {}

  ngOnInit() {}

  handleButtonOneClick(event) {
    this.content.visible = false;
    this.closeModal.emit(
      new SideBarClosedResult(
        this.content.name,
        true,
        false,
        false,
        this.content.source
      )
    );
  }

  handleButtonTwoClick(event) {
    this.content.visible = false;
    this.closeModal.emit(
      new SideBarClosedResult(
        this.content.name,
        false,
        true,
        false,
        this.content.source
      )
    );
  }

  handleButtonThreeClick(event) {
    this.content.visible = false;
    this.closeModal.emit(
      new SideBarClosedResult(
        this.content.name,
        false,
        false,
        true,
        this.content.source
      )
    );
  }
}
