import { createAction, props, union } from '@ngrx/store';

export const changePasswordSuccess = createAction(
  '[User Profile] Change Password Success'
);

export const changePasswordFailed = createAction(
  '[User Profile] Change Password Failed',
  props<{ errors: any }>()
);
