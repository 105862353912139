import { createSelector, createFeatureSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromAppSettingsReducer from '../reducers/app-settings-reducer';

export const getApplicationSettingsPending = createSelector(
  fromFeature.getAppSettingsState,
  fromAppSettingsReducer.getAppSettingsPending
);

export const getApplicationSettingsLoaded = createSelector(
  fromFeature.getAppSettingsState,
  fromAppSettingsReducer.getAppSettingsLoaded
);

export const getApplicationSettings = createSelector(
  fromFeature.getAppSettingsState,
  fromAppSettingsReducer.getAppSettings
);

export const selectRecaptchaSiteKey = createSelector(
  getApplicationSettings,
  state => {
    return state.googleReCaptchaSiteKey;
  }
);

/* export const getDisplayFormats = createSelector(
  fromFeature.getAppSettingsState,
  fromAppSettingsReducer.getDisplayFormats
); */
