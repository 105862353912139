/* import { map, take, skip } from 'rxjs/operators';

import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Router,
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Store } from '@ngrx/store';

import { IApplicationSettings } from '../../shared/models/application-settings.model';
import { SettingsService } from './settings.service';
import * as fromRoot from '../../store/reducers';
import * as fromConfig from '../config';
import * as fromReg from '../../registration/store/reducers';

@Injectable()
export class ApplicationSettingsResolverService implements Resolve<any> {
  constructor(
    private settingsService: SettingsService,
    private router: Router,
    private store: Store<fromRoot.State>
  ) {}

  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    this.store.dispatch(new fromConfig.LoadApplicationSettings());

    return this.store.select(fromRoot.getApplicationSettings).pipe(
      skip(1),
      take(1),
      map(settings => {
        return settings;
      })
    );
  }
}
 */

import { throwError as observableThrowError, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpRequest
} from '@angular/common/http';
import { Store } from '@ngrx/store';
import { catchError, tap } from 'rxjs/operators';

import { IApplicationSettings } from '../../shared/models/application-settings.model';
import * as fromRoot from '../../store';

import * as AppSettingsActions from '../../store/actions/app-settings.actions';

@Injectable()
export class ApplicationSettingsService {
  serverSettings: IApplicationSettings;

  constructor(
    private httpClient: HttpClient,
    private store: Store<fromRoot.State>
  ) {}

  load(): Observable<IApplicationSettings> {
    const baseUri = document.baseURI || document.URL.split('/')[0];
    const newBaseUri = baseUri.endsWith('/') ? baseUri : `${baseUri}/`;

    const url = `${newBaseUri}api/settings`;

    if (this.serverSettings) {
      return of(this.serverSettings);
    }

    return this.httpClient.get<IApplicationSettings>(url).pipe(
      tap(settings => {
        this.serverSettings = settings;
        this.store.dispatch(
          AppSettingsActions.loadAppSetingsSuccess({ payload: settings })
        );
      }),
      catchError(this.handleError<any>('getSettings'))
    );
  }

  loadSettings() {
    const baseURI = document.baseURI.endsWith('/')
      ? document.baseURI
      : `${document.baseURI}/`;
    const url = `${baseURI}api/settings`;

    return this.httpClient
      .get<IApplicationSettings>(url)
      .toPromise()
      .then(x => {
        this.serverSettings = x;
      });
  }

  getSettings() {
    return this.serverSettings;
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}

export function initEnvironmentSettings(
  service: ApplicationSettingsService
): () => Promise<IApplicationSettings> {
  return () => service.load().toPromise();
}