import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-two-factor-options',
  templateUrl: './two-factor-options.component.html',
  styleUrls: ['./two-factor-options.component.scss'],
})
export class TwoFactorOptionsComponent implements OnInit {
  @Output() showSetupUsingApp = new EventEmitter();
  @Output() showSetupUsingSms = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onSetupUsingAnApp() {
    this.showSetupUsingApp.emit();
  }

  onSetupUsingSms() {
    this.showSetupUsingSms.emit();
  }
}
