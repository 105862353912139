import { createReducer, on } from '@ngrx/store';

import {
  loadUserSetupSteps,
  partnerTodoStepComplete,
  researcherTodoStepComplete,
} from '@cci/store/actions/user-setup-steps.actions';
import { UserSetUpStep } from '@cci/shared/models';
import {
  loadUserSetupStepsFailed,
  loadUserSetupStepsSuccess,
} from '../actions/user-setup-steps-api.actions';
import { TodoType } from '@cci/shared/models/todo-type';

export interface UserTodoState {
  todos: { [id: number]: UserSetUpStep };
  loaded: boolean;
  pending: boolean;
}

export const initialState: UserTodoState = {
  todos: {},
  loaded: false,
  pending: false,
};

export const reducer = createReducer(
  initialState,
  on(loadUserSetupSteps, (state, {}) => {
    return {
      ...state,
      loaded: false,
      pending: true,
    };
  }),
  on(loadUserSetupStepsSuccess, (state, { payload }) => {
    const todos = payload.todos.reduce(
      (entities: { [id: number]: UserSetUpStep }, todo: UserSetUpStep) => {
        return {
          ...entities,
          [todo.id]: todo,
        //  [TodoType[todo.type]]: todo,
        };
      },
      {
        ...state.todos,
      }
    );

    return {
      ...state,
      todos: todos,
      pending: false,
      loaded: true,
    };
  }),
  on(loadUserSetupStepsFailed, (state, { errors }) => {
    return {
      ...state,
      todos: {},
      pending: false,
      loaded: false,
    };
  }),
  on(partnerTodoStepComplete, (state, { payload }) => {
    const todoItem = Object.assign({}, state.todos[payload], {
      started: true,
      completed: true,
    });

    const todos = {
      ...state.todos,
      [todoItem.type]: todoItem,
    };

    return {
      ...state,
      todos: todos,
    };
  }),
  on(researcherTodoStepComplete, (state, { payload }) => {
    const todoItem = Object.assign({}, state.todos[payload], {
      started: true,
      completed: true,
    });

    const todos = {
      ...state.todos,
      [todoItem.type]: todoItem,
    };

    return {
      ...state,
      todos: todos,
    };
  })
);

export const getUserSetupSteps = (state: UserTodoState) => state.todos;
export const getUserSetupStepsPending = (state: UserTodoState) => state.pending;
export const getUserSetupStepsLoaded = (state: UserTodoState) => {
  return state.loaded;
};
