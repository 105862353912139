<div class="p-lg-12 p-mg-12 p-sm-12">
  <div class="p-grid">
    <div class="p-col headerClass">
        <label class="control-label" class="fontLarge fontAlignLeft">
          <u>{{ 'moduleManagement.common.headerOne' | translate }}</u>
        </label>
    </div>
    <div class="p-col rightHeaderClass">
      <!-- <a class="report-link" routerLinkActive="active" (click)="reportAnIssue($event)">
        <span>
          {{ 'moduleManagement.common.reportAnIssue' | translate }}
        </span>
      </a> -->
    </div>
  </div>

  <p-tabView styleClass="information-tab-view">
    <p-tabPanel *ngIf="tabHeaderOne"  header="{{ tabHeaderOne | translate }}">
      <span *ngIf="!areaInfo">
        <h3 class="pt-3">
          For Future Use.
        </h3>
      </span>
      <span *ngIf="areaInfo">
        <span *ngFor="let item of areaInfo[0]['dashboardItems']" class="tab-body">
          <p style="line-height: 1.5 !important; margin: 0;">{{ item.eventDate | date }}: {{ item.id | courtHousePipe : courtHouses }}; {{ item.event }}</p>
          <br>
        </span>
      </span>
    </p-tabPanel>
    <p-tabPanel *ngIf="tabHeaderTwo"  header="{{ tabHeaderOne | translate }}">
      <span *ngIf="!areaInfo">
        <h3 class="pt-3">
          For Future Use.
        </h3>
      </span>
      <span *ngIf="areaInfo">
        <span *ngFor="let item of areaInfo[0]['dashboardItems']" class="tab-body">
          <p style="line-height: 1.5 !important; margin: 0;">{{ item.eventDate | date }}: {{ item.id | courtHousePipe : courtHouses }}; {{ item.event }}</p>
          <br>
        </span>
      </span>
    </p-tabPanel>
  </p-tabView>
</div>