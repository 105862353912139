import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { AuthenticatorSetup } from '@cci/shared/models/authenticator-setup';
import { SideBarContent, TwoFactorProvider, VerifyPhoneNumberModel } from '@cci/shared/models';

@Component({
  selector: 'app-two-factor',
  templateUrl: './two-factor.component.html',
  styleUrls: ['./two-factor.component.scss'],
})
export class TwoFactorComponent implements OnInit {
  @Input() form: FormGroup;
  @Input()
  twoFactorRecoveryCodes: string[];
  @Input() public authenticatorSetup: AuthenticatorSetup = <
    AuthenticatorSetup
  >{};
  @Input() twoFactorPending: boolean;
  @Input() errors: any;
  @Input()
  set twoFactorDisabled(disabled: boolean) {
    this.handleTwoFactorStatusChanged(disabled);
  }
  @Input()
  twoFactorSmsCodeSent: boolean;
  @Input()
  twoFactorSmsCodeVerified: boolean;
  @Input()
  twoFactorSmsErrors: any;
  @Input()
  twoFactorSmsPending: boolean;

  @Input()
  twoFactorAppCodeVerified: boolean;
  @Input()
  twoFactorAppErrors: any;
  @Input()
  twoFactorAppPending: boolean;

  @Output() activateTwoFactor = new EventEmitter<string>();
  @Output() disbaledTwoFactor = new EventEmitter();
  @Output() resetRecoveryCodes = new EventEmitter();
  @Output() sendSmsCode = new EventEmitter<string>();
  @Output() verifyPhoneNumber = new EventEmitter<VerifyPhoneNumberModel>();
  @Output()
  showSideBar = new EventEmitter<SideBarContent>();

  twoFactorIsDisabled = false;
  showEnableTwoFactor = true;
  showTwoFactorOptions = false;
  showActivateAuthenticatorApp = false;
  showActivateSms = false;
  showActivateEmail = false;
  showTwoFactorActivated = false;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.showEnableTwoFactor = true;
    this.showTwoFactorOptions = false;
    this.showActivateAuthenticatorApp = false;
    this.showActivateSms = false;
    this.showActivateEmail = false;

    this.form = this.toFormGroup();
  }

  toFormGroup() {
    return this.fb.group({
      authenticatorAppForm: this.fb.group({
        verificationCode: [
          '',
          Validators.compose([Validators.required, Validators.minLength(6)]),
        ],
      }),
      smsForm: this.fb.group({
        /* countryCode: [null, Validators.compose([Validators.required])], */
        phoneNumber: [null, Validators.compose([Validators.required])],
        verificationCode: [null],
      }),
    });
  }

  onEnableTwoFactor() {
    this.showTwoFactorOptions = true;
    this.showEnableTwoFactor = false;
    this.showActivateAuthenticatorApp = false;
    this.showActivateSms = false;
    this.showActivateEmail = false;
    this.showTwoFactorActivated = false;
  }

  onShowActivateAuthenticatorApp() {
    this.showActivateAuthenticatorApp = true;
    this.showTwoFactorOptions = false;
    this.showEnableTwoFactor = false;
    this.showActivateSms = false;
    this.showActivateEmail = false;
    this.showTwoFactorActivated = false;
  }

  onShowActiveSms() {
    this.showActivateSms = true;
    this.showActivateAuthenticatorApp = false;
    this.showTwoFactorOptions = false;
    this.showEnableTwoFactor = false;
    this.showActivateEmail = false;
    this.showTwoFactorActivated = false;
  }

  onTwoFactorMethodActivatedTwoFactorSetup() {
    this.handleTwoFactorStatusChanged(this.twoFactorIsDisabled);
  }

  onShowActiveEmail() {
    this.showActivateEmail = true;
    this.showActivateAuthenticatorApp = false;
    this.showTwoFactorOptions = false;
    this.showEnableTwoFactor = false;
    this.showActivateSms = false;
    this.showTwoFactorActivated = false;
  }

  onShowSelectTwoFactorOption(provider: TwoFactorProvider) {
    this.onEnableTwoFactor();
  }

  onActivateTwoFactor(verificationCode: string) {
    this.activateTwoFactor.emit(verificationCode);
  }

  onDisableTwoFactor() {
    this.disbaledTwoFactor.emit();
  }

  onResetRecoveryCodes() {
    this.resetRecoveryCodes.emit();
  }

  onSendSmsVerificationCode(phoneNumber: string) {
    this.sendSmsCode.emit(phoneNumber);
  }

  onVerifyPhoneNumber(model: VerifyPhoneNumberModel) {
    this.verifyPhoneNumber.emit(model);
  }

  private handleTwoFactorStatusChanged(disabled) {
    this.twoFactorIsDisabled = disabled;
    this.showTwoFactorActivated = !disabled;

    if (this.showEnableTwoFactor && !disabled) {
      this.showEnableTwoFactor = false;
    }

    if (this.showActivateAuthenticatorApp && !disabled) {
      this.showActivateAuthenticatorApp = false;
    }

    if (this.showActivateSms && !disabled) {
      this.showActivateSms = false;
    }

    if (this.showActivateEmail && !disabled) {
      this.showActivateEmail = false;
    }
  }

  onShowSideBar(content) {
    this.showSideBar.emit(content);
  }
}
