import { AuthenticationGuard } from './authentication-guard.service';
import { RegistrationCompleteGuard } from './registration-complete.guard';
import { SeoGuard } from './seo.guard';
import { HasSessionGuard } from './has-session.guard';
import { ConstantsGuard } from './constants.guard';
import { UserProfileGuard } from './user-profile.guard';

export const guards: any = [
  AuthenticationGuard,
  RegistrationCompleteGuard,
  SeoGuard,
  HasSessionGuard,
  ConstantsGuard,
  UserProfileGuard
];

export * from './authentication-guard.service';
export * from './registration-complete.guard';
export * from './seo.guard';
export * from './has-session.guard';
export * from './constants.guard';
export * from './user-profile.guard';
