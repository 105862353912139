import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
  HttpRequest,
  HttpHandler
} from '@angular/common/http';
import { GrowlNotifyService } from '../services/growl-notify-service';
import { throwError, of } from 'rxjs';

@Injectable()
export class NotificationInterceptorService implements HttpInterceptor {
  constructor(private growlNotificationService: GrowlNotifyService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    return next.handle(request).pipe(
      tap((ev: HttpEvent<any>) => {
        if (ev instanceof HttpResponse) {
          const headers = ev.headers;

          const type = headers.get('x-notification-type');
          const title = headers.get('x-notification-title');
          const body = headers.get('x-notification-body');

          if (type && title && body) {
            this.growlNotificationService.showMessage({
              type: type,
              summary: title,
              detail: body,
              key: 'toast',
              life: 5000
            });
          }
        }
      })
    );
  }
}
