import { Pipe, PipeTransform } from '@angular/core';
import { SelectItem } from 'primeng/api';

@Pipe({ name: 'rolesPipe' })
export class RolesPipe implements PipeTransform {
  transform(id: string, roles?: SelectItem[]) {
    for (let role of roles) {
      if (role.value === id) {
        return role.label;
      }
    }

    return id;
  }
}
