import { OrdersCollectionPage, OrdersCollectionApi } from '../actions';
import { OrderCollectionItem, OrderForCreate } from '../../orders/models';
import { Pagination } from '../../shared/models/pagination';

export interface OrdersState {
  loading: boolean | false;
  loaded: boolean | false;
  orders: OrderCollectionItem[] | [];
  pagination: Pagination;
}

const initialState: OrdersState = {
  loading: false,
  loaded: false,
  orders: [],
  pagination: null
};

export function reducer(
  state = initialState,
  action:
    | OrdersCollectionPage.loadOrdersPageUnion
    | OrdersCollectionApi.OrderCollectionApiActionsUnion
) {
  switch (action.type) {
    case OrdersCollectionPage.loadOrders.type: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case OrdersCollectionApi.LoadOrdersSuccess.type: {
      return {
        ...state,
        loading: false,
        loaded: true,
        orders: action.orders,
        pagination: action.pagination
      };
    }

    case OrdersCollectionApi.LoadOrdersFailed.type: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    default: {
      return state;
    }
  }
}

export const getOrdersLoading = (state: OrdersState) => state.loading;
export const getOrdersLoaded = (state: OrdersState) => {
  return state.loaded;
}
export const getOrders = (state: OrdersState) => {
  return state.orders;
}
export const getOrdersPagination = (state: OrdersState) => {
  return state.pagination;
}
