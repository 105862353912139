import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import {
  UNAUTHORIZED,
  BAD_REQUEST,
  FORBIDDEN,
  UNPROCESSABLE_ENTITY,
  NOT_FOUND,
  INTERNAL_SERVER_ERROR,
} from 'http-status-codes';
import { Store } from '@ngrx/store';

import * as fromRoot from '@cci/store';
import { AppErrorHandler } from '../services/app-error.handler';
import { go } from '@cci/store/actions/router.action';
import { Urls } from '@cci/shared';
import { signOut } from '@cci/auth/store/actions';
import { ErrorBody, NotifyMessage } from '@cci/shared/models';
import { String } from 'typescript-string-operations';

@Injectable()
export class HandleHttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private globalErrorHandler: AppErrorHandler,
    private store: Store<fromRoot.State>
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof Error) {
          // client-side or network error
          const errorToLog = `Http error (client/network). ${error.message}`;

          var message = <NotifyMessage>{
            key: 'unexpectedError',
            life: 5000,
            summary: 'Http error (client/network)',
            detail: error.message,
          };

          var errorModel = { log: errorToLog, message: message };

          this.globalErrorHandler.handleError(errorModel);
        }

        const httpErrorCode = error.status;
        switch (httpErrorCode) {
          case INTERNAL_SERVER_ERROR:
            this.store.dispatch(go({ path: [Urls.ErrorInternalServerError] }));
            break;
          case UNAUTHORIZED:
            this.store.dispatch(signOut());
            break;
          case FORBIDDEN:
            this.store.dispatch(go({ path: [Urls.ErrorAccessDenied] }));
            break;
          case NOT_FOUND:
            this.store.dispatch(go({ path: [Urls.ErrorNotFound] }));
            break;
          case UNPROCESSABLE_ENTITY:
            return throwError(error.error);
          default: {
            // unsuccesful response code
            const errorToLog = `Http error (unsuccessful reponse). Message: ${
              error.message
            }, status code: ${error.status}, body: ${JSON.stringify(
              error.error
            )} `;

            var body = <ErrorBody>error.error;

            var message = <NotifyMessage>{
              id: 'bad-request',
              type: 'error',
              key: 'toast',
              life: 5000,
              summary: 'Http error (unsuccessful response)',
              detail: body.message,
              closable: true,
            };

            var errorModel = { log: errorToLog, message: message };

            this.globalErrorHandler.handleError(errorModel);
          }
        }

        return throwError(error);
      })
    );
  }
}
