import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { tap, filter, map, take, switchMap, catchError } from 'rxjs/operators';

import * as fromAuth from '../../auth/store';
import { loadUserProfile } from '../../auth/store';
import * as fromRoot from '../../store';

@Injectable()
export class UserProfileGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.checkStore().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkStore(): Observable<boolean> {
    return this.store.select(fromAuth.selectUserInformationLoaded).pipe(
      tap((loaded) => {
        if (!loaded) {
          this.store.dispatch(loadUserProfile());
        }
      }),
      filter((loaded) => loaded),
      take(1)
    );
  }

  constructor(private store: Store<fromRoot.State>) {}
}

