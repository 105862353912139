import { createSelector } from "@ngrx/store";
import * as fromFeature from '../reducers';
import * as fromProcessing from '../reducers/field-processing-recording.reducer';

export const selectFieldProcessState = createSelector(
    fromFeature.getFieldProcessingRecordingState,
    (state: fromProcessing.FieldProcessingRecording) => state
  );
  
export const selectRecordingInProgress = createSelector(
    selectFieldProcessState,
    fromProcessing.getRecordingInProgress
  )