import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-mobile-view',
  templateUrl: './mobile-view.component.html',
  styleUrls: ['./mobile-view.component.scss']
})
export class MobileViewComponent implements OnInit {

  @Output() priorScreen = new EventEmitter();
  @Output() retunrTo = new EventEmitter();
  constructor() {}

  @Input() returnLabel: string;
  ngOnInit() {}

  onClickGoBack() {
    this.priorScreen.emit();
  }

  onClickReturnTo() {
    this.retunrTo.emit();
  }

}
