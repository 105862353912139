import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { tap, filter, map, take, switchMap, catchError } from 'rxjs/operators';

import * as fromRoot from '../../store';
import * as fromAuth from '../../auth/store';
import { AuthorizedUser } from '@cci/shared/models';
import { Urls } from '../../shared/constants';
import {
  loadUserSetupSteps,
  partnerRegistrationRedirect,
  researcherRegistrationRedirect,
} from '@cci/store/actions/user-setup-steps.actions';
import {
  selectAccountActivated,
  selectUserSetupStepsLoaded,
} from '../../store';

@Injectable()
export class RegistrationCompleteGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.checkStore().pipe(
      switchMap(() => {
        return this.accountActivated().pipe(
          switchMap((complete) => {
            return this.userIsPartner().pipe(
              map((isPartner) => {
                if (!complete) {
                  if (isPartner) {
                    if (!this.activatedRouteIsPartnerRegistration(state.url)) {
                      this.store.dispatch(partnerRegistrationRedirect());
                      return false;
                    } else {
                      return true;
                    }
                  } else {
                    if (
                      !this.activatedRouteIsResearcherRegistration(state.url)
                    ) {
                      this.store.dispatch(researcherRegistrationRedirect());
                      return false;
                    } else {
                      return true;
                    }
                  }
                }

                return true;
              })
            );
          })
        );
      }),
      catchError(() => of(false))
    );
  }

  userIsPartner(): Observable<boolean> {
    return this.store
      .select(fromAuth.getAuthenticatedUserIsPartner)
      .pipe(take(1));
  }

  checkStore(): Observable<boolean> {
    return this.store.select(selectUserSetupStepsLoaded).pipe(
      tap((loaded) => {
        if (!loaded) {
          this.store.dispatch(loadUserSetupSteps());
        }
      }),
      filter((loaded) => loaded),
      take(1)
    );
  }

  accountActivated(): Observable<boolean> {
    return this.store.select(selectAccountActivated).pipe(
      tap((done) => {
        if (done) {
        }
      }),
      take(1)
    );
  }

  private activatedRouteIsPartnerRegistration(url: string): boolean {
    return url === Urls.PartnerRegistration;
  }

  private activatedRouteIsResearcherRegistration(url: string): boolean {
    return url === Urls.ResearcherRegistration;
  }

  constructor(private store: Store<fromRoot.State>) {}
}
