<div class="ui-g" [formGroup]="form">
  <div class="ui-g-12">
    <div class="ui-lg-12 ui-mg-12 ui-sm-12">
      <label class="control-label" style="font-size:x-large; text-align:center"> {{headerText}}
      </label>
      <br /><br />
      <label class="control-label">{{headerDescription}}</label>
      <ul>
        <label class="control-label">
          <li><span><u><a>See</a></u> your active OSPs.</span><i class="fal fa-info-circle faInfoCircle"></i></li>
        </label>
      </ul>
    </div>
  </div>
  <div class="ui-g-12">
    <div class="ui-lg-12 ui-mg-12 ui-sm-12">
      <div class="container" style="background-color:#BFE4f4">
        <label class="control-label" for="marketplacemodule-division" style="padding-top:10px;margin-left:7px;">Service
          Area
          <i class="fal fa-info-circle faInfoCircle"></i>
        </label>
      </div>
    </div>
  </div>
  <div class="ui-g-12 marginDivs">
    <div class=" ui-lg-8 ui-mg-8 ui-lg-offset-1 ui-mg-offset-1 ui-sm-8">
      <label class="control-label" for="marketplacemodule-country">Country:
      </label>
      <div class="panel panel-default">
        <div class="panel-body">
          <input id="marketplacemodule-country" type="text" size="30" formControlName="countryName" pInputText />
        </div>
      </div>
    </div>
  </div>
  <div class="ui-g-12 marginDivs ">
    <div class=" ui-lg-8 ui-mg-8 ui-lg-offset-1 ui-mg-offset-1 ui-sm-8">
      <label class="control-label" for="marketplacemodule-state">State:
      </label>
      <div class="panel panel-default">
        <div class="panel-body">
          <input id="marketplacemodule-state" type="text" size="30" formControlName="stateName" pInputText />
        </div>
      </div>
    </div>
  </div>
  <div class="ui-g-12 marginDivs">
    <div class=" ui-lg-11 ui-mg-11 ui-lg-offset-1 ui-mg-offset-1 ui-sm-12">
      <label class="control-label" for="marketplacemodule-county">County Seat:
      </label>
      <div class="panel panel-default">
        <div class="panel-body">
          <input id="marketplacemodule-county" type="text" size="30" formControlName="countyName" pInputText />
        </div>
      </div>
    </div>
  </div>
  <div class="ui-g-12">
    <div class="ui-lg-12 ui-mg-12 ui-sm-12">
      <div class="container" style="background-color:#BFE4f4; height: 70px;">
        <label class="control-label" for="marketplacemodule-maxprice" style="padding-top:10px;margin-left: 7px;">Max
          Price
          <i class="fal fa-info-circle faInfoCircle"></i>
        </label>
        <div class="panel panel-default">
          <div class="panel-body">
            <input id="marketplacemodule-maxprice" type="number" autocomplete="off" placeholder="$0.00 USD"
              formControlName="price" class="p-inputtext p-component" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="ui-g-12 marginDivs ">
    <ng-content select=".addional-cost"></ng-content>
  </div>
  <div class="ui-g-12 marginDivs ">
    <ng-content select=".quality-options"></ng-content>
  </div>

</div>
