import { Pipe, PipeTransform } from '@angular/core';
import { SelectItem } from 'primeng/api';

@Pipe({ name: 'courtHousePipe' })
export class CourtHousePipe implements PipeTransform {
  transform(id: number, courtHouses?: SelectItem[]) {
    for (let courtHouse of courtHouses) {
      if (courtHouse.value === id) {
        return courtHouse.label;
      }
    }

    return id;
  }
}
