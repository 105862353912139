<div class="p-grid activate-authenticator">
  <form>
    <div class="p-col-12 pt-4">
      <div class="p-grid p-grid-form-group">
        <div class="p-col-12 text-center">
          <h2>
            {{
              'twoFactor.twoFactorActivated.twoFactorActivatedHeader'
                | translate
            }}
          </h2>
          <p>
            {{
              'twoFactor.twoFactorActivated.twoFactorActivatedDescription'
                | translate
            }}
          </p>
        </div>
      </div>
    </div>
    <div class="p-col-12 pt-4">
      <div class="p-grid p-grid-form-group">
        <div class="p-col-10">
          <h4>
            {{
              'twoFactor.twoFactorActivated.twoFactorActivatedSubHeader'
                | translate
            }}
          </h4>
        </div>
        <div class="p-col-2 pull-right">
          <button
            *ngIf="twoFactorEnabled"
            pButton
            type="button"
            label="{{ 'common.strings.enabled' | translate }}"
            class="btn default-enable success p-button pull-right"
            [disabled]="true"
          ></button>
          <button
            *ngIf="!twoFactorEnabled"
            pButton
            type="button"
            label="{{ 'common.strings.disabled' | translate }}"
            class="btn default-enable secondary p-button pull-right"
            [disabled]="true"
          ></button>
        </div>
      </div>
    </div>
    
    <div class="p-col-12 pt-4">
      <div class="p-grid p-grid-form-group">
        <div class="p-col-10">
          <h4>
            {{
              'twoFactor.twoFactorActivated.twoFactorActivatedSubHeader'
                | translate
            }}
          </h4>
        </div>
        <div class="p-col-2 pull-right">
          <button
            pButton
            type="button"
            label="{{ enableDisableText }}"
            class="p-button pull-right"
            [class.green-highlight-button]="showEnabled"
            (click)="onDisableTwoFactor()"
            (mouseover)="handleButtonStateChange(false)"
            (mouseout)="handleButtonStateChange(true)"
          ></button>
        </div>
      </div>
    </div>

    <p-panel>
      <p-header>
        Two-factor methods
      </p-header>
      <app-two-factor-method-list
        [providers]="providers"
        (showSelectTwoFactorOption)="onShowSelectTwoFactorOption($event)"
        (showSideBar)="onShowSideBar($event)"
      >
      </app-two-factor-method-list>
    </p-panel>
    <!-- <p-panel header="Recovery options" styleClass="recover-options">
      <div class="p-col-12">
        <div class="p-grid p-grid-form-group">
          <div class="p-col-7">
            <p>
              Recovery codes
            </p>
          </div>
          <div class="p-col-3">
            <p>
              Configured
            </p>
          </div>
          <div class="p-col-2">
            <button
              pButton
              type="button"
              label="{{ 'buttons.Edit' | translate }}"
              class="p-button pull-right"
              (click)="onResetRecoverCodes()"
            ></button>
          </div>
        </div>
      </div>
      <div *ngIf="recoveryCodes && recoveryCodes.length > 0" class="p-col-12">
        <div class="p-grid p-grid-form-group">
          <div class="p-col-10">
            <p>
              {{
                'twoFactor.twoFactorActivated.recoveryCodeHeader' | translate
              }}
            </p>
            <ul>
              <li *ngFor="let recoveryCode of recoveryCodes">
                {{ recoveryCode }}
              </li>
            </ul>
            <p>
              {{
                'twoFactor.twoFactorActivated.recoveryCodeFooter' | translate
              }}
            </p>
          </div>
        </div>
      </div>
    </p-panel> -->

    <!-- <div class="p-col-12">
      <div class="p-grid p-grid-form-group">
        <div class="p-col-12">
          <button
            pButton
            type="button"
            label="{{
              'twoFactor.twoFactorActivated.setupAuthenticator' | translate
            }}"
            class="p-button"
          ></button>
        </div>
      </div>
    </div> -->

    <!-- <div class="p-col-12">
      <div class="p-grid p-grid-form-group">
        <div class="p-col-12">
          <button
            pButton
            type="button"
            label="{{
              'twoFactor.twoFactorActivated.resetRecoverCodes' | translate
            }}"
            class="p-button"
            (click)="onResetRecoverCodes()"
          ></button>
        </div>
      </div>
    </div> -->

    <!--  <div class="p-col-12">
      <div class="p-grid p-grid-form-group">
        <div class="p-col-12">
          <button
            pButton
            type="button"
            label="{{
              'twoFactor.twoFactorActivated.disableTwoFactor' | translate
            }}"
            class="p-button"
            (click)="onDisableTwoFactor()"
          ></button>
        </div>
      </div>
    </div> -->
  </form>
</div>
