import {
  Component,
  Output,
  Input,
  OnInit,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { SelectItem } from 'primeng/api';

import { UserInformationForUpdate } from '@cci/researcher/models';
import { SideBarContent, UserInformation } from '@cci-shared/models';
import { AppTranslationService } from '@cci/core/services/app-translation.service';

@Component({
  selector: 'app-user-information',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './user-information.component.html',
  styleUrls: ['./user-information.component.scss'],
})
export class UserInformationComponent implements OnInit {
  @Input()
  form: FormGroup;
  @Input()
  userInformation: UserInformation;
  @Input()
  pending: boolean;
  @Input()
  jobPositions: SelectItem[];
  @Input()
  states: SelectItem[];
  @Input()
  accountStatus: SelectItem[];
  @Input()
  isPartner: boolean;
  @Input()
  isAdmin: boolean;
  @Input()
  showAccountStatus: boolean;
  @Input()
  userTypeSidebarConfirmationName: string;
  @Input()
  phoneNumberInfoSidebarConfirmationName: string;
  @Input()
  readonly: boolean;
  @Input() timeZones: SelectItem[];
  cancelEdit = new EventEmitter();
  @Output()
  resetForm = new EventEmitter();
  @Output()
  updateUserProfile = new EventEmitter<UserInformationForUpdate>();
  @Output()
  showSideBar = new EventEmitter<SideBarContent>();

  header: string;
  phoneInfoContent: string;
  timezoneContent: string;
  phoneNumber: string;

  ngOnInit(): void {
    this.createInput();
    this.translate = (key: string) =>
      this.translationService.getTranslation(key);
    
    this.phoneInfoContent = this.translate('userInformation.sidebar.text');
    this.timezoneContent = this.translate('userInformation.sidebar.timezoneText');
  }

  createInput() {
    this.phoneNumber = this.form.value['phoneNumber'];
    if (!this.phoneNumber) return;
    this.phoneNumber = this.phoneNumber.replace(/[^\w\s]/gi, '');
    this.phoneNumber = this.phoneNumber.replace(/\s/g, '');

    const part1 = this.phoneNumber.substring(0, 3);
    const part2 = this.phoneNumber.substring(3, 6);
    const part3 = this.phoneNumber.substring(6);

    if (part3 == '' && part2 != '' && part1 != '') {
      this.form.controls.phoneNumber.setValue('(' + part1 + ') ' + part2);
    } else if (part3 == '' && part2 == '' && part1 != '') {
      this.form.controls.phoneNumber.setValue('(' + part1 + ')');
    } else if (part3 == '' && part2 == '' && part1 != '') {
      this.form.controls.phoneNumber.setValue('(' + part1 + ')');
    } else if (part1 == '' && part2 == '' && part3 == '') {
      this.form.controls.phoneNumber.setValue('');
    } else {
      this.form.controls.phoneNumber.setValue(
        '(' + part1 + ') ' + part2 + '-' + part3
      );
    }
  }

  translate = (key: string) => <string>{};

  get userNameControl() {
    return this.form.get('userName') as FormControl;
  }

  get emailControl() {
    return this.form.get('email') as FormControl;
  }

  get emailControlInvalid() {
    return this.emailControl.hasError('required') && this.emailControl.touched;
  }

  get timezoneValue() {
    let val;
    this.timeZones.forEach(element => {
      if(this.form.value['timeZoneId'] && (element?.value == this.form.value['timeZoneId'])) {
        val = element?.label?? null;
      }
    });
    return val;
  }

  cancel() {
    this.cancelEdit.emit();
  }

  resetFormStatus() {
    this.resetForm.emit();
  }

  infoSideBar(sidebarText) {

    const content = new SideBarContent(
      this.phoneNumberInfoSidebarConfirmationName,
      true,
      'bottom',
      sidebarText,
      true,
      false,
      false,
      true,
      '',
      'p-sidebar-md phoneInfo',
      'sidebarHeader',
      'sidebarContent',
      '',
      '',
      'Got It'
    );
    this.showSideBar.emit(content);
  }
  constructor(
    private cd: ChangeDetectorRef,
    private translationService: AppTranslationService
  ) {}
}
