import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import * as fromContainers from './containers';

export const routes: Routes = [
  {
    path: 'noauth/account/auth-callback',
    component: fromContainers.AuthenticationCallbackPageComponent,
    data: { title: '' }
  },
  {
    path: 'noauth/account/signout-callback',
    component: fromContainers.LogOutCallbackPageComponent,
    data: { title: '' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule {}
