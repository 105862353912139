<div>
  <div class="p-grid p-grid-form-group">
    <div *ngIf="showActive" class="p-lg-3 p-md-3 p-lg-offset-1 p-md-offset-1 p-sm-12 module-check-box padding-bottom">
      <p-checkbox
        (onChange)="onActive($event)"
        [(ngModel)]="checked" 
        [binary]="true"
        label="{{ day | translate }}:"
        [disabled]="!isPartner && !isResearcherPayPerOrder"
      ></p-checkbox>
    </div>
    <div *ngIf="!showActive" class="p-lg-3 p-md-3 p-lg-offset-1 p-md-offset-1 p-sm-12 padding-bottom">
      <label
        class="control-label fix-label label"
        >{{ day | translate }}: </label
      >
    </div>
    
    <div [formGroup]="scheduleForm" *ngIf="!isDisabled" class="p-lg-6 p-md-6 p-sm-12 padding-bottom">
      <div class="p-grid">
        <div class="p-col-6">
          <p-calendar
            [(ngModel)]="startTime"
            showTime="true" 
            timeOnly="true" 
            hourFormat="12"
            required="true"
            [stepMinute]="15"
            formControlName="startTime"
            styleClass="timepicker"
            [dataType]="string"
            (onSelect)="onChangeStartTime($event)"
          ></p-calendar>
        </div>
        <div class="p-col-6">
          <p-calendar
            [(ngModel)]="endTime"
            showTime="true" 
            timeOnly="true" 
            hourFormat="12"
            required="true"
            [stepMinute]="15"
            formControlName="endTime"
            styleClass="timepicker"
            [dataType]="string"
            (onSelect)="onChangeEndTime($event)"
          ></p-calendar>
        </div>
      </div>
    </div>
    <div *ngIf="isDisabled" class="p-lg-6 p-md-6 p-sm-12 padding-bottom">
      <div class="p-grid">
        <div class="p-col-6">
          <p-calendar
            [disabled]="true"
            showTime="true" 
            timeOnly="true" 
            hourFormat="12"
            required="true"
            [stepMinute]="15"
            styleClass="timepicker"
            [dataType]="string"
          ></p-calendar>
        </div>
        <div class="p-col-6">
          <p-calendar
            [disabled]="true"
            showTime="true" 
            timeOnly="true" 
            hourFormat="12"
            required="true"
            [stepMinute]="15"
            styleClass="timepicker"
            [dataType]="string"
          ></p-calendar>
        </div>
      </div>
    </div>
  </div>
</div>  