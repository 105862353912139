import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-two-factor-not-activated',
  templateUrl: './two-factor-not-activated.component.html',
  styleUrls: ['./two-factor-not-activated.component.scss'],
})
export class TwoFactorNotActivatedComponent implements OnInit {
  @Output() enableTwoFactor = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onEnableTwoFactor() {
    this.enableTwoFactor.emit();
  }
}
