import { createAction, props, union } from '@ngrx/store';
import { OrderCollectionItem } from '../../orders/models';
import { OrderStatus, Pagination } from '../../shared/models';

export const LoadOrdersSuccess = createAction(
  '[Orders Collection API] Load Orders Success',
  props<{ 
    orders: OrderCollectionItem[]; 
    pagination: Pagination;
  }>()
);

export const LoadOrdersFailed = createAction(
  '[Orders Collection API] Load Orders Failed',
  props<{ error: any }>()
);

const all = union({
  LoadOrdersSuccess,
  LoadOrdersFailed
});

export type OrderCollectionApiActionsUnion = typeof all;
