import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { tap, delay, catchError } from 'rxjs/operators';

//import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class HttpRequestOverlayInterceptor implements HttpInterceptor {
  constructor(private spinnerService: NgxSpinnerService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const randomTime = this.getRandomIntInclusive(0, 1500);
    const started = Date.now();
    this.spinnerService.show();
    return next.handle(req).pipe(
      delay(randomTime), // Simulate random Http call delays
      tap((event) => {
        if (event instanceof HttpResponse) {
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinnerService.hide();
          }, 2000);
        }
      }),
      catchError((err) => {
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinnerService.hide();
        }, 2000);
        return throwError(err);
      })
    );
  }

  getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min; // The maximum is inclusive and the minimum is inclusive
  }
}
