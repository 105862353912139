import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import * as fromRoot from '../../store/reducers';
import { DataService } from './data.service';
import { OrderSubmissionProfile } from '../../shared/models/order-submission-profile';
import { OrderSubmissionProfileForCreate } from '../../shared/models/order-submission-profile-for-create';
import { OrderFulfillmentProfile } from '../../shared/models/order-fullfillment-profile';
import { getApplicationSettings } from '../../store/selectors/app-settings.selector';

import { AuthenticationService } from './authentication.service';
import {
  AddServiceAreaDto,
  FilterFieldMetadata,
  GridQueryOptions,
  OrderFulfillmentProfileForCreate,
  OrderPricing,
  CourthousesByResearcherDetailsItemPagedList,
  ResearcherGridItem,
  ResearcherItemPagedList,
  SelectedModulesForUpdate,
  UpdateItem,
  ModulesConfigured,
  OfpGrid,
  OspGrid,
  CourthousesForResearchersItemPagedList
} from '@cci/shared/models';

@Injectable()
export class PartnerService implements OnDestroy {
  private registrationUrl: string | null;
  private apiUrl: string | null;
  private subscription;

  getResearchersAndInvites(
    partnerId: number,
    page: GridQueryOptions
  ): Observable<HttpResponse<ResearcherItemPagedList>> {
    let url = this.getOldApisUrl('getResearchersAndInvites');
    url = `${url}/partner/${partnerId}`;

    let params = new HttpParams().set('pageNumber', page.pageNumber.toString());
    params = params.append('pageSize', page.pageSize.toString());

    if (page.filters) {
      for (let count = 0; count < page.filters.length; count++) {
        params = params.append(
          'filterColumn',
          page.filters[count].filterColumn
        );
        params = params.append('filterBy', page.filters[count].filterBy);
        params = params.append('filterMode', page.filters[count].filterMode);
      }
    }
    return this.dataService.getWithObserveResponse(url, params);
  }

  getPartnerResearcherDetails(
    partnerResearcherId: number,
    page: GridQueryOptions
  ): Observable<HttpResponse<CourthousesByResearcherDetailsItemPagedList>> {
    let url = this.getOldApisUrl('GetPartnerResearcherCourthouses');
    url = `${url}/partnerResearcherId/${partnerResearcherId}`;

    let params = new HttpParams().set('pageNumber', page.pageNumber.toString());
    params = params.append('pageSize', page.pageSize.toString());

    if (page.filters) {
      for (let count = 0; count < page.filters.length; count++) {
        params = params.append(
          'filterColumn',
          page.filters[count].filterColumn
        );
        params = params.append('filterBy', page.filters[count].filterBy);
        params = params.append('filterMode', page.filters[count].filterMode);
      }
    }

    return this.dataService.getWithObserveResponse(url, params);
  }

  getPartnerResearcherInviteDetails(
    inviteId: number,
    page: GridQueryOptions
  ): Observable<HttpResponse<CourthousesByResearcherDetailsItemPagedList>> {
    let url = this.getOldApisUrl('GetPartnerInvitedCourtHouses');
    url = `${url}/InviteId/${inviteId}`;

    let params = new HttpParams().set('pageNumber', page.pageNumber.toString());
    params = params.append('pageSize', page.pageSize.toString());

    if (page.filters) {
      for (let count = 0; count < page.filters.length; count++) {
        params = params.append(
          'filterColumn',
          page.filters[count].filterColumn
        );
        params = params.append('filterBy', page.filters[count].filterBy);
        params = params.append('filterMode', page.filters[count].filterMode);
      }
    }

    return this.dataService.getWithObserveResponse(url, params);
  }

  public getModulesConfigured(): Observable<ModulesConfigured> {
    const userId = this.authenticationService.getSub();
    let url = this.getOldApisUrl('GetModulesConfigured');

    return this.dataService.get<ModulesConfigured>(url, {
      headers: { 'content-type': 'application/json' },
    });
  }

  public DeleteOrderSubmissionProfile(
    partnerId: number,
    profileId: number
  ): Observable<boolean> {
    let url = this.getApisUrl('deleteOsp');
    url = `${url}/partner/${partnerId}/profileId/${profileId}`;

    return this.dataService.delete<boolean>(url);
  }

  // public UpdateOrderSubmissionProfiles(
  //   partnerId: number,
  //   profiles: OrderSubmissionProfileForCreate[]
  // ): Observable<OrderSubmissionProfile[]> {
  //   let url = this.getApisUrl('upsertOsps');
  //   url = `${url}/partner/${partnerId}`;

  //   return this.dataService.post<OrderSubmissionProfile[]>(url, profiles, {
  //     headers: { 'content-type': 'application/json' },
  //   });
  // }

  public updateBatchResearchers(
    partnerId: number,
    item: UpdateItem,
    filters: FilterFieldMetadata[]
  ): Observable<boolean> {
    let url = this.getApisUrl('updateBatchResearchers');
    url = `${url}/partner/${partnerId}`;

    const serverFilters = [];
    for (let count = 0; count < filters.length; count++) {
      serverFilters.push({
        name: filters[count].filterColumn,
        data: {
          value: filters[count].filterBy,
          matchMode: filters[count].filterMode,
        },
      });
    }
    const updateItem = { updateItem: item, filters: serverFilters };

    return this.dataService.post<boolean>(url, updateItem, {
      headers: { 'content-type': 'application/json' },
    });
  }

  public createOsp(
    partnerId: number,
    osp: OrderSubmissionProfileForCreate
  ): Observable<OrderSubmissionProfile> {
    let url = this.getApisUrl('createOsp');
    url = `${url}/partner/${partnerId}`;

    return this.dataService.post<OrderSubmissionProfile>(url, osp, {
      headers: { 'content-type': 'application/json' }
    });
  }

  public UpdateOrderFulfillmentProfiles(
    partnerId: number,
    profiles: OrderFulfillmentProfileForCreate[]
  ): Observable<OrderFulfillmentProfile[]> {
    let url = this.getApisUrl('upsertOfps');
    url = `${url}/partner/${partnerId}`;

    return this.dataService.post<OrderFulfillmentProfile[]>(url, profiles, {
      headers: { 'content-type': 'application/json' },
    });
  }

  public createOfp(
    partnerId: number,
    osp: OrderFulfillmentProfileForCreate
  ): Observable<OrderFulfillmentProfile> {
    let url = this.getApisUrl('createOfp');
    url = `${url}/partner/${partnerId}`;
    return this.dataService.post<OrderFulfillmentProfile>(url, osp, {
      headers: { 'content-type': 'application/json' },
    });
  }

  public getOrderSubmissionProfiles(
    partnerId: number,
    page: GridQueryOptions
  ): Observable<OspGrid> {
    let url = this.getApisUrl('getMarketPlacePricing');
    url = `${url}/${partnerId}`;

    let params = new HttpParams().set('pageNumber', page.pageNumber.toString());
    params = params.append('pageSize', page.pageSize.toString());

    if (page.filters) {
      for (let count = 0; count < page.filters.length; count++) {
        params = params.append(
          'filterColumn',
          page.filters[count].filterColumn
        );
        params = params.append('filterBy', page.filters[count].filterBy);
        params = params.append('filterMode', page.filters[count].filterMode);
      }
    }
    return this.dataService.get<OspGrid>(url, { params: params });
  }

  public getOrderFullfillmentProfiles(
    partnerId: number,
    page: GridQueryOptions
  ): Observable<OfpGrid> {
    let url = this.getApisUrl('getAffiliatePricing');
    url = `${url}/${partnerId}`;

    let params = new HttpParams().set('pageNumber', page.pageNumber.toString());
    params = params.append('pageSize', page.pageSize.toString());

    if (page.filters) {
      for (let count = 0; count < page.filters.length; count++) {
        params = params.append(
          'filterColumn',
          page.filters[count].filterColumn
        );
        params = params.append('filterBy', page.filters[count].filterBy);
        params = params.append('filterMode', page.filters[count].filterMode);
      }
    }

    return this.dataService.get<OfpGrid>(url, { params: params });
  }

  public getServiceAreaForReseachers(
    page: GridQueryOptions
  ): Observable<HttpResponse<CourthousesForResearchersItemPagedList>> {
    const url = this.getApisUrl('getServiceAreaForResearchers');

    let params = new HttpParams().set('pageNumber', page.pageNumber.toString());
    params = params.append('pageSize', page.pageSize.toString());

    if (page.sortField) {
      params = params.append('fields', page.sortField);
      if (page.sortOrder == 'asc') {
        params = params.append('orderBy', 'asc');
      } else {
        params = params.append('orderBy', 'des');
      }
    }

    if (page.filters) {
      for (let count = 0; count < page.filters.length; count++) {
        params = params.append(
          'filterColumn',
          page.filters[count].filterColumn
        );
        params = params.append('filterBy', page.filters[count].filterBy);
        params = params.append('filterMode', page.filters[count].filterMode);
      }
    }
    return this.dataService.getWithObserveResponse(url, params);
  }

  public getResearchersForCourtHouse(
    courtHouseId: number,
    page: GridQueryOptions
  ): Observable<HttpResponse<ResearcherItemPagedList>> {
    let url = this.getApisUrl('getResearchersForCourtHouse');
    url = `${url}/CourtHouseId/${courtHouseId}`;

    let params = new HttpParams().set('pageNumber', page.pageNumber.toString());
    params = params.append('pageSize', page.pageSize.toString());

    if (page.sortField) {
      params.append('fields', page.sortField);
      if (page.sortOrder == 'asc') {
        params.append('orderBy', 'asc');
      } else {
        params.append('orderBy', 'des');
      }
    }

    if (page.filters) {
      for (let count = 0; count < page.filters.length; count++) {
        params = params.append(
          'filterColumn',
          page.filters[count].filterColumn
        );
        params = params.append('filterBy', page.filters[count].filterBy);
        params = params.append('filterMode', page.filters[count].filterMode);
      }
    }
    return this.dataService.getWithObserveResponse(url, params);
  }

  public updateResercherItem(partnerId: number, item: ResearcherGridItem) {
    let url = this.getApisUrl('UpdateResearcherItem');
    url = `${url}/partner/${partnerId}`;

    return this.dataService.post<boolean>(url, item, {
      headers: { 'content-type': 'application/json' },
    });
  }

  public sendReminderInvite(partnerId: number, inviteId: number) {
    let url = this.getApisUrl('SendReminderInvite');
    url = `${url}/partner/${partnerId}/inviteId/${inviteId}`;

    return this.dataService.post<boolean>(
      url,
      {},
      {
        headers: { 'content-type': 'application/json' },
      }
    );
  }

  public deleteInvite(partnerId: number, inviteId: number) {
    let url = this.getApisUrl('DeletePartnerInvite');
    url = `${url}/partner/${partnerId}/inviteId/${inviteId}`;

    return this.dataService.delete(url, {});
  }

  public deletePartnerResearcher(
    partnerId: number,
    partnerResearcherId: number
  ) {
    let url = this.getApisUrl('DeletePartnerResearcher');
    url = `${url}/partner/${partnerId}/partnerResearcherId/${partnerResearcherId}`;

    return this.dataService.delete(url, {});
  }

  public addToInviteServiceArea(
    inviteId: number,
    serviceArea: AddServiceAreaDto
  ) {
    let url = this.getApisUrl('addCourtHouseToInviteServiceArea');
    url = `${url}/inviteId/${inviteId}`;

    return this.dataService.post<boolean>(url, serviceArea, {
      headers: { 'content-type': 'application/json' },
    });
  }

  public addToResearcherServiceArea(
    researcherId: number,
    serviceArea: AddServiceAreaDto
  ) {
    let url = this.getApisUrl('addCourtHouseToResearcherServiceArea');
    url = `${url}/ResearcherId/${researcherId}`;

    return this.dataService.post<boolean>(url, serviceArea, {
      headers: { 'content-type': 'application/json' },
    });
  }

  public deleteInviteServiceArea(inviteId: number, courtHouseId: number) {
    let url = this.getApisUrl('DeleteInviteServiceAreaCourtHouse');
    url = `${url}/inviteId/${inviteId}/courtHouseId/${courtHouseId}`;

    return this.dataService.delete(url, {});
  }

  public deleteResearcherServiceArea(
    researcherId: number,
    courtHouseId: number
  ) {
    let url = this.getApisUrl('DeleteResearcherServiceAreaCourtHouse');
    url = `${url}/researcherId/${researcherId}/courtHouseId/${courtHouseId}`;

    return this.dataService.delete(url, {});
  }

  public deleteAllInviteServiceArea(
    inviteId: number,
    filters: FilterFieldMetadata[]
  ) {
    const serverFilters = [];

    let params = new HttpParams();
    if (filters) {
      for (let count = 0; count < filters.length; count++) {
        params = params.append('filterColumn', filters[count].filterColumn);
        params = params.append('filterBy', filters[count].filterBy);
        params = params.append('filterMode', filters[count].filterMode);
      }
    }

    let url = this.getApisUrl('DeleteAllInviteServiceAreaCourtHouse');
    url = `${url}/inviteId/${inviteId}`;

    return this.dataService.delete(url, {
      headers: { 'content-type': 'application/json' },
      params: params,
    });
  }

  public deleteAllResearchersServiceArea(
    researcherId: number,
    filters: FilterFieldMetadata[]
  ) {
    const serverFilters = [];
    for (let count = 0; count < filters.length; count++) {
      serverFilters.push({
        name: filters[count].filterColumn,
        data: {
          value: filters[count].filterBy,
          matchMode: filters[count].filterMode,
        },
      });
    }

    let url = this.getApisUrl('DeleteAllResearchersServiceAreaCourtHouse');
    url = `${url}/researcherId/${researcherId}`;

    return this.dataService.delete(url, serverFilters);
  }

  private getOldApisUrl(action: string = null) {
    if (action != null) {
      return `${this.registrationUrl}${this.apiOldPath}/${action}`;
    }
    return this.registrationUrl + this.apiOldPath;
  }

  private getApisUrl(action: string = null) {
    if (action != null) {
      return `${this.apiUrl}${this.apiPath}/${action}`;
    }
    return this.apiUrl + this.apiPath;
  }

  private get apiOldPath() {
    const apiPath = '/marketplace/api/Partner';

    return apiPath;
  }

  private get apiPath() {
    const apiPath = '/marketplace/api/Partner';

    return apiPath;
  }

  private init(): void {
    this.subscription = this.store
      .select(getApplicationSettings)
      .subscribe((response) => {
        if (response) {
          this.registrationUrl = response.administrationUrl;
          this.apiUrl = response.apiUrl;
        }
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  constructor(
    private dataService: DataService,
    private authenticationService: AuthenticationService,
    private store: Store<fromRoot.State>
  ) {
    this.init();
  }
}
