<div class="p-grid ui-fluid activate-authenticator">
  <form [formGroup]="form">
    <div class="p-col-12 pt-4">
      <div class="p-grid p-grid-form-group">
        <div class="p-col-12">
          <h2>
            {{ 'twoFactor.activateAuthenticator.mainHeader' | translate }}
          </h2>
          <p>
            {{ 'twoFactor.activateAuthenticator.downloadHeader' | translate }}
          </p>
          <ul class="a">
            <li>Google Authenticator</li>
            <li>Last Password Authenticator</li>
            <li>Authy</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="p-col-12">
      <div class="p-grid p-grid-form-group">
        <div class="p-col-12">
          <p>{{ 'twoFactor.activateAuthenticator.header' | translate }}</p>
        </div>
      </div>
    </div>

    <div class="p-col-12">
      <div class="p-grid p-grid-form-group">
        <div class="p-col-12">
          <div class="authenticator-Key">
            {{ authenticatorSetup.preSharedKey }}
          </div>
        </div>
      </div>
    </div>

    <div class="p-col-12">
      <div class="p-grid p-grid-form-group">
        <div class="p-col-12">
          <div id="qr-code-container" class="qr-code-container">
            <qrcode
              [qrdata]="authenticatorSetup.authenticatorUri"
              [width]="150"
              [elementType]="'url'"
              [colorDark]="'#000'"
              [colorLight]="'#ffffff'"
              [errorCorrectionLevel]="'H'"
            ></qrcode>
          </div>
        </div>
      </div>
    </div>

    <div class="p-col-12">
      <div class="p-grid p-grid-form-group">
        <div class="p-col-12">
          <p>
            {{ 'twoFactor.activateAuthenticator.activateHeader' | translate }}
          </p>
        </div>
        <div class="p-col-12">
          <input
            type="text"
            id="activate-authenticator-verification-code"
            autocomplete="off"
            placeholder="{{
              'twoFactor.twoFactorActivated.placeHolders.sixDigitVerificationCode'
                | translate
            }}"
            formControlName="verificationCode"
            class="p-inputtext p-component"
          />
          <div
            class="ui-message text-danger ui-corner-all"
            *ngIf="verificationCodeControlInvalid"
          >
            <i class="fa fa-close"></i>
            Verification code is required.
          </div>
          <div
            class="ui-message text-danger ui-corner-all"
            *ngIf="verificationCodeControlIsNotValid"
          >
            <i class="fa fa-close"></i>
            Verification code is not valid.
          </div>
        </div>
      </div>
    </div>

    <div class="p-col-12">
      <div class="p-grid p-grid-form-group">
        <div class="p-col-8">
          <button
            pButton
            type="button"
            class="p-button button-full-width"
            label="{{ 'buttons.Verify' | translate }}"
            [disabled]="!form.valid || pending"
            (click)="onActivateTwoFactor(form)"
          ></button>
        </div>
        <div class="p-col-4">
          <button
            pButton
            type="button"
            class="p-button button-full-width"
            label="{{ 'buttons.Cancel' | translate }}"
            (click)="onCancel()"
          ></button>
        </div>
      </div>
    </div>
  </form>
</div>
