import { createReducer, on } from '@ngrx/store';

import { formStatus, PartnerResearcherType, UserInformation } from '@cci/shared/models';

import {
  loadUserProfile
} from '../actions/profile.actions';
import {
  loadUserProfileSuccess,
  loadUserProfileFailed
} from '../actions/profile-api.actions';


export const collectionFeatureKey = 'profile';

export interface UserProfileState {
  profile: UserInformation;
  loaded: boolean;
  pending: boolean;
}

export const initialState: UserProfileState = {
  profile: null,
  loaded: false,
  pending: false
};

export const reducer = createReducer(
  initialState,
  on(loadUserProfile, (state) => ({
    ...state,
    pending: true,
  })),
  on(loadUserProfileSuccess, (state, { userInformation }) => {
    return {
      ...state,
      loaded: true,
      pending: false,
      profile: userInformation
    };
  }),
  on(loadUserProfileFailed, (state, {}) => ({
    ...state,
    loaded: false,
    pending: false
  }))
)

export const getUserInformation = (state: UserProfileState) => {
  return state.profile;
}
  
export const getUserProfilePending = (state: UserProfileState) => state.pending;
export const getUserProfileLoaded = (state: UserProfileState) => state.loaded;
