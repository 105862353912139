import { createAction, props, union } from '@ngrx/store';
import { WorkDay } from '@cci/shared/models';

export const loadDailyStartStopTimeForCompanySuccess = createAction(
  '[Tenants] Load Daily Start Stop Time For Company Success',
  props<{ dailyStartStopTimeForCompany: WorkDay[] }>()
);

export const loadDailyStartStopTimeForCompanyFailed = createAction(
  '[Tenants] Load Daily Start Stop Time For Company Failed',
  props<{ errors: any }>()
);
