import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromOrders from '../reducers/orders-collection.reducer';

export const getOrdersState = createSelector(
  fromFeature.getOrdersdState,
  (state: fromOrders.OrdersState) => state
);

export const selectOrdersLoading = createSelector(
  getOrdersState,
  fromOrders.getOrdersLoading
);

export const selectOrdersLoaded = createSelector(
  getOrdersState,
  fromOrders.getOrdersLoaded
);

export const selectOrdersPage = createSelector(
  getOrdersState,
  fromOrders.getOrders
);

export const selectOrdersPagination = createSelector(
  getOrdersState,
  fromOrders.getOrdersPagination
);
