import { createReducer, on } from "@ngrx/store";
import { recordingInProgressAction } from "../actions/field-processing-recording.actions";

export interface FieldProcessingRecording {
    recordingInProgress: boolean;
}

export const initialState: FieldProcessingRecording = {
    recordingInProgress: false
}

export const reducer = createReducer(
    initialState,
    on(recordingInProgressAction, (state, {recordingInProgress}) => ({
        recordingInProgress: recordingInProgress
    }))
);

export const getRecordingInProgress = (state: FieldProcessingRecording) => state.recordingInProgress;