import { CapitalizePipe } from './capitalize.pipe';
import { RolesPipe } from './roles.pipe';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { CourtHousePipe } from './court-house.pipe';

export const pipes: any[] = [CapitalizePipe, RolesPipe, CourtHousePipe];

export * from './capitalize.pipe';
export * from './court-house.pipe';
export * from './roles.pipe';
