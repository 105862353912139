import { Injectable } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';

import {
  loadConstants,
  loadJobPositions,
  loadWorkModes,
  loadUserTypes,
  loadWeekDays,
  loadCountries,
  loadCounties,
  loadStates,
  loadCourtHouses,
  loadTimeZones
} from '../actions/master-data.actions';
import { ConstantsService } from '@cci/core/services/constants-service';
import {
  loadCountriesSuccess,
  loadCountiesSuccess,
  loadCourtHousesSuccess,
  loadWeekDaysSuccess,
  loadWorkModesSuccess,
  loadJobPositionsSuccess,
  loadTimeZonesSuccess,
  loadConstantsSuccess,
  loadStatesSuccess,
  loadCourtTypesSuccess,
  loadCourtLevelsSuccess,
  loadConstantsFailed,
  loadJobPositionsFailed,
  loadWorkModesFailed,
  loadUserTypesSuccess,
  loadUserTypesFailed,
  loadCountriesFailed,
  loadCountiesFailed,
  loadStatesFailed,
  loadCourtHousesFailed,
  loadTimeZonesFailed,
  loadAccountStatusSuccess,
  loadRecordTypesSuccess,
  loadSearchMethodsSuccess,
  loadSearchTimeFramesSuccess,
  loadEyeColorsSuccess,
  loadGendersSuccess,
  loadHairColorsSuccess,
  loadRacesSuccess,
} from '../actions/master-data-api.actions';

@Injectable()
export class MasterDataEffects {
  loadConstants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadConstants),
      switchMap(() => {
        return this.service.getAll().pipe(
          mergeMap((response) => [
            loadConstantsSuccess(),
            loadCountriesSuccess({ countries: response.countries }),
            loadStatesSuccess({ states: response.states }),
            loadCountriesSuccess({ countries: response.countries }),
            loadCountiesSuccess({ counties: response.counties }),
            loadCourtHousesSuccess({ courtHouses: response.courtHouses }),
            loadWeekDaysSuccess({ weekDays: response.weekDays }),
            loadWorkModesSuccess({ workModes: response.workModes }),
            loadJobPositionsSuccess({ positions: response.jobPositions }),
            loadTimeZonesSuccess({ timeZones: response.timeZones }),
            loadCourtTypesSuccess({ courtTypes: response.courtTypes }),
            loadCourtLevelsSuccess({ courtLevels: response.courtLevels }),
            loadAccountStatusSuccess({ accountStatus: response.accountStatus }),
            loadRecordTypesSuccess({ recordTypes: response.recordTypes }),
            loadSearchMethodsSuccess({ searchMethods: response.searchMethods }),
            loadSearchTimeFramesSuccess({ searchTimeFrames: response.searchTimeFrames }),
            loadEyeColorsSuccess({ eyeColors: response.eyeColors }),
            loadGendersSuccess({ genders: response.genders }),
            loadHairColorsSuccess({ hairColors: response.hairColors }),
            loadRacesSuccess({ races: response.races }),
          ]),
          catchError((errors) => of(loadConstantsFailed({ errors: errors })))
        );
      })
    )
  );

  loadJobPositions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadJobPositions),
      switchMap(() => {
        return this.service.getJobPositions().pipe(
          map((response) => loadJobPositionsSuccess({ positions: response })),
          catchError((errors) => of(loadJobPositionsFailed({ errors: errors })))
        );
      })
    )
  );

  loadUserType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserTypes),
      switchMap(() => {
        return this.service.getUserTypes().pipe(
          map((response) => {
            return loadUserTypesSuccess({ positions: response });
          }),
          catchError((errors) => of(loadUserTypesFailed({ errors: errors })))
        );
      })
    )
  );

  loadWorkModes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadWorkModes),
      switchMap(() => {
        return this.service.getWorkModes().pipe(
          map((response) => loadWorkModesSuccess({ workModes: response })),
          catchError((errors) => of(loadWorkModesFailed({ errors: errors })))
        );
      })
    )
  );

  loadWeekDays$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadWeekDays),
      switchMap(() => {
        return this.service.getWeekDays().pipe(
          map((response) => loadWorkModesSuccess({ workModes: response })),
          catchError((errors) => of(loadWorkModesFailed({ errors: errors })))
        );
      })
    )
  );

  loadCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCountries),
      switchMap(() => {
        return this.service.getCountries().pipe(
          map((response) => loadCountriesSuccess({ countries: response })),
          catchError((errors) => of(loadCountriesFailed({ errors: errors })))
        );
      })
    )
  );

  loadCounties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCounties),
      switchMap(() => {
        return this.service.getCounties().pipe(
          map((response) => loadCountiesSuccess({ counties: response })),
          catchError((errors) => of(loadCountiesFailed({ errors: errors })))
        );
      })
    )
  );

  loadStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadStates),
      switchMap(() => {
        return this.service.getStates().pipe(
          map((response) => loadStatesSuccess({ states: response })),
          catchError((errors) => of(loadStatesFailed({ errors: errors })))
        );
      })
    )
  );

  loadCourtHouses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCourtHouses),
      switchMap(() => {
        return this.service.getCourtHouses().pipe(
          map((response) => loadCourtHousesSuccess({ courtHouses: response })),
          catchError((errors) => of(loadCourtHousesFailed({ errors: errors })))
        );
      })
    )
  );

  loadTimeZones$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTimeZones),
      switchMap(() => {
        return this.service.getTimeZones().pipe(
          map((response) => loadTimeZonesSuccess({ timeZones: response })),
          catchError((errors) => of(loadTimeZonesFailed({ errors: errors })))
        );
      })
    )
  );

  constructor(private actions$: Actions, private service: ConstantsService) {}
}
