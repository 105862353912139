import {
  Injectable,
  EventEmitter,
  InjectionToken,
  Inject
} from '@angular/core';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';

export const CCI_STATE_STORAGE = new InjectionToken<StorageService>(
  'CCI_STATE_STORAGE'
);

@Injectable()
export class LocalStorageService {
  constructor(
    @Inject(CCI_STATE_STORAGE) private storageService: StorageService
  ) {}

  set(key: string, value: any): void {
    return this.storageService.set(key, value);
  }

  get(key: string): any {
    return this.storageService.get(key);
    return null;
  }

  remove(key?: string) {
    this.storageService.remove(key);
  }

  clear(key?: string) {
    this.storageService.clear();
  }
}
