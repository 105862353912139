<div *ngIf="twoFactorIsDisabled && showEnableTwoFactor" class="p-grid">
  <div class="p-col-12 p-md-6 p-md-offset-3">
    <div class="two-fatcor-not-activated">
      <app-two-factor-not-activated (enableTwoFactor)="onEnableTwoFactor()">
      </app-two-factor-not-activated>
    </div>
  </div>
</div>

<div *ngIf="showTwoFactorOptions" class="p-grid">
  <div class="p-col-12 p-md-6 p-md-offset-3">
    <div class="two-fatcor-options">
      <app-two-factor-options
        (showSetupUsingApp)="onShowActivateAuthenticatorApp()"
        (showSetupUsingSms)="onShowActiveSms()"
      >
      </app-two-factor-options>
    </div>
  </div>
</div>

<div *ngIf="showActivateAuthenticatorApp" class="p-grid">
  <div class="p-col-12 p-md-6 p-md-offset-3">
    <div class="activate-authenticator">
      <app-activate-authenticator
        [form]="form.controls.authenticatorAppForm"
        [authenticatorSetup]="authenticatorSetup"
        [pending]="twoFactorAppPending"
        [errors]="twoFactorAppErrors"
        [twoFactorAppCodeVerified]="twoFactorAppCodeVerified"
        (activateTwoFactor)="onActivateTwoFactor($event)"
        (methodActivated)="onTwoFactorMethodActivatedTwoFactorSetup()"
        (cancel)="onEnableTwoFactor()"
      ></app-activate-authenticator>
    </div>
  </div>
</div>

<div *ngIf="showActivateSms" class="p-grid">
  <div class="p-col-12 p-md-6 p-md-offset-3">
    <div class="activate-sms">
      <app-two-factor-sms
        [form]="form.controls.smsForm"
        [twoFactorSmsPending]="twoFactorSmsPending"
        [twoFactorSmsErrors]="twoFactorSmsErrors"
        [twoFactorSmsCodeSent]="twoFactorSmsCodeSent"
        [twoFactorSmsCodeVerified]="twoFactorSmsCodeVerified"
        (cancel)="onEnableTwoFactor()"
        (sendCode)="onSendSmsVerificationCode($event)"
        (verifyPhoneNumber)="onVerifyPhoneNumber($event)"
        (methodActivated)="onTwoFactorMethodActivatedTwoFactorSetup()"
      ></app-two-factor-sms>
    </div>
  </div>
</div>

<div *ngIf="!twoFactorIsDisabled && showTwoFactorActivated" class="p-grid">
  <div class="p-col-12 p-md-6 p-md-offset-3">
    <div class="two-factor-activated">
      <app-two-factor-activated
        [recoveryCodes]="twoFactorRecoveryCodes"
        [providers]="authenticatorSetup?.providers"
        (disbaledTwoFactor)="onDisableTwoFactor()"
        (resetRecoveryCodes)="onResetRecoveryCodes()"
        (showSelectTwoFactorOption)="onShowSelectTwoFactorOption($event)"
        (showSideBar)="onShowSideBar($event)"
      ></app-two-factor-activated>
    </div>
  </div>
</div>
