import { RouterStateSerializer } from '@ngrx/router-store';
import {
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  Params,
  Route
} from '@angular/router';
import { Injectable } from "@angular/core";

/**
 * The RouterStateSerializer takes the current RouterStateSnapshot
 * and returns any pertinent information needed. The snapshot contains
 * all information about the state of the router at the given point in time.
 * The entire snapshot is complex and not always needed. In this case, you only
 * need the URL and query parameters from the snapshot in the store. Other items could be
 * returned such as route parameters and static route data.
 */

export interface RouterStateUrl {
  url: string;
  firstChild: ActivatedRouteSnapshot;
  queryParams: Params;
  params: Params;
  title: string;
}

@Injectable()
export class CustomRouterStateSerializer
  implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url } = routerState;
    const { firstChild } = routerState.root;
    const { queryParams } = routerState.root;

    let childRoute = routerState.root;
    while (childRoute.firstChild) {
      childRoute = childRoute.firstChild;
    }

    const { params } = childRoute;

    const title = childRoute.data['title'];

    return { url, firstChild, queryParams, params, title };
  }
}
