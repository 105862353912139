import { Subscription } from 'rxjs';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { setTheme } from 'ngx-bootstrap/utils';

import * as fromCoreServices from './modules/core/services';
import { LocalStorageService } from './modules/core/services';
import { Router, ActivatedRoute, Route } from '@angular/router';
import { StorageKeys } from '@cci/shared/constants/storage-keys';
import { initializeRecorder } from 'client/assets/js/hippo';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
})
export class RootComponent implements OnInit {
  public innerwidth: any;
  constructor(
    private translationService: fromCoreServices.AppTranslationService,
    private localStorage: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    setTheme('bs4'); // or 'bs4'
  }

  public ngOnInit(): void {
    this.init();
    this.innerwidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerwidth = event.target.innerWidth;
  }

  init() {
    this.initializeLanaguageService();
    initializeRecorder(); //Hippo video recorder initializing
    this.storeActivatedUrl(this.router.url);

    this.printpath('', this.router.config);
  }

  printpath(parent: String, config: Route[]) {
    for (let i = 0; i < config.length; i++) {
      const route = config[i];
      console.log(parent + '/' + route.path);
      if (route.children) {
        const currentPath = route.path ? parent + '/' + route.path : parent;
        this.printpath(currentPath, route.children);
      }
    }
  }

  initializeLanaguageService() {
    this.translationService.addLanguages(['en', 'fr']);
    this.translationService.setDefaultLanguage('en');
  }

  private storeActivatedUrl(url: string): void {
    if (!url.includes('/noauth')) {
      this.localStorage.set(StorageKeys.ACTIVATE_URL, url);
    }
  }

  onDeactivate(event) {
    console.log(event);
  }

  onActivate(event) {
    console.log(event);
  }
}
