import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { State, County, Divisions } from '../../../shared/models';
import { SelectItem } from 'primeng/api';
import { SideBarContent } from '../../models';

@Component({
  selector: 'app-service-area-info',
  templateUrl: './service-area-info.component.html',
  styleUrls: ['./service-area-info.component.scss']
})
export class ServiceAreaInfoComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() countries: SelectItem[];
  @Input() states: State[];
  @Input() counties: County[];
  @Input() headerText: string;
  @Input() headerDescription: string;
  @Input() sideBarTextForHeader: string;
  @Input() sideBarTextForLabel1: string;
  @Input() sideBarTextForLabel2: string;
  @Input() sideBarTextForLabel3: string;
  @Input() leftLabel1: string;
  @Input() leftLabel2: string;
  @Input() leftLabel3: string;
  @Input() rightLabel1: string;
  @Input() rightLabel2: string;
  @Input() placeholder: string;
  @Input() areas: SelectItem[];
  @Input() confirmationName: string;

  @Output()
  showSideBar = new EventEmitter<SideBarContent>();

  currency: string;

  constructor() {}

  ngOnInit() {}

  infoSideBar(sidebarText, styleClass) {
    const content = new SideBarContent(
      this.confirmationName,
      true,
      'bottom',
      sidebarText,
      true,
      false,
      false,
      true,
      '',
      styleClass,
      'sidebarHeader',
      'sidebarContent',
      '',
      '',
      'Got It'
    );
    this.showSideBar.emit(content);
  }

  createInput() {
    if(!this.form.controls['price'].pristine) {
      this.currency = this.form.value['price'];
      
      this.currency = this.currency.replace(/\$/g, '');

      if(this.currency != '') {
        this.form.controls.price.setValue('$'+this.currency);
      } else {
        this.form.controls.price.setValue('');
      }
    }
  }
}
