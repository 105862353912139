import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';
import * as fromModuleActivation from '../reducers/module-activation.reducer';

export const selectModuleActivationState = createSelector(
  fromFeature.getModuleActivationState,
  (state: fromModuleActivation.ModuleActivateState) => state
);

export const selectCreateOspSuccess = createSelector(
  selectModuleActivationState,
  fromModuleActivation.getCreateOspSuccess
);

export const selectCreateOspFailed = createSelector(
  selectModuleActivationState,
  fromModuleActivation.getCreateOspFailed
);

export const selectCreateOspErrors = createSelector(
  selectModuleActivationState,
  fromModuleActivation.getCreateOspErrors
);

export const selectCreateOfpSuccess = createSelector(
  selectModuleActivationState,
  fromModuleActivation.getCreateOfpSuccess
);

export const selectCreateOfpFailed = createSelector(
  selectModuleActivationState,
  fromModuleActivation.getCreateOfpFailed
);

export const selectCreateOfpErrors = createSelector(
  selectModuleActivationState,
  fromModuleActivation.getCreateOfpErrors
);
