import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SideBarContent, TwoFactorProvider } from '@cci/shared/models';
import { AppTranslationService } from '@cci/core/services/app-translation.service';

@Component({
  selector: 'app-two-factor-activated',
  templateUrl: './two-factor-activated.component.html',
  styleUrls: ['./two-factor-activated.component.scss'],
})
export class TwoFactorActivatedComponent implements OnInit {
  @Input() recoveryCodes: string[];
  @Input() public providers: TwoFactorProvider[];

  @Output() disbaledTwoFactor = new EventEmitter();
  @Output() resetRecoveryCodes = new EventEmitter();
  @Output() showSelectTwoFactorOption = new EventEmitter<TwoFactorProvider>();
  @Output()
  showSideBar = new EventEmitter<SideBarContent>();

  enableDisableText: string;
  showEnabled = true;
  twoFactorEnabled: boolean;

  translate = (key: string) => <string>{};
  constructor(private translationService: AppTranslationService) {
    this.translate = (key: string) =>
      this.translationService.getTranslation(key);
  }

  ngOnInit() {
    this.handleButtonStateChange(this.showEnabled);
    if(this.providers) {
      this.providers.forEach(item => {
        if(item['active']) {
          this.twoFactorEnabled = true;
        } else {
          this.twoFactorEnabled = false;
        }
      })
    }
  }

  onDisableTwoFactor() {
    this.disbaledTwoFactor.emit();
  }

  onResetRecoverCodes() {
    this.resetRecoveryCodes.emit();
  }

  handleButtonStateChange(value: boolean) {
    this.showEnabled = value;

    this.enableDisableText = this.showEnabled
      ? this.translate('common.strings.enabled')
      : this.translate('common.strings.disabled');
  }

  onShowSelectTwoFactorOption(provider: TwoFactorProvider) {
    this.showSelectTwoFactorOption.emit(provider);
  }

  onShowSideBar(content) {
    this.showSideBar.emit(content);
  }
}
