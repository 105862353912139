import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuth from './authentication.reducer';
import * as fromProfile from './profile.reducer';
import * as fromRoot from '../../../store/reducers';

export interface AuthenticationState {
  authenticationState: fromAuth.AuthenticationState,
  userProfile: fromProfile.UserProfileState;
}

export interface State extends fromRoot.State {
  auth: AuthenticationState;
}

export const reducers = {
  authenticationState: fromAuth.reducer,
  userProfile: fromProfile.reducer
};

/**
 * Initializing state selectors for retreiving states in smart components.
 */

export const authenticationStoreState =
  createFeatureSelector<AuthenticationState>('auth');
