/*
   The common module should be used for:
      -Single-use components (such as spinners, message toasts, and modal dialogs)
      -Singleton services
*/

import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
  APP_INITIALIZER,
} from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { SESSION_STORAGE } from 'ngx-webstorage-service';

import * as fromServices from './services';
import * as fromInterceptors from './interceptors';
import * as fromGuards from './gaurds';
import * as fromMoment from 'ngx-moment';
import { CCI_STATE_STORAGE } from './services';
import { DialogService } from 'primeng/dynamicdialog';

@NgModule({
  imports: [RouterModule, CommonModule],
  declarations: [
    // WizardComponent,
    // WizardStepComponent
  ],
  exports: [
    // WizardComponent,
    // WizardStepComponent
  ],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }

  static forRoot(config: any): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        fromMoment.DateFormatPipe,
        {
          provide: APP_INITIALIZER,
          useFactory: fromServices.initEnvironmentSettings,
          multi: true,
          deps: [fromServices.ApplicationSettingsService],
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: fromInterceptors.EnsureAcceptHeaderInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: fromInterceptors.AuthorizedRequestInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: fromInterceptors.NotificationInterceptorService,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: fromInterceptors.HttpRequestOverlayInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: fromInterceptors.HandleHttpErrorInterceptor,
          multi: true,
        },
        { provide: CCI_STATE_STORAGE, useExisting: SESSION_STORAGE },
        ...fromServices.services,
        ...fromGuards.guards,
        DatePipe,
        DialogService,
      ],
    };
  }
}
