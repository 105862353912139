<div *ngIf="returnLabel != null" class="fontAlignRight">
  <div>
    <a class="link-button link-color" (click)="onClickReturnTo()"
      >{{ returnLabel | translate }}</a
    >
  </div>
</div>
<div class="p-grid mobile-view">
  <div class="p-col-12">
    <label
      class="control-label body-text"
    >
      {{ 'mobileView.headerText' | translate }}
    </label>
    <br><br/>
    <label
      class="control-label body-text"
    >
      {{ 'mobileView.bodyText' | translate }}
    </label>
    <br><br/>
    <label
      class="control-label body-text"
    >
      {{ 'mobileView.message' | translate }}
    </label>
  </div>
  <div class="p-col-12 button">
      <button
          type="button"
          pButton
          class="p-button tool-bar"
          (click)="onClickGoBack()"
          >{{ 'mobileView.buttonText' | translate }}
      </button>
  </div>
</div>