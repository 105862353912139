export class Urls {
  //static readonly AccountRegistration: string = '/noauth/account/register';
  //static readonly AccountRegistrationComplete: string =
  //  '/noauth/account/register-email-validation-required';
  //static readonly AccountConfirmEmail: string = 
  //'/noauth/account/register-email-validation-successful';
  static readonly AccountRegistration: string = '/noauth/account/register';
  static readonly AccountRegistrationComplete: string =
      '/noauth/account/registration-complete';
  static readonly AccountConfirmEmail: string = '/noauth/account/confirm-email';
  static readonly AccountAuthorizedCallback: string =
    '/noauth/account/auth-callback';
  static readonly Home: string = '/auth/application-home';
  static readonly UserManagment: string = '/auth/users-management';

  static readonly SendRecoverCode: string = '/noauth/account/send-recovery-code';
  static readonly EnterRecoverCode: string ='/noauth/account/enter-recovery-code';
  static readonly ResetPassword: string ='/noauth/account/reset-password';

  // RESEARCHER MODULE
  static readonly ResearcherRegistration: string = '/auth/researcher/register';
  static readonly ResearcherProfile: string = '/auth/researcher/profile';
  static readonly ResearcherChangePassword: string =
    '/auth/researcher/change-password';
  static readonly ResearcherWorkProfile: string =
    '/auth/researcher/work-profile';
  static readonly ResearcherHome: string =
    '/auth/researcher/researcher-home';


  static readonly TimeOffNew: string = '/auth/researcher/new-time-off';
  static readonly TimeOffEdit: string = '/auth/researcher/edit-time-off';
  static readonly TimeOffDelete: string = '/auth/researcher/delete-time-off';

  static readonly TimeOff: string = '/auth/researcher/time-off';
  static readonly ServiceSetups: string = '/auth/researcher/service-setups';
  static readonly MasterSchedule: string = '/auth/researcher/master-schedule';
  static readonly Status: string = '/auth/researcher/status';

  // PARTNER MODULE
  static readonly PartnerRegistration: string = '/auth/company/register';
  static readonly PartnerChangePassword: string =
    '/auth/partner/change-password';

  static readonly CompanyDetails: string = '/auth/company/details';  
  static readonly CompanyStatus: string = '/auth/company/account-status';
  static readonly CompanyUsers: string = '/auth/company/users';
  static readonly CompanyUserInvites: string = '/auth/company/user-invites';
  static readonly CompanyModuleParticipation: string = '/auth/company/module-participation';
  static readonly CompanyFinancial: string = '/auth/company/financial';
  static readonly CompanyItResources: string = '/auth/company/it-resources';

  // MODULE MANAGEMENT MODULE
  static readonly NewOrderSubmissionProfile: string =
    '/auth/module-management/new-order-submission-profile';  
  static readonly ResearcherGrid: string = '/auth/module-management/researcher';
  static readonly MarketPlaceReadonly: string =
    '/auth/module-management/marketplace-readonly';

  static readonly MarketPlace: string = '/auth/module-management/marketplace-home';
  
  static readonly MarketPlaceServiceProfile: string =
    'auth/module-management/marketplace-service-profiles';
  static readonly MarketPlaceGridAnylitics: string =
    'auth/module-management/marketplace-program-analytics';

  static readonly NewAffiliateProfile: string =
    '/auth/module-management/new-affiliate-profile';
  static readonly AffiliateGrid: string =
    '/auth/module-management/affiliate-program';

  static readonly AffiliateProgramHome: string = 
    '/auth/module-management/affiliate-home';
  static readonly InviteResearchers: string =
    '/auth/module-management/affiliate-invite-researchers';
  static readonly ResearcherCoverage: string = 
    '/auth/module-management/affiliate-researcher-coverage';
  static readonly FulfillmentProfile: string = 
    '/auth/module-management/affiliate-order-fulfillment-profiles';
  static readonly AffiliateProgramAnalytics: string =
    '/auth/module-management/affiliate-program-analytics';
  static readonly CreateOfp: string =
    'auth/module-management/affiliate-create-fulfillment-profile';
  static readonly CreateOsp: string =
    'auth/module-management/marketplace-create-service-profile';

  static readonly ResearcherMyAnalytics: string =
  '/auth/module-management/researcher-my-analytics';
  static readonly ResearcherCourthouseDetail: string =
    '/auth/module-management/researcher-courthouse-detail';
  

  // ORDER MODULE
  static readonly SubmitOrder: string = '/auth/module-management/marketplace-orders-listing';
  static readonly SubmitOrder2: string =
    '/auth/module-management/order-submission';

  static readonly createOrder: string = '/auth/orders/marketplace-create-order';
  static readonly MarketplaceOrderListing: string =  'auth/orders/marketplace-orders-listing';
  static readonly ResearcherOrderListing: string =  'auth/orders/researcher-orders-listing';
  static readonly AffiliateOrderListing: string = '/auth/orders/affiliate-orders-listing';
  static readonly CentralProcessingOrderListing: string = '/auth/orders/central-processing-orders-listing';

  static readonly MarketplaceOrderDetail: string = 'auth/orders/marketplace-order-detail';
  static readonly AffiliateOrderDetail: string = 'auth/orders/affiliate-order-detail';
  static readonly ResearcherOrderDetail: string = 'auth/orders/researcher-order-detail';
  static readonly CPTOrderDetail: string = 'auth/orders/cpt-order-detail';

  // ORDER PROCESSING MODULE
  static readonly ResearcherProcessOrders: string = 
    '/auth/order-field-processing/researcher-field-process-orders';
  static readonly CentralTeamProcessOrders: string =
    '/auth/order-central-processing/central-team-process-orders';
  static readonly CentralTeamHome: string =
    '/auth/central-team/central-team-home';

  // USER MODULE
  static readonly UserDetails: string = '/auth/user/details';
  static readonly UserStatus: string = '/auth/user/account-status';
  static readonly ChangePassword: string = '/auth/user/change-password';
  static readonly TwoFactor: string = '/auth/user/two-factor';

  //ERROR
  static readonly ErrorInternalServerError: string =
    '/error/internal-server-error';
  static readonly ErrorNotFound: string = '/error/not-found';
  static readonly ErrorAccessDenied: string = '/error/access-denied';
  static readonly InvalidToken: string = 'invalid-token';
}
