import 'rxjs/Rx';
import { Observable } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { DataService } from '../../core/services/data.service';
import * as fromRoot from '../../store/reducers';
import { ResearcherInfo } from '../../shared/models';
import { getApplicationSettings } from '../../store/selectors/app-settings.selector';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class ResearchersService implements OnDestroy {
  private researchersUrl: string | null;
  private subscription;

  constructor(
    private dataService: DataService,
    private authenticationService: AuthenticationService,
    private store: Store<fromRoot.State>
  ) {
    this.init();
  }

  getResearcher(): Observable<ResearcherInfo> {
    let url = this.getApisUrl('getUserResearcher');

    const userId = this.authenticationService.getSub();
    url = `${url}/user/${userId}`;
    return this.dataService.get<ResearcherInfo>(url);
  }

  private getApisUrl(action: string = null) {
    if (action != null) {
      return `${this.researchersUrl}${this.apiPath}/${action}`;
    }
    return this.researchersUrl + this.apiPath;
  }

  get apiPath() {
    const apiPath = '/marketplace/api/researcher';
    return apiPath;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  init(): void {
    this.subscription = this.store
      .select(getApplicationSettings)
      .subscribe((response) => {
        if (response) {
          this.researchersUrl = response.researchersUrl;
          console.log(this.researchersUrl);
        }
      });
  }
}
