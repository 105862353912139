import { createAction } from '@ngrx/store';

export const loadConstants = createAction(
  '[Application Constants] Load Constants'
);

export const loadCountries = createAction(
  '[Application Constants] Load Countries'
);

export const loadJobPositions = createAction(
  '[Application Constants] Load Job Positions'
);

export const loadUserTypes = createAction(
  '[Application Constants] Load User Types'
);

export const loadWorkModes = createAction(
  '[Application Constants] Load Work Modes'
);

export const loadWeekDays = createAction(
  '[Application Constants] Load Week Days'
);

export const loadStates = createAction(
  '[Application Constants] Load States and Provinces'
);

export const loadCounties = createAction(
  '[Application Constants] Load Counties'
);

export const loadCourtHouses = createAction(
  '[Application Constants] Load Court Houses'
);

export const loadTimeZones = createAction(
  '[Application Constants] Load Time Zones'
);

export const loadCourtTypes = createAction(
  '[Application Constants] Load Court Types'
);

export const loadCourtLevels = createAction(
  '[Application Constants] Load Court Levels'
);

export const loadAccountStatus = createAction(
  '[Application Constants] Load Account Status'
);

export const loadRecordTypes = createAction(
  '[Application Constants] Load Record Types'
);

export const loadSearchMethods = createAction(
  '[Application Constants] Load Search Methods'
);

export const loadSearchTimeFrames = createAction(
  '[Application Constants] Load Search Time Frames'
);

export const loadEyeColors = createAction(
  '[Application Constants] Load Eye Colors'
);

export const loadGenders = createAction(
  '[Application Constants] Load Genders'
);

export const loadHairColors = createAction(
  '[Application Constants] Load Hair Colors'
);

export const loadRaces = createAction(
  '[Application Constants] Load Races'
);
