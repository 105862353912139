import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable()
export class SeoService {
  readonly appName = 'CCI';
  constructor(private titleService: Title, private meta: Meta) {}

  setTitle(title: string[]): SeoService {
    if (title) {
      title.push(this.appName);
      this.titleService.setTitle(title.join(' | '));
    } else {
      this.titleService.setTitle(this.appName);
    }
    return this;
  }
  setDescription(description: string): SeoService {
    this.meta.updateTag({
      name: 'description',
      content: description
    });
    return this;
  }
}
