import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { tap, filter, take, switchMap, catchError } from 'rxjs/operators';

import * as fromRoot from '../../store';
import {
  loadStates,
  loadConstants,
} from '@cci/store/actions/master-data.actions';

@Injectable()
export class ConstantsGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.checkStore().pipe(
      switchMap(() =>
        this.hasConstants().pipe(
          switchMap(() => of(true)),
          catchError(() => of(false))
        )
      ),
      catchError(() => of(false))
    );
  }

  checkStore(): Observable<boolean> {
    return this.store.select(fromRoot.getStatesLoaded).pipe(
      tap((loaded) => {
        if (!loaded) {
          this.store.dispatch(loadStates());
        }
      }),
      filter((loaded) => loaded),
      take(1)
    );
  }

  hasConstants(): Observable<boolean> {
    return this.store.select(fromRoot.getConstantsLoaded).pipe(
      tap((loaded) => {
        if (!loaded) {
          this.store.dispatch(loadConstants());
        }
      }),
      filter((loaded) => loaded),
      take(1)
    );
  }

  constructor(private store: Store<fromRoot.State>) {}
}
