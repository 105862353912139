import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { State, County, Divisions } from '../../models';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-service-area-info-readonly',
  templateUrl: './service-area-info-readonly.component.html',
  styleUrls: ['./service-area-info-readonly.component.scss']
})
export class ServiceAreaInfoReadonlyComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() countries: SelectItem[];
  @Input() states: State[];
  @Input() counties: County[];
  @Input() headerText: string;
  @Input() headerDescription: string;

  areas = <SelectItem[]>[
    { label: 'Country', value: Divisions.country },
    { label: 'State', value: Divisions.state },
    { label: 'County Seat', value: Divisions.county }
  ];

  filteredStates: State[];
  filteredCounties: County[];

  constructor() {}

  ngOnInit() {
    this.subscribeOnChanges();
  }

  get divisionControl(): FormControl {
    return this.form.get('division') as FormControl;
  }

  get countryIdControl(): FormControl {
    return this.form.get('countryId') as FormControl;
  }

  get stateIdControl(): FormControl {
    return this.form.get('stateId') as FormControl;
  }

  get countyIdControl(): FormControl {
    return this.form.get('countyId') as FormControl;
  }

  get stateDisabled(): boolean {
    return this.stateIdControl.disabled;
  }

  get countyDisabled(): boolean {
    return this.countyIdControl.disabled;
  }

  searchStates(event) {
    this.filteredStates = [];
    this.filteredStates = this.states
      .map(s => {
        if (
          s.abbreviation
            .trim()
            .replace(/\s/g, '')
            .toLowerCase()
            .indexOf(
              event.query
                .trim()
                .replace(/\s/g, '')
                .toLowerCase()
            ) > -1
        ) {
          return s;
        }
      })
      .filter(s => s);
  }
  searchCountySeat(event) {
    if (this.stateIdControl.value) {
      const stateProvinceAbbreviation = this.stateIdControl.value.abbreviation;
      this.searchByCountyAndStateSeat(event.query, stateProvinceAbbreviation);
    } else {
      this.searchByCountySeat(event.query);
    }
  }
  searchByCountySeat(countyQuery: string) {
    this.filteredCounties = [];
    this.filteredCounties = this.counties
      .map(c => {
        if (
          c.name
            .trim()
            .replace(/\s/g, '')
            .toLowerCase()
            .indexOf(
              countyQuery
                .trim()
                .replace(/\s/g, '')
                .toLowerCase()
            ) > -1
        ) {
          return c;
        }
      })
      .filter(c => c);
  }
  searchByCountyAndStateSeat(countyQuery: string, stateAbbreviaion: string) {
    this.filteredCounties = [];
    this.filteredCounties = this.counties
      .map(c => {
        if (
          c.name
            .trim()
            .replace(/\s/g, '')
            .toLowerCase()
            .indexOf(
              countyQuery
                .trim()
                .replace(/\s/g, '')
                .toLowerCase()
            ) > -1 &&
          c.stateAbbreviation.toLowerCase() ===
            stateAbbreviaion.toLowerCase()
        ) {
          return c;
        }
      })
      .filter(c => c);
  }

  subscribeOnChanges(): void {
    this.divisionControl.valueChanges.subscribe((division: SelectItem) => {
      if (division.value === Divisions.country) {
        this.stateIdControl.clearValidators();

        this.countyIdControl.clearValidators();

        this.stateIdControl.setValue(null);
        this.stateIdControl.disable();

        this.countyIdControl.disable();
        this.countyIdControl.setValue(null);
      } else if (division.value === Divisions.state) {
        this.stateIdControl.clearValidators();
        this.stateIdControl.setValidators(Validators.required);
        this.stateIdControl.enable();

        this.countyIdControl.clearValidators();
        this.countyIdControl.setValidators(Validators.required);
        this.countyIdControl.disable();
      } else if (division.value === Divisions.county) {
        this.stateIdControl.clearValidators();
        this.stateIdControl.setValidators(Validators.required);
        this.stateIdControl.enable();

        this.countyIdControl.clearValidators();
        this.countyIdControl.setValidators(Validators.required);
        this.countyIdControl.disable();
      }

      this.stateIdControl.updateValueAndValidity();
      this.countyIdControl.updateValueAndValidity();
    });

    this.stateIdControl.valueChanges.subscribe((state: State) => {
      const division = <SelectItem>this.divisionControl.value;

      if (state && state.abbreviation) {
        if (division.value === Divisions.state) {
          this.countyIdControl.clearValidators();
          this.countyIdControl.enable();
        } else if (division.value === Divisions.county) {
          this.countyIdControl.clearValidators();
          this.countyIdControl.updateValueAndValidity();
          this.countyIdControl.enable();
        }

        this.countyIdControl.updateValueAndValidity();
      }
    });
  }

  countrySelectedAsDivision() {
    this.countryIdControl.setValue('US');
    this.countryIdControl.disable();

    this.stateIdControl.setValue(null);
    this.stateIdControl.disable();

    this.countyIdControl.disable();
    this.countyIdControl.setValue(null);
  }

  handleStateSelected(state: State) {
    // this.stateIdControl.setValue(state.abbreviation);
  }

  handleStateClear() {
    // this.stateIdControl.setValue(null);
  }
}
