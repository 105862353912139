import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss']
})
export class ActionBarComponent {
  @Input() searchPlaceholderText = 'Search';
  @Input() searchFieldVisible = false;
  @Output() searchChanged: EventEmitter<string> = new EventEmitter<string>();

  @Input() actionButtonOneText: string;
  @Input() actionButtonOneVisible = false;
  @Input() actionButtonOneEnabled = true;
  @Output() actionButtonOneClicked = new EventEmitter();

  @Input() actionButtonTwoText: string;
  @Input() actionButtonTwoVisible = false;
  @Input() actionButtonTwoEnabled = true;
  @Output() actionButtonTwoClicked = new EventEmitter();

  model: { filter: string } = { filter: null };

  filterChanged(event: any) {
    event.preventDefault();
    this.searchChanged.emit(this.model.filter); // Raise changed event
  }

  onActionButtonOneClicked(event: any) {
    this.actionButtonOneClicked.emit(event);
  }

  onActionButtonTwoClicked(event: any) {
    this.actionButtonTwoClicked.emit(event);
  }
}
