import { createReducer, on } from '@ngrx/store';

import {
  openModal,
  closeModal,
  modalAccepted,
  modalClosed
} from '@cci/store/actions/modal.actions';
import { Modal, ModalResult } from '@cci/shared/models';

export interface ModalState {
  modal: Modal;
  result: ModalResult;
}

const initialState: ModalState = {
  modal: null,
  result: null
};

export const reducer = createReducer(
  initialState,
  on(openModal, (state, { modal }) => ({
    ...state,
    modal: modal,
    accepted: null
  })),
  on(closeModal, (state, {}) => ({
    ...state,
    modal: null,
    accepted: null
  })),
  on(modalAccepted, (state, { result }) => ({
    ...state,
    modal: null,
    result: result
  })),
  on(modalClosed, (state, {}) => ({
    ...state,
    modal: null,
    result: null
  }))
);

export const getOpenedModal = (state: ModalState) => state.modal;
export const getModalResullt = (state: ModalState) => state.result;
