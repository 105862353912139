import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { ValidationErrorHandler } from '@cci/shared/utils/validation-error-handler';
import { VerifyPhoneNumberModel } from '@cci/shared/models';

@Component({
  selector: 'app-two-factor-sms',
  templateUrl: './two-factor-sms.component.html',
  styleUrls: ['./two-factor-sms.component.scss'],
})
export class TwoFactorSmsComponent implements OnInit {
  @Input() form: FormGroup;
  @Input()
  twoFactorSmsCodeSent: boolean;
  @Input()
  set twoFactorSmsCodeVerified(verified: boolean) {
    this.onSmsVerified(verified);
  }
  @Input()
  twoFactorSmsPending: boolean;
  @Input()
  set twoFactorSmsErrors(errors: string[]) {
    this.handleErrors(errors);
  }

  @Output() sendCode = new EventEmitter<string>();
  @Output() verifyPhoneNumber = new EventEmitter<VerifyPhoneNumberModel>();
  @Output() cancel = new EventEmitter();
  @Output() methodActivated = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  get countryCodeControl(): FormControl {
    return this.form.get('countryCode') as FormControl;
  }

  get countryCodeControlInvalid() {
    return (
      this.countryCodeControl.hasError('required') &&
      this.countryCodeControl.touched
    );
  }

  get phoneNumberControl(): FormControl {
    return this.form.get('phoneNumber') as FormControl;
  }

  get phoneNumberControlInvalid() {
    return (
      this.phoneNumberControl.hasError('required') &&
      this.phoneNumberControl.touched
    );
  }

  get verificationCodeControl(): FormControl {
    return this.form.get('verificationCode') as FormControl;
  }

  get verificationCodeControlInvalid() {
    return (
      this.verificationCodeControl.hasError('required') &&
      this.verificationCodeControl.touched
    );
  }

  get verificationCodeControlIsNotValid() {
    return (
      this.verificationCodeControl.hasError('verificationCodeInvalid') &&
      this.verificationCodeControl.touched
    );
  }

  get canVerify() {
    return !this.verificationCodeControlInvalid;
  }

  onSendVerificationCode() {
    const { valid, value } = this.form;

    if (valid) {
      this.sendCode.emit(value.phoneNumber);
    }
  }

  onVerifyPhoneNumber() {
    const { valid, value } = this.form;

    if (this.canVerify) {
      let model = new VerifyPhoneNumberModel();

      model = Object.assign({}, model, this.form.value);
      this.verifyPhoneNumber.emit(model);
    }
  }

  onCancel() {
    this.cancel.emit();
  }

  onSmsVerified(verified: boolean) {
    if (verified) {
      this.methodActivated.emit();
    }
  }

  handleErrors(errors: any) {
    if (!this.form || !errors) {
      return;
    }

    ValidationErrorHandler.handleValidationErrors(this.form, errors);
  }
}
