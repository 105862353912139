import { createAction, props, union } from '@ngrx/store';
import { IApplicationSettings } from '../../shared/models/application-settings.model';

export const loadAppSetings = createAction('[App] Load Application Settings');

export const loadAppSetingsSuccess = createAction(
  '[App] Load Application Settings Success',
  props<{ payload: IApplicationSettings }>()
);

export const loadAppSetingsFailed = createAction(
  '[App] Load Application Settings Failed',
  props<{ errors: any }>()
);

const all = union({
  loadAppSetings,
  loadAppSetingsSuccess,
  loadAppSetingsFailed
});

export type AppSettingsActionsUnion = typeof all;
