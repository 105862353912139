import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromChangePassword from '../reducers/change-password.reducer';

export const selectChangePasswordState = createSelector(
  fromFeature.getChangePasswordState,
  (state: fromChangePassword.ChangePasswordState) => state
);

export const selectChangePasswordSuccessful = createSelector(
  selectChangePasswordState,
  fromChangePassword.getChangePasswordSuccessful
);

export const selectChangePasswordPending = createSelector(
  selectChangePasswordState,
  fromChangePassword.getChangePasswordPending
);

export const selectChangePasswordErrors = createSelector(
  selectChangePasswordState,
  fromChangePassword.getChangePasswordErrors
);
