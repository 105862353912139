import { createReducer, on } from '@ngrx/store';

/* import {
  ApplicationConstantsTypes,
  ApplicationActions
} from '../actions/constants.actions';
 */

import {
  loadConstants,
  loadJobPositions,
  loadUserTypes,
  loadWorkModes,
  loadWeekDays,
  loadStates,
  loadCounties,
  loadCourtHouses,
  loadTimeZones,
  loadCountries,
  loadCourtTypes,
  loadCourtLevels,
  loadAccountStatus,
  loadSearchTimeFrames,
  loadSearchMethods,
  loadRecordTypes,
  loadEyeColors,
  loadRaces,
  loadHairColors,
  loadGenders
} from '../actions/master-data.actions';

import {
  UserType,
  JobPosition,
  WorkMode,
  WeekDay,
  County,
  TimeZone,
  CourtHouse,
  Country,
  CourtType,
  CourtLevel,
  State,
  WorkDay,
  RecordTypes,
  SearchMethods,
  SearchTimeFrames,
  EyeColors,
  Genders,
  HairColors,
  Races,
} from '@cci-shared/models';
import {
  loadConstantsSuccess,
  loadJobPositionsSuccess,
  loadUserTypesSuccess,
  loadWorkModesSuccess,
  loadWeekDaysSuccess,
  loadStatesSuccess,
  loadCountiesSuccess,
  loadCourtHousesSuccess,
  loadTimeZonesSuccess,
  loadCountriesSuccess,
  loadCourtTypesSuccess,
  loadCourtLevelsSuccess,
  loadAccountStatusSuccess,
  loadSearchTimeFramesSuccess,
  loadSearchMethodsSuccess,
  loadRecordTypesSuccess,
  loadRacesSuccess,
  loadHairColorsSuccess,
  loadGendersSuccess,
  loadEyeColorsSuccess
} from '../actions/master-data-api.actions';
import { AccountStatus } from '@cci/shared/models/account-status';

export interface ConstantsState {
  pending: boolean;
  constantsLoaded: boolean;

  jobPositionsLoaded: boolean;
  jobPositions: JobPosition[];

  userTypesLoaded: boolean;
  userTypes: UserType[];

  workModesLoaded: boolean;
  workModes: WorkMode[];

  weekDaysLoaded: boolean;
  weekDays: WeekDay[];

  statesProvincesLoaded: boolean;
  statesProvinces: State[];

  countiesLoaded: boolean;
  counties: County[];

  timeZonesLoaded: boolean;
  timeZones: TimeZone[];

  courtHousesLoaded: boolean;
  courtHouses: { [id: number]: CourtHouse };

  countriesLoaded: boolean;
  countries: Country[];

  courtTypesLoaded: boolean;
  courtTypes: CourtType[];

  courtLevelsLoaded: boolean;
  courtLevels: CourtLevel[];

  accountStatusLoaded: boolean;
  accountStatus: AccountStatus[];

  dailyStartStopTimeForCompanyLoaded: boolean;
  dailyStartStopTimeForCompany: WorkDay[];

  recordTypesLoaded: boolean;
  recordTypes: RecordTypes[];

  searchMethodsLoaded: boolean;
  searchMethods: SearchMethods[];

  searchTimeFramesLoaded: boolean;
  searchTimeFrames: SearchTimeFrames[];

  eyeColorsLoaded: boolean;
  eyeColors: EyeColors[];

  gendersLoaded: boolean;
  genders: Genders[];

  hairColorsLoaded: boolean;
  hairColors: HairColors[];

  racesLoaded: boolean;
  races: Races[];
}

const initialState: ConstantsState = {
  pending: false,
  constantsLoaded: false,

  jobPositionsLoaded: false,
  jobPositions: [],

  userTypesLoaded: false,
  userTypes: [],

  workModesLoaded: false,
  workModes: [],

  weekDaysLoaded: false,
  weekDays: [],

  statesProvincesLoaded: false,
  statesProvinces: [],

  countiesLoaded: false,
  counties: [],

  timeZonesLoaded: false,
  timeZones: [],

  courtHousesLoaded: false,
  courtHouses: {},

  countriesLoaded: false,
  countries: [],

  courtTypesLoaded: false,
  courtTypes: [],

  courtLevelsLoaded: false,
  courtLevels: [],

  accountStatusLoaded: false,
  accountStatus: [],

  dailyStartStopTimeForCompanyLoaded: false,
  dailyStartStopTimeForCompany: [],

  recordTypesLoaded: false,
  recordTypes: [],

  searchMethodsLoaded: false,
  searchMethods: [],

  searchTimeFramesLoaded: false,
  searchTimeFrames: [],

  eyeColorsLoaded: false,
  eyeColors: [],

  gendersLoaded: false,
  genders: [],

  hairColorsLoaded: false,
  hairColors: [],

  racesLoaded: false,
  races: [],
};

export const reducer = createReducer(
  initialState,
  on(loadConstants, (state, {}) => ({
    ...state,
    pending: true,
    constantsLoaded: false,
  })),
  on(loadConstantsSuccess, (state, {}) => ({
    ...state,
    pending: false,
    constantsLoaded: true,
  })),
  on(loadJobPositions, (state, {}) => ({
    ...state,
    pending: true,
  })),
  on(loadJobPositionsSuccess, (state, { positions }) => {
    return {
      ...state,
      pending: false,
      jobPositionsLoaded: true,
      jobPositions: positions,
    };
  }),
  on(loadUserTypes, (state, {}) => ({
    ...state,
    pending: true,
  })),
  on(loadUserTypesSuccess, (state, { positions }) => {
    return {
      ...state,
      pending: false,
      userTypesLoaded: true,
      userTypes: positions,
    };
  }),
  on(loadWorkModes, (state, {}) => ({
    ...state,
    pending: true,
  })),
  on(loadWorkModesSuccess, (state, { workModes }) => ({
    ...state,
    pending: false,
    workModesLoaded: true,
    workModes: workModes,
  })),
  on(loadWeekDays, (state, {}) => ({
    ...state,
    pending: true,
  })),
  on(loadWeekDaysSuccess, (state, { weekDays }) => ({
    ...state,
    pending: false,
    weekDaysLoaded: true,
    weekDays: weekDays,
  })),
  on(loadStates, (state, {}) => ({
    ...state,
    pending: true,
  })),
  on(loadStatesSuccess, (state, { states }) => ({
    ...state,
    pending: false,
    statesProvincesLoaded: true,
    statesProvinces: states,
  })),
  on(loadCounties, (state, {}) => ({
    ...state,
    pending: true,
  })),
  on(loadCountiesSuccess, (state, { counties }) => ({
    ...state,
    pending: false,
    countiesLoaded: true,
    counties: counties,
  })),
  on(loadCourtHouses, (state, {}) => ({
    ...state,
    pending: true,
  })),
  on(loadCourtHousesSuccess, (state, { courtHouses }) => {
    const courtHousesEntities = courtHouses.reduce(
      (entities: { [id: number]: CourtHouse }, courtHouse: CourtHouse) => {
        return {
          ...entities,
          [courtHouse.id]: courtHouse,
        };
      },
      {
        ...state.courtHouses,
      }
    );

    return {
      ...state,
      pending: false,
      courtHousesLoaded: true,
      courtHouses: courtHousesEntities,
    };
  }),
  on(loadTimeZones, (state, {}) => ({
    ...state,
    pending: true,
  })),
  on(loadTimeZonesSuccess, (state, { timeZones }) => ({
    ...state,
    pending: false,
    timeZonesLoaded: true,
    timeZones: timeZones,
  })),
  on(loadCountries, (state, {}) => ({
    ...state,
    pending: true,
  })),
  on(loadCountriesSuccess, (state, { countries }) => ({
    ...state,
    pending: false,
    countriesLoaded: true,
    countries: countries,
  })),
  on(loadCourtTypes, (state, {}) => ({
    ...state,
    pending: true,
  })),
  on(loadCourtTypesSuccess, (state, { courtTypes }) => ({
    ...state,
    pending: false,
    courtTypesLoaded: true,
    courtTypes: courtTypes,
  })),
  on(loadCourtLevels, (state, {}) => ({
    ...state,
    pending: true,
  })),
  on(loadCourtLevelsSuccess, (state, { courtLevels }) => ({
    ...state,
    pending: false,
    courtLevelsLoaded: true,
    courtLevels: courtLevels,
  })),
  on(loadAccountStatus, (state, {}) => ({
    ...state,
    pending: true,
  })),
  on(loadAccountStatusSuccess, (state, { accountStatus }) => ({
    ...state,
    pending: false,
    accountStatusLoaded: true,
    accountStatus: accountStatus,
  })),
  on(loadRecordTypes, (state, {}) => ({
    ...state,
    pending: true,
  })),
  on(loadRecordTypesSuccess, (state, { recordTypes }) => ({
    ...state,
    pending: false,
    recordTypesLoaded: true,
    recordTypes: recordTypes,
  })),
  on(loadSearchMethods, (state, {}) => ({
    ...state,
    pending: true,
  })),
  on(loadSearchMethodsSuccess, (state, { searchMethods }) => ({
    ...state,
    pending: false,
    searchMethodsLoaded: true,
    searchMethods: searchMethods,
  })),
  on(loadSearchTimeFrames, (state, {}) => ({
    ...state,
    pending: true,
  })),
  on(loadSearchTimeFramesSuccess, (state, { searchTimeFrames }) => ({
    ...state,
    pending: false,
    searchTimeFramesLoaded: true,
    searchTimeFrames: searchTimeFrames,
  })),
  on(loadEyeColors, (state, {}) => ({
    ...state,
    pending: true,
  })),
  on(loadEyeColorsSuccess, (state, { eyeColors }) => ({
    ...state,
    pending: false,
    eyeColorsLoaded: true,
    eyeColors: eyeColors,
  })),
  on(loadGenders, (state, {}) => ({
    ...state,
    pending: true,
  })),
  on(loadGendersSuccess, (state, { genders }) => ({
    ...state,
    pending: false,
    gendersLoaded: true,
    genders: genders,
  })),
  on(loadHairColors, (state, {}) => ({
    ...state,
    pending: true,
  })),
  on(loadHairColorsSuccess, (state, { hairColors }) => ({
    ...state,
    pending: false,
    hairColorsLoaded: true,
    hairColors: hairColors,
  })),
  on(loadRaces, (state, {}) => ({
    ...state,
    pending: true,
  })),
  on(loadRacesSuccess, (state, { races }) => ({
    ...state,
    pending: false,
    racesLoaded: true,
    races: races,
  }))
);

export const getJobPositionsPending = (state: ConstantsState) => state.pending;
export const getJobPositionsLoaded = (state: ConstantsState) =>
  state.jobPositionsLoaded;
export const getJobPositions = (state: ConstantsState) => {
  return state.jobPositions;
};

export const getUserTypesPending = (state: ConstantsState) => state.pending;
export const getUserTypesLoaded = (state: ConstantsState) =>
  state.userTypesLoaded;
export const getUserTypes = (state: ConstantsState) => state.userTypes;

export const getApplicationPending = (state: ConstantsState) => state.pending;
export const getWorkModesLoaded = (state: ConstantsState) =>
  state.workModesLoaded;
export const getWorkModes = (state: ConstantsState) => state.workModes;

export const getWeekDaysLoaded = (state: ConstantsState) =>
  state.weekDaysLoaded;
export const getWeekDays = (state: ConstantsState) => state.weekDays;

export const getStatesLoaded = (state: ConstantsState) =>
  state.statesProvincesLoaded;
export const getStates = (state: ConstantsState) => state.statesProvinces;

export const getCountiesLoaded = (state: ConstantsState) =>
  state.countiesLoaded;
export const getCounties = (state: ConstantsState) => state.counties;

export const getCourtHousesLoaded = (state: ConstantsState) =>
  state.courtHousesLoaded;
export const getCourtHouses = (state: ConstantsState) => state.courtHouses;

export const getTimeZonesLoaded = (state: ConstantsState) =>
  state.timeZonesLoaded;
export const getTimeZones = (state: ConstantsState) => state.timeZones;

export const getCourtTypesLoaded = (state: ConstantsState) =>
  state.courtTypesLoaded;
export const getCourtTypes = (state: ConstantsState) => state.courtTypes;

export const getCourtLevelsLoaded = (state: ConstantsState) =>
  state.courtTypesLoaded;
export const getCourtLevels = (state: ConstantsState) => state.courtLevels;

export const getCountriesLoaded = (state: ConstantsState) => 
  state.countriesLoaded;
export const getCountries = (state: ConstantsState) => state.countries;

export const getConstantsLoaded = (state: ConstantsState) => {
  return state.constantsLoaded;
};

export const getAccountStatus = (state: ConstantsState) => state.accountStatus;

export const getRecordTypesLoaded = (state: ConstantsState) =>
  state.recordTypesLoaded;
export const getRecordTypes = (state: ConstantsState) => {
  return state.recordTypes;
};

export const getSearchMethodsLoaded = (state: ConstantsState) =>
  state.searchMethodsLoaded;
export const getSearchMethods = (state: ConstantsState) => state.searchMethods;

export const getSearchTimeFramesLoaded = (state: ConstantsState) =>
  state.searchTimeFramesLoaded;
export const getSearchTimeFrames = (state: ConstantsState) =>
  state.searchTimeFrames;

export const getEyeColorsLoaded = (state: ConstantsState) =>
  state.eyeColorsLoaded;
export const getEyeColors = (state: ConstantsState) =>
  state.eyeColors;

export const getGendersLoaded = (state: ConstantsState) =>
  state.gendersLoaded;
export const getGenders = (state: ConstantsState) =>
  state.genders;

export const getHairColorsLoaded = (state: ConstantsState) =>
  state.hairColorsLoaded;
export const getHairColors = (state: ConstantsState) =>
  state.hairColors;

export const getRacesLoaded = (state: ConstantsState) =>
  state.racesLoaded;
export const getRaces = (state: ConstantsState) =>
  state.races;