import { createAction, props, union } from '@ngrx/store';
import { UserInformation, UserInformationForUpdate } from '@cci/shared/models';

export const loadUserProfileSuccess = createAction(
  '[User Profile] Load User Profile Success',
  props<{
    userInformation: UserInformation;
  }>()
);

export const loadUserProfileFailed = createAction(
  '[User Profile] Load User Profile Failed',
  props<{ errors: any }>()
);

