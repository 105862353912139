import { Injectable } from '@angular/core';
import { ConfirmationService } from 'primeng/api';

import { Dialog } from '../../shared/models/dialog';

@Injectable()
export class DialogService {
  constructor(private confirmationService: ConfirmationService) {}

  confirm(dialog: Dialog) {
    this.confirmationService.confirm({
      message: dialog.message,
      accept: dialog.accept,
      reject: dialog.reject,
      icon: dialog.icon,
    });
  }
}
