import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Message } from 'primeng/api';

import { GrowlNotifyService } from '../../../core/services/growl-notify-service';
import { NotifyMessage } from '../../models/notify-message';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-growl-notifier',
  templateUrl: './growl-notification.component.html',
  styleUrls: ['./growl-notification.component.scss']
})
export class GrowlNotificationComponent implements OnDestroy {
  subscription: Subscription;

  constructor(
    private growlerNotifyService: GrowlNotifyService,
    private messageService: MessageService
  ) {
    this.subscribeToNotifications();
  }

  subscribeToNotifications() {
    this.subscription = this.growlerNotifyService.notificationChange.subscribe(
      (arg: NotifyMessage[] | NotifyMessage) => {
        let messages: Message[] = [];
        if ((<NotifyMessage>arg).detail) {
          const msg = arg as NotifyMessage;
          messages.push(msg);
        } else {
          messages = arg as NotifyMessage[];
        }

        this.messageService.addAll(messages);
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
