import { Injectable } from '@angular/core';
import {
  Router,
  ActivationStart,
  NavigationEnd,
  ActivatedRoute,
} from '@angular/router';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { tap, map, filter, debounce, mergeMap } from 'rxjs/operators';

import { State } from '@cci/store/reducers/root.reducers';
import { routeNavigation, go } from '../actions/router.action';
import { SeoService } from '../../core/services/seo.service';

@Injectable()
export class RouterEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private location: Location,
    private store: Store<State>,
    private activatedRoute: ActivatedRoute,
    private service: SeoService
  ) {
    this.listenToRouter();
  }

  go$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(go),
        tap(({ path, query: queryParams, extras }) => {
          this.router.navigate(path, { queryParams, ...extras });
        })
      ),
    {
      dispatch: false,
    }
  );

  back$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(go),
        tap(() => tap(() => this.location.back()))
      ),
    {
      dispatch: false,
    }
  );

  forward$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(go),
        tap(() => tap(() => this.location.forward()))
      ),
    {
      dispatch: false,
    }
  );

  updateTitle$ = createEffect(
    () =>
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        mergeMap((route) => route.data),
        map((data) => `${data['title']}`),
        tap((title) => this.service.setTitle([title]))
      ),
    {
      dispatch: false,
    }
  );

  private navEnd$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd)
  );

  private listenToRouter() {
    this.router.events
      .pipe(
        filter((event) => event instanceof ActivationStart),
        debounce(() => this.navEnd$)
      )
      .subscribe((event: any) => {
        let route = event.snapshot;
        const path: any[] = [];
        const { params, queryParams, data } = route;

        while (route.parent) {
          if (route.routeConfig && route.routeConfig.path) {
            path.push(route.routeConfig.path);
          }
          route = route.parent;
        }
        const routerState = {
          params,
          queryParams,
          data,
          path: path.reverse().join('/'),
        };
        this.store.dispatch(routeNavigation(routerState));
      });
  }
}
