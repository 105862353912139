import { Injectable } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import {
  GrowlNotifyService,
  OfpService,
  OspService
} from '@cci/core/services';
import { NotifyMessage } from '@cci/shared/models';
import { activateOfpAction, activateOspAction } from '../actions/module-activation.actions';
import { activateOfpActionFailed, activateOfpActionSuccess, activateOspActionFailed, activateOspActionSuccess } from '../actions/module-activation-api.actions';

@Injectable()
export class ModuleActivationEffects {

  debounce = 400;
  message = <NotifyMessage>{
    type: 'success',
    key: 'toast',
    life: 5000,
    closable: true,
  };

  errorMessage = <NotifyMessage>{
    type: 'error',
    key: 'toast',
    life: 5000,
    closable: true,
  };
  translate = (key: string) => <string>{};

  createPartnerOspAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(activateOspAction),
      mergeMap(({ tenantId, osp }) => {
        return this.ospService.createDefaultOsp(tenantId, osp).pipe(
          mergeMap(() => {
            return [
              activateOspActionSuccess()
            ];
          }),
          catchError((errors) =>
            of(activateOspActionFailed({ errors: errors }))
          )
        );
      })
    )
  );

  createPartnerOspFailed$ = createEffect(() =>
  this.actions$.pipe(
    ofType(activateOspActionFailed),
    switchMap((errors) => [
      this.growlNotificationService.showErrorMessage(
        this.setErrorMessage(
          this.translate('addOsp.toastyMessages.errorMessageBody')
        )
      ),
    ])
  ),
  { dispatch: false }
);

  createPartnerOfpAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(activateOfpAction),
      mergeMap(({ tenantId, ofp }) => {
        return this.ofpService.createDefaultOfp(tenantId, ofp).pipe(
          mergeMap(() => {
            return [
              activateOfpActionSuccess()
            ];
          }),
          catchError((errors) =>
            of(activateOfpActionFailed({ errors: errors }))
          )
        );
      })
    )
  );

  createPartnerOfpFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(activateOfpActionFailed),
      switchMap((errors) => [
        this.growlNotificationService.showErrorMessage(
          this.setErrorMessage(
            this.translate('addOfp.toastyMessages.errorMessageBody')
          )
        ),
      ])
    ),
    { dispatch: false }
  );

  private setErrorMessage(details: string): NotifyMessage {
    this.errorMessage.detail = details;
    return this.errorMessage;
  }

  constructor(
    private actions$: Actions,
    private ofpService: OfpService,
    private ospService: OspService,
    private growlNotificationService: GrowlNotifyService
  ) {}
}
function createPartnerOfpActionSuccess(): any {
  throw new Error('Function not implemented.');
}

